import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import "../styles/globals.scss";
import "../styles/common.scss";
import "../styles/util.scss";

import { data as en } from "../data/en";
import BookDemoForm from "../components/BookDemoForm";
import { useAtom } from "jotai";
import { geoAtom } from "../state";

export const App = ({ Component, pageProps }) => {
  const { locale } = useRouter();
  const [translations, setTranslations] = useState(en);
  const [geoIp, setGeoIp] = useAtom(geoAtom);

  const getTranslations = async ({ browserLocale, browserLanguage }) => {
    const language = browserLocale === browserLanguage ? browserLocale : browserLanguage;
    if (language !== "en") {
      switch (language) {
        case "ar": {
          const { data: ar } = await import("../data/ar");
          setTranslations(ar);
          break;
        }
        case "de": {
          const { data: de } = await import("../data/de");
          setTranslations(de);
          break;
        }
        case "es": {
          const { data: es } = await import("../data/es");
          setTranslations(es);
          break;
        }
        case "fr": {
          const { data: fr } = await import("../data/fr");
          setTranslations(fr);
          break;
        }
        case "hi": {
          const { data: hi } = await import("../data/hi");
          setTranslations(hi);
          break;
        }
        case "pt": {
          const { data: pt } = await import("../data/pt");
          setTranslations(pt);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const getGeoIp = async () => {
    try {
      const response = await fetch(
        "https://api.quicksell.co/v1/config/ip-location"
      );
      const { geo } = await response.json();
      setGeoIp(geo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const browserLanguage = navigator ? navigator.language : "en";
    getTranslations({
      browserLocale: locale,
      browserLanguage: browserLanguage.split("-")[0],
    });
    getGeoIp();
  }, [locale]);

  return (
    <>
      <Component {...pageProps} {...translations} geoIp={geoIp} />
      <BookDemoForm tag="Web Demo Leads TF" />
    </>
  );
};

export default App;
