const data = {
  headerUseCasesSubMenuData: {
    createdAt: "2022-06-23T20:37:53.208Z",
    updatedAt: "2022-11-04T21:26:13.303Z",
    publishedAt: "2022-06-23T20:38:00.243Z",
    locale: "en",
    browseByBusinessModelSection: {
      id: 107,
      cardTitle: "Browse By Business Model",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "browseByBusinessModelSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-23T20:56:26.471Z",
          updatedAt: "2022-06-23T20:56:30.238Z",
          publishedAt: "2022-06-23T20:56:30.107Z",
          locale: "en",
          internal_identifier: "HeaderUseCasesSubMenu - QuickSell for B2B",
          cardSubTitle: null,
          cardDescription:
            "QuickSell for Manufacturers, Distributors, and Wholesalers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "QuickSell for B2B",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Explore",
              url: "/b2b",
              openInNewTab: false,
              color: "",
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-06-23T20:54:00.280Z",
              updatedAt: "2022-06-23T20:54:03.495Z",
              publishedAt: "2022-06-23T20:54:03.370Z",
              locale: "en",
              identifier_internal: "HeaderUseCasesSubMenu - QuickSell for B2B",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-06-23T20:55:27.396Z",
          updatedAt: "2022-06-23T20:55:58.433Z",
          publishedAt: "2022-06-23T20:55:51.093Z",
          locale: "en",
          internal_identifier: "HeaderUseCasesSubMenu - QuickSell for B2C",
          cardSubTitle: null,
          cardDescription: "QuickSell for Retailers and Boutiques",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "QuickSell for B2C",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Explore",
              url: "/b2c",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-06-23T20:54:04.537Z",
              updatedAt: "2022-06-23T20:54:07.742Z",
              publishedAt: "2022-06-23T20:54:07.615Z",
              locale: "en",
              identifier_internal: "HeaderUseCasesSubMenu - QuickSell for B2C",
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
      ],
    },
    browseByindustrySection: {
      id: 108,
      cardTitle: "Browse by Industry",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "browseByindustrySection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "QuickSell for Jewellery",
          url: "/quicksell-for-jewellery",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/jewellery_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-06-23T20:40:42.312Z",
          updatedAt: "2022-06-23T20:47:22.511Z",
          publishedAt: "2022-06-23T20:43:13.706Z",
          locale: "en",
          identifier_internal:
            "HeaderUseCasesSubMenu - QuickSell for Jewellery",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "QuickSell for Apparel",
          url: "/quicksell-for-clothing",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/clothing_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-06-23T20:42:51.137Z",
          updatedAt: "2022-06-30T20:36:11.082Z",
          publishedAt: "2022-06-30T20:36:11.073Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - QuickSell for Apparel",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "QuickSell for Gifting",
          url: "/quicksell-for-gifting",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/gift_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-06-23T20:43:58.382Z",
          updatedAt: "2022-06-23T20:47:41.352Z",
          publishedAt: "2022-06-23T20:44:04.570Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - QuickSell for Gifting",
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "QuickSell for Toys",
          url: "/quicksell-for-toys",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/toys_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-06-23T20:45:16.167Z",
          updatedAt: "2022-06-30T20:36:12.697Z",
          publishedAt: "2022-06-30T20:36:12.691Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - QuickSell for Toys",
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
    },
    keyFeaturesSection: {
      id: 109,
      cardTitle: "Key Features",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyFeaturesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "Live Analytics",
          url: "/analytics",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/eye_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-06-23T20:49:58.393Z",
          updatedAt: "2022-06-23T20:50:02.765Z",
          publishedAt: "2022-06-23T20:50:02.643Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - Live Analytics",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "Private Showcasing",
          url: "/private-showcasing",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/security_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-06-23T20:50:56.701Z",
          updatedAt: "2022-06-23T20:51:04.901Z",
          publishedAt: "2022-06-23T20:51:04.773Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - Private Showcasing",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "Cataloguing",
          url: "/cataloguing",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/product_management_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-07-01T05:47:31.206Z",
          updatedAt: "2022-07-01T05:49:58.575Z",
          publishedAt: "2022-07-01T05:47:33.575Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - Cataloguing",
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "Native App",
          url: "/native-app",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/native_app_icon.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-07-26T08:37:05.907Z",
          updatedAt: "2022-11-04T21:15:03.030Z",
          publishedAt: "2022-07-26T08:37:06.888Z",
          locale: "en",
          identifier_internal: "HeaderUseCasesSubMenu - Native App",
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "DoubleTick By QuickSell",
          url: "https://doubletick.io/?utm_source=QuickSell&utm_medium=QuickSell+Website&utm_campaign=QuickSell+Referral",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: true,
          extraIconUrl: "/assets/logo/doubletick_logo_black.svg",
          extraIconPosition: "left",
          showLinkIcon: true,
          createdAt: "2022-11-04T21:16:09.537Z",
          updatedAt: "2022-11-04T21:24:40.207Z",
          publishedAt: "2022-11-04T21:16:11.668Z",
          locale: "en",
          identifier_internal:
            "HeaderUseCasesSubMenu - DoubleTick By QuickSell",
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          menuId: null,
          onClick: "captureDoubleTickClick",
          bold: false,
        },
      ],
    },
  },
  headerData: {
    createdAt: "2022-06-23T18:49:19.521Z",
    updatedAt: "2022-11-04T20:44:44.163Z",
    publishedAt: "2022-06-23T19:21:44.721Z",
    locale: "en",
    brandSection: {
      id: 103,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/powered_by.png",
      cardImageUrlFallback: null,
      cardImageHeight: "104",
      cardImageWidth: "324",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "brandSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: "/",
      openInNewTab: false,
    },
    menuSection: {
      id: 104,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "menuSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
      linksList: [
        {
          text: "<div class=\"with-header-menu-item-new-feature-label\"><span class=\"header-menu-item-new-feature-label\">NEW</span><span>DoubleTick</span></div>",
          url: "https://doubletick.io/?utm_source=QuickSell&utm_medium=QuickSell+Website&utm_campaign=QuickSell+Referral",
          openInNewTab: true,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-11-04T20:37:49.625Z",
          updatedAt: "2022-11-04T21:06:47.651Z",
          publishedAt: "2022-11-04T20:40:38.989Z",
          locale: "en",
          identifier_internal: "Header Menu - DoubleTick Link",
          columnName: null,
          sectionName: "",
          sortOrder: 1,
          menuId: "",
          onClick: "captureDoubleTickClick",
          bold: false
        },
        {
          text: "Use cases",
          url: null,
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T18:54:41.120Z",
          updatedAt: "2022-11-04T20:38:27.184Z",
          publishedAt: "2022-06-23T18:54:59.969Z",
          locale: "en",
          identifier_internal: "Header Menu - Use cases Link",
          columnName: null,
          sectionName: "use_cases",
          sortOrder: 2,
          menuId: "use_cases",
          onClick: null,
          bold: false,
        },
        {
          text: "Pricing",
          url: "https://quicksell.co/pricing-app",
          openInNewTab: true,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T18:55:24.466Z",
          updatedAt: "2022-11-04T20:39:58.008Z",
          publishedAt: "2022-06-23T18:55:27.158Z",
          locale: "en",
          identifier_internal: "Header Menu - Pricing Link",
          columnName: null,
          sectionName: "pricing",
          sortOrder: 3,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "Help",
          url: "https://learn.quicksell.co",
          openInNewTab: true,
          color: "",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T18:58:42.294Z",
          updatedAt: "2022-11-04T20:40:19.276Z",
          publishedAt: "2022-06-23T18:58:45.421Z",
          locale: "en",
          identifier_internal: "Header Menu - Help Link",
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "Login",
          url: "https://web.quicksell.co/login",
          openInNewTab: true,
          color: "var(--green)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T18:58:46.897Z",
          updatedAt: "2022-11-04T20:40:31.892Z",
          publishedAt: "2022-06-23T18:58:50.063Z",
          locale: "en",
          identifier_internal: "Header Menu - Login Link",
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          menuId: null,
          onClick: "loginToWeb",
          bold: true,
        }
      ],
    },
    b2bECommerceMenuSection: {
      id: 104,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "menuSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [],
      linksList: []
    }
  },
  footerData: {
    createdAt: "2022-06-23T19:26:46.606Z",
    updatedAt: "2022-07-30T07:25:55.382Z",
    publishedAt: "2022-06-23T19:26:52.022Z",
    locale: "en",
    brandSection: {
      id: 105,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription:
        "QuickSell helps merchants increase their conversion rate and shorten sales cycle with a personal e-commerce strategy",
      sectionTitle: null,
      sectionSubTitle: "© 2023 QuickSell. All rights reserved",
      cardImageUrl: "/assets/powered_by.png",
      cardImageUrlFallback: null,
      cardImageHeight: "104",
      cardImageWidth: "324",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "brandSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: "/",
      openInNewTab: false,
      contactsList: [
        {
          title: "Sales",
          email: "sales@quicksell.co",
          number: "+919321721252",
          createdAt: "2022-06-23T18:35:50.718Z",
          updatedAt: "2022-06-23T18:37:45.664Z",
          publishedAt: "2022-06-23T18:35:54.519Z",
          locale: "en",
          sortOrder: 1,
          internal_identifier: "Sales",
        },
        {
          title: "Support",
          email: "support@quicksell.co",
          number: "+91 80007 50634",
          createdAt: "2022-06-23T18:36:13.571Z",
          updatedAt: "2022-06-23T18:37:53.491Z",
          publishedAt: "2022-06-23T18:36:16.655Z",
          locale: "en",
          sortOrder: 2,
          internal_identifier: "Support",
        },
        {
          title: "Address",
          email: null,
          number: null,
          address:
            "APPORT SOFTWARE SOLUTIONS PRIVATE LIMITED\n8th Floor, 8-B, Sagar Sangeet, Colaba Post Office, Mumbai,\nMumbai City, Maharashtra, 400005",
          createdAt: "2022-06-23T18:36:13.571Z",
          updatedAt: "2022-06-23T18:37:53.491Z",
          publishedAt: "2022-06-23T18:36:16.655Z",
          locale: "en",
          sortOrder: 2,
          internal_identifier: "Address",
        },
      ],
      linksList: [
        {
          text: "Privacy policy",
          url: "https://quicksell.co/privacy.html",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T19:30:22.196Z",
          updatedAt: "2022-07-06T10:22:58.389Z",
          publishedAt: "2022-06-23T19:31:14.512Z",
          locale: "en",
          identifier_internal: "Privacy policy Link",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "Terms and conditions",
          url: "https://quicksell.co/terms.html",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T19:30:22.196Z",
          updatedAt: "2022-07-06T10:22:58.389Z",
          publishedAt: "2022-06-23T19:31:14.512Z",
          locale: "en",
          identifier_internal: "Terms and conditions Link",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
    },
    linksSection: {
      id: 106,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "linksSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-23T19:34:12.452Z",
          updatedAt: "2022-06-23T19:34:16.402Z",
          publishedAt: "2022-06-23T19:34:16.258Z",
          locale: "en",
          internal_identifier: "Footer - Why QuickSell",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Why QuickSell",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "QuickSell vs Website",
              url: "/quicksell-vs-website",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:30:22.196Z",
              updatedAt: "2022-07-06T10:22:58.389Z",
              publishedAt: "2022-06-23T19:31:14.512Z",
              locale: "en",
              identifier_internal: "QuickSell vs Website Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "QuickSell vs PDF",
              url: "/quicksell-vs-pdf",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:30:40.629Z",
              updatedAt: "2022-07-06T10:23:04.747Z",
              publishedAt: "2022-06-23T19:31:07.876Z",
              locale: "en",
              identifier_internal: "QuickSell vs PDF Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "QuickSell vs Excel",
              url: "/quicksell-vs-excel",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:31:33.910Z",
              updatedAt: "2022-07-06T10:23:13.080Z",
              publishedAt: "2022-06-23T19:31:37.290Z",
              locale: "en",
              identifier_internal: "QuickSell vs Excel Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 3,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "QuickSell vs Shopify",
              url: "/quicksell-vs-shopify",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:32:02.243Z",
              updatedAt: "2022-07-06T10:22:47.860Z",
              publishedAt: "2022-06-23T19:32:08.126Z",
              locale: "en",
              identifier_internal: "QuickSell vs Shopify Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 4,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "QuickSell vs Dropbox",
              url: "/quicksell-vs-dropbox",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:32:35.020Z",
              updatedAt: "2022-07-06T10:23:20.443Z",
              publishedAt: "2022-06-23T19:32:38.291Z",
              locale: "en",
              identifier_internal: "QuickSell vs Dropbox Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 5,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-06-23T19:35:11.377Z",
          updatedAt: "2022-07-26T08:34:27.380Z",
          publishedAt: "2022-06-23T19:42:43.395Z",
          locale: "en",
          internal_identifier: "Footer - Product",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Product",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Cataloguing",
              url: "/cataloguing",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:35:51.611Z",
              updatedAt: "2022-07-06T10:20:15.807Z",
              publishedAt: "2022-06-23T19:35:54.556Z",
              locale: "en",
              identifier_internal: "Cataloguing Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "Live Analytics",
              url: "/analytics",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:36:15.906Z",
              updatedAt: "2022-06-23T19:36:18.766Z",
              publishedAt: "2022-06-23T19:36:18.639Z",
              locale: "en",
              identifier_internal: "Live Analytics",
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "Native App",
              url: "/native-app",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-07-26T08:34:15.555Z",
              updatedAt: "2022-07-26T08:34:17.060Z",
              publishedAt: "2022-07-26T08:34:17.053Z",
              locale: "en",
              identifier_internal: "Native App",
              columnName: null,
              sectionName: null,
              sortOrder: 3,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-06-23T19:42:16.181Z",
          updatedAt: "2022-06-23T19:42:29.136Z",
          publishedAt: "2022-06-23T19:42:29.003Z",
          locale: "en",
          internal_identifier: "Footer - Help & Support",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Help & Support",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Knowledge Center",
              url: "https://learn.quicksell.co",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:38:08.914Z",
              updatedAt: "2022-07-06T10:21:12.782Z",
              publishedAt: "2022-06-23T19:38:12.049Z",
              locale: "en",
              identifier_internal: "Knowledge Center Link",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "YouTube",
              url: "https://www.youtube.com/channel/UC4ygOF2X_LHOOxC1-OoVbdQ",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:38:39.171Z",
              updatedAt: "2022-07-06T10:21:29.182Z",
              publishedAt: "2022-06-23T19:38:43.832Z",
              locale: "en",
              identifier_internal: "YouTube Link",
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-06-23T19:43:22.628Z",
          updatedAt: "2022-06-23T19:43:34.480Z",
          publishedAt: "2022-06-23T19:43:34.342Z",
          locale: "en",
          internal_identifier: "Footer - Company",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Company",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Privacy policy",
              url: "https://quicksell.co/privacy.html",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:39:11.856Z",
              updatedAt: "2022-06-23T19:39:17.625Z",
              publishedAt: "2022-06-23T19:39:17.504Z",
              locale: "en",
              identifier_internal: "Privacy policy",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "Terms and conditions",
              url: "https://quicksell.co/terms.html",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:39:33.476Z",
              updatedAt: "2022-07-06T10:21:49.038Z",
              publishedAt: "2022-06-23T19:39:36.095Z",
              locale: "en",
              identifier_internal: "Terms And Conditions Link",
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "Cancellation & Refund Policy",
              url: "https://quicksell.notion.site/Cancellation-Refund-Policy-3758a95f96734182bbcd520d6641990a",
              openInNewTab: true,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:39:33.476Z",
              updatedAt: "2022-07-06T10:21:49.038Z",
              publishedAt: "2022-06-23T19:39:36.095Z",
              locale: "en",
              identifier_internal: "Cancellation & Refund Policy Link",
              columnName: null,
              sectionName: null,
              sortOrder: 3,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "Contact us",
              url: "https://quicksell.notion.site/Contact-Us-1640e1cfdde445d8871e246dffc0b425",
              openInNewTab: true,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:39:33.476Z",
              updatedAt: "2022-07-06T10:21:49.038Z",
              publishedAt: "2022-06-23T19:39:36.095Z",
              locale: "en",
              identifier_internal: "Contact us Link",
              columnName: null,
              sectionName: null,
              sortOrder: 4,
              menuId: null,
              onClick: null,
              bold: false,
            },
            {
              text: "About us",
              url: "https://quicksell.notion.site/About-Us-17869537f4b34af680f1a6a65ab92a54",
              openInNewTab: true,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: false,
              createdAt: "2022-06-23T19:39:33.476Z",
              updatedAt: "2022-07-06T10:21:49.038Z",
              publishedAt: "2022-06-23T19:39:36.095Z",
              locale: "en",
              identifier_internal: "About us Link",
              columnName: null,
              sectionName: null,
              sortOrder: 5,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
      ],
    },
  },
  homePageData: {
    createdAt: "2022-06-20T15:36:46.272Z",
    updatedAt: "2022-11-04T21:47:11.425Z",
    publishedAt: "2022-06-23T07:24:40.568Z",
    locale: "en",
    internal_identifier: "homepage",
    boostConversionWhatsappSection: {
      id: 34,
      cardTitle:
        "Boost conversion rate from prospects and customers on WhatsApp",
      cardSubTitle: null,
      cardDescription:
        "Create your e-commerce catalogue, website and app using only your phone",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/home_main_image.webp",
      cardImageUrlFallback: "/assets/home_main_image.png",
      cardImageHeight: "1548",
      cardImageWidth: "1892",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "boostConversionWhatsappSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "QuickSell for B2B",
          url: "/b2b",
          openInNewTab: false,
          color: "",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-20T16:16:55.764Z",
          updatedAt: "2022-06-24T06:22:07.122Z",
          publishedAt: "2022-06-20T17:59:09.180Z",
          locale: "en",
          identifier_internal: "B2B Page Link",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
        {
          text: "QuickSell for B2C",
          url: "/b2c",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-20T16:17:22.295Z",
          updatedAt: "2022-06-24T06:22:18.077Z",
          publishedAt: "2022-06-20T17:59:15.047Z",
          locale: "en",
          identifier_internal: "B2C Page Link",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "Home Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    partnerLogosSection: {
      id: 35,
      cardTitle: "Trusted by millions of businesses from 100+ countries",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "partnerLogosSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-20T17:26:56.199Z",
          updatedAt: "2022-06-23T17:07:12.018Z",
          publishedAt: "2022-06-20T17:58:46.763Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pantaloons.webp",
          cardImageUrlFallback: "/assets/partner_logos/pantaloons.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Pantaloons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T17:27:22.706Z",
          updatedAt: "2022-06-23T18:02:16.855Z",
          publishedAt: "2022-06-20T17:58:34.032Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 2",
          cardSubTitle: null,
          cardDescription: "",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/peter_england.webp",
          cardImageUrlFallback: "/assets/partner_logos/peter_england.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Peter England",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:18:23.996Z",
          updatedAt: "2022-06-23T18:02:26.904Z",
          publishedAt: "2022-06-21T03:18:24.910Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/sabyasachi.webp",
          cardImageUrlFallback: "/assets/partner_logos/sabyasachi.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sabyasachi",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:19:09.401Z",
          updatedAt: "2022-06-23T18:02:34.789Z",
          publishedAt: "2022-06-21T03:19:11.976Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/gini_and_jony.webp",
          cardImageUrlFallback: "/assets/partner_logos/gini_and_jony.png",
          cardImageHeight: "94",
          cardImageWidth: "199",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Gini & Jony",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:19:36.599Z",
          updatedAt: "2022-06-23T18:02:42.315Z",
          publishedAt: "2022-06-21T03:19:39.846Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/grt.webp",
          cardImageUrlFallback: "/assets/partner_logos/grt.png",
          cardImageHeight: "194",
          cardImageWidth: "160",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "GRT",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:20:10.134Z",
          updatedAt: "2022-06-23T18:02:49.227Z",
          publishedAt: "2022-06-21T03:20:17.934Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/tupperware.webp",
          cardImageUrlFallback: "/assets/partner_logos/tupperware.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Tupperware",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:20:46.201Z",
          updatedAt: "2022-06-23T18:02:56.650Z",
          publishedAt: "2022-06-21T03:20:48.441Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 7",
          cardSubTitle: "",
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/hamleys.webp",
          cardImageUrlFallback: "/assets/partner_logos/hamleys.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Hamleys",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:21:10.867Z",
          updatedAt: "2022-06-23T18:03:04.247Z",
          publishedAt: "2022-06-21T03:21:17.458Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/neelkanth.webp",
          cardImageUrlFallback: "/assets/partner_logos/neelkanth.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Neelkanth",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:21:36.348Z",
          updatedAt: "2022-06-23T18:03:13.549Z",
          publishedAt: "2022-06-21T03:21:38.874Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/miniso.webp",
          cardImageUrlFallback: "/assets/partner_logos/miniso.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Miniso",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:22:03.060Z",
          updatedAt: "2022-06-23T17:07:34.290Z",
          publishedAt: "2022-06-21T03:22:05.776Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/siroya.webp",
          cardImageUrlFallback: "/assets/partner_logos/siroya.jpeg",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Siroya",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:22:34.969Z",
          updatedAt: "2022-06-23T17:07:46.511Z",
          publishedAt: "2022-06-21T03:22:39.730Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 11",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/toy_port.webp",
          cardImageUrlFallback: "/assets/partner_logos/toy_port.png",
          cardImageHeight: "185",
          cardImageWidth: "606",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Toy Port",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 11,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:23:38.975Z",
          updatedAt: "2022-06-23T17:07:58.936Z",
          publishedAt: "2022-06-21T03:23:43.172Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 12",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pd_soni.webp",
          cardImageUrlFallback: "/assets/partner_logos/pd_soni.jpeg",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PD Soni",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 12,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:24:08.724Z",
          updatedAt: "2022-06-23T17:08:09.632Z",
          publishedAt: "2022-06-21T03:24:11.811Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 13",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/bakery.webp",
          cardImageUrlFallback: "/assets/partner_logos/bakery.png",
          cardImageHeight: "1172",
          cardImageWidth: "2132",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Bakery",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 13,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:24:38.459Z",
          updatedAt: "2022-06-23T17:08:19.262Z",
          publishedAt: "2022-06-21T03:24:40.858Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 14",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/kokoh.webp",
          cardImageUrlFallback: "/assets/partner_logos/kokoh.jpeg",
          cardImageHeight: "185",
          cardImageWidth: "297",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Kokoh",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 14,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:25:10.140Z",
          updatedAt: "2022-06-23T18:01:57.757Z",
          publishedAt: "2022-06-21T03:25:12.864Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 15",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/skynn.webp",
          cardImageUrlFallback: "/assets/partner_logos/skynn.jpeg",
          cardImageHeight: "185",
          cardImageWidth: "185",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Skynn",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 15,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:25:33.685Z",
          updatedAt: "2022-06-23T18:02:07.250Z",
          publishedAt: "2022-06-21T03:25:34.626Z",
          locale: "en",
          internal_identifier: "homepage - section 2 - logo 16",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/mangalmani_jewels.webp",
          cardImageUrlFallback: "/assets/partner_logos/mangalmani_jewels.png",
          cardImageHeight: "1536",
          cardImageWidth: "1536",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mangalmani",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 16,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    boostConversionPersonalCommerceSection: {
      id: 36,
      cardTitle:
        '<span class="text-black">Boost conversion rate with </span><span class="text-green text-slant-underline">Personal Commerce</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "boostConversionPersonalCommerceSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T05:24:40.622Z",
          updatedAt: "2022-06-24T11:17:25.485Z",
          publishedAt: "2022-06-21T05:24:41.961Z",
          locale: "en",
          internal_identifier: "homepage - section 3 - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Create customer specific, price specifc, or theme specific catalogues",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/boost_personal_commerce_1.webp",
          cardImageUrlFallback: "/assets/boost_personal_commerce_1.png",
          cardImageHeight: "1160",
          cardImageWidth: "1104",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Curate catalogues for customers",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Explore Curated Cataloguing",
              url: "/cataloguing",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-06-23T06:14:49.273Z",
              updatedAt: "2022-07-06T10:20:34.693Z",
              publishedAt: "2022-06-23T06:14:52.878Z",
              locale: "en",
              identifier_internal: "Explore Curated Cataloguing Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-06-21T05:25:25.655Z",
          updatedAt: "2022-06-23T18:03:28.476Z",
          publishedAt: "2022-06-21T05:25:28.215Z",
          locale: "en",
          internal_identifier: "homepage - section 3 - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Get notified in real-time on your phone when buyers open your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/boost_personal_commerce_2.webp",
          cardImageUrlFallback: "/assets/boost_personal_commerce_2.png",
          cardImageHeight: "1160",
          cardImageWidth: "1104",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get notified in real-time when buyer opens",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T05:26:02.679Z",
          updatedAt: "2022-06-23T18:03:37.255Z",
          publishedAt: "2022-06-21T05:26:04.354Z",
          locale: "en",
          internal_identifier: "homepage - section 3 - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Track customer activity live and boost conversion rate by calling customers at the right time",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/boost_personal_commerce_3.webp",
          cardImageUrlFallback: "/assets/boost_personal_commerce_3.png",
          cardImageHeight: "1158",
          cardImageWidth: "1104",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Read your customers mind and close sales faster",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Explore Live Analytics",
              url: "/analytics",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-06-23T06:20:38.115Z",
              updatedAt: "2022-07-06T10:20:45.765Z",
              publishedAt: "2022-06-23T06:20:38.920Z",
              locale: "en",
              identifier_internal: "Explore Live Analytics Page Link",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
      ],
    },
    builtWithQuickSellSection: {
      id: 37,
      cardTitle:
        '<span class="text-libre-bodoni text-italic">Built with </span><span class="text-avenir-next text-bold text-italic">QuickSell</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "builtWithQuickSellSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-20T14:29:56.887Z",
          updatedAt: "2022-06-23T18:03:45.748Z",
          publishedAt: "2022-06-20T14:30:00.668Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/clothing.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/clothing.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "638",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Clothing",
          linkUrl: "https://pantaloonslakecitymall.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:32:41.537Z",
          updatedAt: "2022-06-23T18:05:11.635Z",
          publishedAt: "2022-06-20T14:32:44.066Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/skincare.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/skincare.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "632",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Skincare",
          linkUrl: "https://skkyn.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:34:05.416Z",
          updatedAt: "2022-06-23T18:05:20.709Z",
          publishedAt: "2022-06-20T14:34:06.747Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/electronics.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/electronics.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "636",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Electronics",
          linkUrl: "https://skkyn.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:35:52.686Z",
          updatedAt: "2022-06-23T18:05:33.309Z",
          publishedAt: "2022-06-20T14:35:54.262Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/furniture.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/furniture.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "634",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Furniture",
          linkUrl: "https://nilkamalfurniture.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:36:54.399Z",
          updatedAt: "2022-06-23T18:05:41.596Z",
          publishedAt: "2022-06-20T14:43:55.149Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/toys.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/toys.jpeg",
          cardImageHeight: "2161",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Toys",
          linkUrl: "https://toyport.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:38:18.575Z",
          updatedAt: "2022-06-23T18:05:50.229Z",
          publishedAt: "2022-06-20T14:38:20.182Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/handicrafts.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/handicrafts.jpeg",
          cardImageHeight: "2153",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Handicrafts",
          linkUrl: "https://catalog.shrihandicrafts.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:39:10.853Z",
          updatedAt: "2022-06-23T18:05:59.530Z",
          publishedAt: "2022-06-20T14:39:12.457Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/hardware.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/hardware.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "635",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Hardware",
          linkUrl: "https://indokokoh.com",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:40:02.956Z",
          updatedAt: "2022-06-23T18:06:07.759Z",
          publishedAt: "2022-06-20T14:40:05.476Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/household.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/household.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "636",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Household",
          linkUrl: "https://tupperwaregurgaon.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:41:36.143Z",
          updatedAt: "2022-06-23T18:06:16.805Z",
          publishedAt: "2022-06-20T14:41:37.955Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/jewellery.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/jewellery.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "636",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Jewellery",
          linkUrl: "https://pdahmedabad.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:42:25.542Z",
          updatedAt: "2022-06-23T18:03:58.808Z",
          publishedAt: "2022-06-20T14:42:26.173Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/medical.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/medical.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "636",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Medical",
          linkUrl: "https://mediaidxpert.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:43:15.373Z",
          updatedAt: "2022-06-23T18:04:09.552Z",
          publishedAt: "2022-06-20T14:43:16.593Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 11",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/flower.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/flower.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "635",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Gardening",
          linkUrl: "https://divineblossoms.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 11,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:45:20.119Z",
          updatedAt: "2022-06-23T18:04:22.287Z",
          publishedAt: "2022-06-20T14:45:21.754Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 12",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/packaging.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/packaging.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "637",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Packaging",
          linkUrl: "https://megapackaging.app",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 12,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:46:17.971Z",
          updatedAt: "2022-06-23T18:04:32.735Z",
          publishedAt: "2022-06-20T14:46:20.145Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 13",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/shoes.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/shoes.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "638",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Shoes",
          linkUrl: "https://srisaileathers.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 13,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:47:09.272Z",
          updatedAt: "2022-06-23T18:04:43.297Z",
          publishedAt: "2022-06-20T14:47:11.420Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 14",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/stationary.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/stationary.jpeg",
          cardImageHeight: "1157",
          cardImageWidth: "576",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Stationary",
          linkUrl: "https://bps.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 14,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:48:02.422Z",
          updatedAt: "2022-06-23T18:04:53.401Z",
          publishedAt: "2022-06-20T14:48:03.882Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 15",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/grocery.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/grocery.jpeg",
          cardImageHeight: "1280",
          cardImageWidth: "639",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Grocery",
          linkUrl: "https://metto.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 15,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T14:48:54.822Z",
          updatedAt: "2022-06-23T18:05:02.913Z",
          publishedAt: "2022-06-20T14:48:56.645Z",
          locale: "en",
          internal_identifier: "home page - section 4 - logo 16",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/art.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/art.jpeg",
          cardImageHeight: "1156",
          cardImageWidth: "576",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Art",
          linkUrl: "https://rangartgallery.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 16,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    catalogueECommerceSection: {
      id: 38,
      cardTitle: "Catalogue e-commerce",
      cardSubTitle: null,
      cardDescription:
        "Get started with an easy to use and powerful catalogue and allow customers to place an order easily",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/catalogue_e_commerce.webp",
      cardImageUrlFallback: "/assets/catalogue_e_commerce.png",
      cardImageHeight: "1776",
      cardImageWidth: "1332",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "catalogueECommerceSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-20T14:56:46.486Z",
          updatedAt: "2022-06-23T18:06:26.351Z",
          publishedAt: "2022-06-20T14:56:47.902Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 1",
          cardSubTitle: null,
          cardDescription:
            '<span class="text-green text-italic">Curated catalogues</span> for customers for a personalized buying experience',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/curated_cataloguing.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "20",
          cardImageWidth: "19",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Curated cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Explore Cataloguing",
              url: "/cataloguing",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-06-22T11:37:04.795Z",
              updatedAt: "2022-06-23T11:17:55.255Z",
              publishedAt: "2022-06-22T11:37:06.704Z",
              locale: "en",
              identifier_internal: "homepage catalogueECommerceSection link 1",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: null,
            },
          ],
        },
        {
          createdAt: "2022-06-20T15:03:29.336Z",
          updatedAt: "2022-06-23T18:06:35.136Z",
          publishedAt: "2022-06-20T15:03:30.943Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Create your e-commerce website with your own custom domain, secured payments and logistics",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/ecommerce_website.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "20",
          cardImageWidth: "20",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "E-commerce website",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Explore B2C",
              url: "/b2c",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-06-22T11:37:31.168Z",
              updatedAt: "2022-06-23T11:18:01.352Z",
              publishedAt: "2022-06-22T11:37:33.069Z",
              locale: "en",
              identifier_internal: "homepage catalogueECommerceSection link 2",
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              menuId: null,
              onClick: null,
              bold: null,
            },
          ],
        },
        {
          createdAt: "2022-06-20T15:05:33.305Z",
          updatedAt: "2022-06-24T09:58:47.582Z",
          publishedAt: "2022-06-20T15:05:34.509Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_layout.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "15",
          cardImageWidth: "15",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom layout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-24T10:02:58.915Z",
          updatedAt: "2022-07-04T11:07:08.976Z",
          publishedAt: "2022-07-04T11:03:26.871Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_theme.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "15",
          cardImageWidth: "15",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Themes",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T15:06:07.319Z",
          updatedAt: "2022-06-24T09:59:51.855Z",
          publishedAt: "2022-06-20T15:06:08.806Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/product_layouts.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "15",
          cardImageWidth: "15",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "3 product layouts",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T15:07:00.352Z",
          updatedAt: "2022-06-24T10:00:26.805Z",
          publishedAt: "2022-06-20T15:07:02.192Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/search.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "15",
          cardImageWidth: "15",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Powerful search",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T15:07:56.086Z",
          updatedAt: "2022-06-24T10:01:07.847Z",
          publishedAt: "2022-06-20T15:07:57.298Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/two_step_checkout.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "16",
          cardImageWidth: "15",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "2 click checkout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T15:08:27.356Z",
          updatedAt: "2022-06-24T10:01:30.927Z",
          publishedAt: "2022-06-20T15:08:28.986Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/tag.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "15",
          cardImageWidth: "15",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Tags",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T15:09:31.342Z",
          updatedAt: "2022-06-24T10:02:03.027Z",
          publishedAt: "2022-06-20T15:09:32.912Z",
          locale: "en",
          internal_identifier: "home page - section 5 - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_action_button.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "15",
          cardImageWidth: "8",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom action button",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    scaleBusinessOnWhatsappSection: {
      id: 39,
      cardTitle: "Scale your business on WhatsApp",
      cardSubTitle: null,
      cardDescription:
        "Take your WhatsApp Commerce to the next level with powerful features to boost your conversion rate on WhatsApp",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/whatsapp_ordering.webp",
      cardImageUrlFallback: "/assets/whatsapp_ordering.png",
      cardImageHeight: "1558",
      cardImageWidth: "1144",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "scaleBusinessOnWhatsappSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T02:54:27.775Z",
          updatedAt: "2022-06-23T17:28:55.519Z",
          publishedAt: "2022-06-21T02:54:29.435Z",
          locale: "en",
          internal_identifier: "home page - section 6 - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/catalogue_brochure.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "21",
          cardImageWidth: "20",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Catalogue brochure",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T02:55:05.260Z",
          updatedAt: "2022-06-23T17:29:02.474Z",
          publishedAt: "2022-06-21T02:55:06.593Z",
          locale: "en",
          internal_identifier: "home page - section 6 - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/quick_whatsapp_chat_from_catalogue_icon.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "23",
          cardImageWidth: "23",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Quick WhatsApp Chat from Catalogue",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T02:56:04.691Z",
          updatedAt: "2022-06-23T17:29:08.377Z",
          publishedAt: "2022-06-21T02:56:05.756Z",
          locale: "en",
          internal_identifier: "home page - section 6 - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/automated_order_receipt.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "24",
          cardImageWidth: "24",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Automated WhatsApp order receipts",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T02:56:44.558Z",
          updatedAt: "2022-06-23T17:29:15.589Z",
          publishedAt: "2022-06-21T02:56:45.649Z",
          locale: "en",
          internal_identifier: "home page - section 6 - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/create_catalogue_from_whatsapp.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "26",
          cardImageWidth: "26",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Create catalogue from WA chat",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
      linksList: [
        {
          text: "Explore DoubleTick By QuickSell",
          url: "https://doubletick.io/?utm_source=QuickSell&utm_medium=QuickSell+Website&utm_campaign=QuickSell+Referral",
          openInNewTab: true,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-11-04T21:45:49.791Z",
          updatedAt: "2022-11-04T21:54:29.464Z",
          publishedAt: "2022-11-04T21:54:29.456Z",
          locale: "en",
          identifier_internal: "homepage scaleBusinessOnWhatsappSection link 1",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: "captureDoubleTickClick",
          bold: false,
        },
      ],
    },
    powerfulFeaturesForB2bSection: {
      id: 40,
      cardTitle: "Powerful features for B2B business",
      cardSubTitle: null,
      cardDescription:
        "QuickSell is built from the ground up for B2B businesses needs",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/b2b_main_image.webp",
      cardImageUrlFallback: "/assets/b2b_main_image.png",
      cardImageUrlFallback: null,
      cardImageHeight: "1651",
      cardImageWidth: "2700",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "powerfulFeaturesForB2bSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T02:58:41.645Z",
          updatedAt: "2022-06-23T17:29:21.545Z",
          publishedAt: "2022-06-21T02:58:43.073Z",
          locale: "en",
          internal_identifier: "home page - section 7 - logo 1",
          cardSubTitle: null,
          cardDescription:
            "With bulk ordering and 2 click checkout allow customers to order large number of items easily",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/fast_order_booking.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "21",
          cardImageWidth: "29",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Fast order booking",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T02:59:50.822Z",
          updatedAt: "2022-06-23T17:29:29.897Z",
          publishedAt: "2022-06-21T02:59:54.279Z",
          locale: "en",
          internal_identifier: "home page - section 7 - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Set minimum order limits and sell your products in sets to avoid single piece ordering",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2b_features.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "MOA, MOQ and Sets",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:00:45.330Z",
          updatedAt: "2022-06-23T17:29:36.762Z",
          publishedAt: "2022-06-21T03:00:46.628Z",
          locale: "en",
          internal_identifier: "home page - section 7 - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Create a private catalogue and protect your designs and prices",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/private_catalogue.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "18",
          cardImageWidth: "36",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:01:33.400Z",
          updatedAt: "2022-06-23T17:29:44.613Z",
          publishedAt: "2022-06-21T03:01:34.447Z",
          locale: "en",
          internal_identifier: "home page - section 7 - logo 4",
          cardSubTitle: null,
          cardDescription: "Create different pricing for B2B and B2C customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2b_pricing.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "24",
          cardImageWidth: "24",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "B2B pricing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
      linksList: [
        {
          text: "Learn More",
          url: "/private-showcasing",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-21T07:05:54.736Z",
          updatedAt: "2022-06-24T06:57:01.063Z",
          publishedAt: "2022-06-21T07:07:36.664Z",
          locale: "en",
          identifier_internal: "Private Showcasing Page Link",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
    },
    buildYourOwnECommerceAppSection: {
      id: 41,
      cardTitle: "Your own e-commerce app",
      cardSubTitle: null,
      cardDescription:
        "Curate catalogues for customers, create a personalized browsing experience and boost conversion rate and trust",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: "/assets/app_with_push_notification.mp4",
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "buildYourOwnECommerceAppSection",
      cardVideoControls: false,
      cardVideoAutoplay: true,
      cardVideoMuted: true,
      cardVideoLoop: true,
      cardVideoDisablePictureInPicture: true,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "video",
      cardVideoHeight: "1000",
      cardVideoWidth: "1000",
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T03:03:18.608Z",
          updatedAt: "2022-06-23T17:29:51.624Z",
          publishedAt: "2022-06-21T03:03:21.854Z",
          locale: "en",
          internal_identifier: "home page - section 8 - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Screenshot protection",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:03:51.809Z",
          updatedAt: "2022-06-23T17:34:09.594Z",
          publishedAt: "2022-06-21T03:03:54.450Z",
          locale: "en",
          internal_identifier: "home page - section 8 - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Push offers",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:04:21.799Z",
          updatedAt: "2022-06-23T17:34:16.620Z",
          publishedAt: "2022-06-21T03:04:25.300Z",
          locale: "en",
          internal_identifier: "home page - section 8 - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom layouting",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
      linksList: [
        {
          text: "Explore Native App",
          url: "/native-app",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-07-26T08:45:25.322Z",
          updatedAt: "2022-07-26T08:45:27.902Z",
          publishedAt: "2022-07-26T08:45:27.895Z",
          locale: "en",
          identifier_internal: "Explore Native App Page Link",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
    },
    playstoreReviewsSection: {
      id: 42,
      cardTitle: "Playstore reviews",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "playstoreReviewsSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      reviewsList: [
        {
          createdAt: "2022-06-20T18:06:58.940Z",
          updatedAt: "2022-06-23T16:53:37.790Z",
          publishedAt: "2022-06-21T01:06:47.764Z",
          locale: "en",
          content:
            '"Cool application, very convenient, I figured out what was happening for a long time, but as you adapt, things go easily, the only negative is that everything is in English, everything is clear in principle, but for customers it would be more convenient in Russian, and the description if you exit and re-enter erased, but these are trifles. The main catalog turns out to be convenient and pleasing to the eye (◍•ᴗ•◍)❤"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Russia",
          flagIconUrl: "/assets/flags/russia.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 1",
          sortOrder: 1,
        },
        {
          createdAt: "2022-06-22T10:56:25.486Z",
          updatedAt: "2022-06-23T16:55:07.082Z",
          publishedAt: "2022-06-22T10:56:32.599Z",
          locale: "en",
          content:
            '"This application fulfills its objective. The catalog is easy to use and the images shown to the client are of a good size. In addition, a large number of images can be uploaded at once. Fast and efficient."',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 2",
          sortOrder: 2,
        },
        {
          createdAt: "2022-06-22T10:57:16.317Z",
          updatedAt: "2022-06-24T06:01:42.276Z",
          publishedAt: "2022-06-22T10:57:18.297Z",
          locale: "en",
          content:
            '"I am selling via Whatsapp. Whatsapp in-house cataloging, product listing and canned responses, tags, shopping cart, etc. there are many features. However, this application offers more than all of these, especially for businesses that sell products, with a cleverly designed, fully user-friendly interface, interactive sharing and instant feedback to increase your sales, moreover, with your own domain name or using the sub-root name of the application."',
          reviewContentMaxLineCount: 6,
          flagCountry: "Turkey",
          flagIconUrl: "/assets/flags/turkey.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 3",
          sortOrder: 3,
        },
        {
          createdAt: "2022-06-22T10:57:58.024Z",
          updatedAt: "2022-06-25T12:01:16.907Z",
          publishedAt: "2022-06-22T10:58:00.356Z",
          locale: "en",
          content:
            '"The best app for both little and vip business. I\'m getting 4 time what I was earning before To change the price is so easy Just go on the catalogue Edit Change the price Save before exit Then quit the app and load it on the web"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Ivory Coast",
          flagIconUrl: "/assets/flags/ivory_coast.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 4",
          sortOrder: 4,
        },
        {
          createdAt: "2022-06-22T10:58:34.459Z",
          updatedAt: "2022-06-24T06:01:49.183Z",
          publishedAt: "2022-06-22T10:58:36.089Z",
          locale: "en",
          content:
            '"Absolutely the best, I tried to use other apps but this one gives affordable and easy use,professional, love it. Can we get a website or shop option, so one can hv both. Now I hv to hv a website from different provider and the is no communication btw the 2.well done othrwise"',
          reviewContentMaxLineCount: 6,
          flagCountry: "South Africa",
          flagIconUrl: "/assets/flags/south_africa.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 5",
          sortOrder: 5,
        },
        {
          createdAt: "2022-06-22T10:59:25.296Z",
          updatedAt: "2022-06-25T12:04:58.149Z",
          publishedAt: "2022-06-22T10:59:27.068Z",
          locale: "en",
          content:
            "\"I love it, they load the photos fast, I don't have to crop them, it lets me put several similar things and write the name of the item and price only once...It allows sharing catalog links or by item...LSLHO that's my code for if you want to use it 💞\"",
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 6",
          sortOrder: 6,
        },
        {
          createdAt: "2022-06-22T11:00:05.473Z",
          updatedAt: "2022-06-24T06:02:03.188Z",
          publishedAt: "2022-06-22T11:00:07.628Z",
          locale: "en",
          content:
            '"Really thankful to Yogesh from the tech team and Neeraj, Akanksha from support team..to be available at 1 am to sort out our issue. GREAT GUYS HATS OFF TO YOU ALL... KEEP UP WITH THIS PACE..THIS IS WHAT IS NEED OF THE HOUR... GOODNITES AND GOD BLESS"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 7",
          sortOrder: 7,
        },
        {
          createdAt: "2022-06-22T11:00:38.372Z",
          updatedAt: "2022-06-24T06:02:09.456Z",
          publishedAt: "2022-06-22T11:00:39.808Z",
          locale: "en",
          content:
            '"It is an application that is very helpful to me, to be able to make my own catalogs and show my products. Where you can modify in general or by unit, change the order of them, manage the inventory. Really super grateful to have met her, I recommend her."',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 8",
          sortOrder: 8,
        },
        {
          createdAt: "2022-06-22T11:01:09.387Z",
          updatedAt: "2022-06-24T06:02:19.037Z",
          publishedAt: "2022-06-22T11:01:10.660Z",
          locale: "en",
          content:
            '"Very impressive and a need of the time business solution app. This app has many important features like product sharing to social media, cataloguing, simple UI for anyone to understand. Mr.Ritesh and the tech term are very professional and given the complete demo of the product which helped me buy the subscription. I strongly recommend this app if you want to upscale your business professionally."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 9",
          sortOrder: 9,
        },
        {
          createdAt: "2022-06-22T11:01:37.500Z",
          updatedAt: "2022-06-23T16:53:50.588Z",
          publishedAt: "2022-06-22T11:01:38.719Z",
          locale: "en",
          content:
            '"On the trial subscription and already loving this app. Take away the stress of individual positing of item. Just post your catalogue on any social media or your contacts and voila! Looking forward to having a visa payment option to receive money."',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 10",
          sortOrder: 10,
        },
        {
          createdAt: "2022-06-22T11:02:17.636Z",
          updatedAt: "2022-06-23T16:53:57.844Z",
          publishedAt: "2022-06-22T11:02:19.588Z",
          locale: "en",
          content:
            '"One of the best apps seller app available in the market. Genuine, user-friendly, customer service is good. I personally have tried a lot other apps, but this one is till now the best I have used. I will promote this in my YouTube channels as well."',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 11",
          sortOrder: 11,
        },
        {
          createdAt: "2022-06-22T11:03:19.857Z",
          updatedAt: "2022-06-23T16:54:06.470Z",
          publishedAt: "2022-06-22T11:03:21.618Z",
          locale: "en",
          content:
            '"Best app for any kind of business.Using same platform one can create different listings for, customer page, reseller page and for B2B as well.Very esay to operate and share.Highly recommended for small business owners."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 12",
          sortOrder: 12,
        },
        {
          createdAt: "2022-06-22T11:02:49.530Z",
          updatedAt: "2022-06-25T12:01:47.518Z",
          publishedAt: "2022-06-22T11:02:50.977Z",
          locale: "en",
          content:
            '"A very dynamic user friendly App.It makes my work so much easier and the support is very awesome. I was searching for such an App for a long time. Its a boon for small businesses and there\'s no need for a website at all if you have this App. I sure want to extend my appreciation to the developer and the team here in India."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 13",
          sortOrder: 13,
        },
        {
          createdAt: "2022-06-22T11:03:51.598Z",
          updatedAt: "2022-06-23T16:54:20.589Z",
          publishedAt: "2022-06-22T11:03:52.750Z",
          locale: "en",
          content:
            '"First of all deep gratitude to the team quicksell for their prompt & positive response, support and consistent guidance.Especially to theyoung Entrapreure with special needs.We experienced quick processing from our customers on this app.We also like that quick sell is always welcoming the suggestions from its user for further improvement in the services."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 14",
          sortOrder: 14,
        },
        {
          createdAt: "2022-06-22T11:04:23.556Z",
          updatedAt: "2022-06-23T16:54:28.782Z",
          publishedAt: "2022-06-22T11:04:24.961Z",
          locale: "en",
          content:
            '"Finally a app that understands and solved problems related to niche business requirements.Private catalogue feature has worked wonder for us.Simple and straightforward to use."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 15",
          sortOrder: 15,
        },
        {
          createdAt: "2022-06-22T11:04:58.529Z",
          updatedAt: "2022-06-23T16:54:36.439Z",
          publishedAt: "2022-06-22T11:05:00.310Z",
          locale: "en",
          content:
            '"ms.khusbu ji you sold me early gave good reference thank you very much from the team of balotra textile mam your precious time has been the point of growth of our business✨✨"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 16",
          sortOrder: 16,
        },
        {
          createdAt: "2022-06-22T11:05:36.396Z",
          updatedAt: "2022-06-25T12:02:12.023Z",
          publishedAt: "2022-06-22T11:05:38.509Z",
          locale: "en",
          content:
            '"Well Amazing aap for all kind of business .it\'s works just like a sales man .. good osm aap I like it....and I apriciate other also to install this aap ...Thanku..."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 17",
          sortOrder: 17,
        },
        {
          createdAt: "2022-06-22T11:06:12.920Z",
          updatedAt: "2022-06-23T16:54:51.671Z",
          publishedAt: "2022-06-22T11:06:14.249Z",
          locale: "en",
          content:
            '"Just started with top plan platinum..MR Anurag attend so well and explain each and everything very perfectly..All commitments are on time..So far so good 👍"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 18",
          sortOrder: 18,
        },
        {
          createdAt: "2022-06-22T11:06:43.856Z",
          updatedAt: "2022-06-23T16:54:59.345Z",
          publishedAt: "2022-06-22T11:06:45.368Z",
          locale: "en",
          content:
            '"Excellent software to manage your business on digital platform.We are using this software since last one year and my experience is too good with this tool."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 19",
          sortOrder: 19,
        },
        {
          createdAt: "2022-06-22T11:07:17.560Z",
          updatedAt: "2022-06-23T16:55:15.349Z",
          publishedAt: "2022-06-22T11:07:19.184Z",
          locale: "en",
          content:
            '"Quite unique and easy to operate....I just love it.I have tried many e- commerce apps but this one is different and looking positively to do business."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 20",
          sortOrder: 20,
        },
        {
          createdAt: "2022-06-22T11:07:52.997Z",
          updatedAt: "2022-06-23T16:55:23.887Z",
          publishedAt: "2022-06-22T11:07:53.948Z",
          locale: "en",
          content:
            '"Thankyou quicksell for such an amazing experience..Mr.ritesh sharma attend politely and explained everything so well..Thanks to the entire team.."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 21",
          sortOrder: 21,
        },
        {
          createdAt: "2022-06-22T11:08:27.733Z",
          updatedAt: "2022-06-23T16:55:31.600Z",
          publishedAt: "2022-06-22T11:08:28.967Z",
          locale: "en",
          content:
            '"Easy to operate with a good service and support from the team.Good for all types of business, easy to reach out customers to show the products."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 22",
          sortOrder: 22,
        },
        {
          createdAt: "2022-06-22T11:08:56.080Z",
          updatedAt: "2022-06-25T12:02:25.645Z",
          publishedAt: "2022-06-22T11:08:57.454Z",
          locale: "en",
          content:
            '"It\'s the wonderful product for new startup business or for those who want to sell and share there products through online and e-commerce"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 23",
          sortOrder: 23,
        },
        {
          createdAt: "2022-06-22T11:09:34.936Z",
          updatedAt: "2022-06-25T12:04:33.814Z",
          publishedAt: "2022-06-22T11:09:36.114Z",
          locale: "en",
          content:
            "\"This app is the best.I've been using QuickSell for over 3 years now and I don't regret using it.It's a friendly app\"",
          reviewContentMaxLineCount: 6,
          flagCountry: "Nigeria",
          flagIconUrl: "/assets/flags/nigeria.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 24",
          sortOrder: 24,
        },
        {
          createdAt: "2022-06-22T11:10:03.275Z",
          updatedAt: "2022-06-23T16:55:56.881Z",
          publishedAt: "2022-06-22T11:10:04.629Z",
          locale: "en",
          content:
            '"Available, Reliable, Affordable.As a Consumers and Sellers both are handling professionally as well as friendly."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 25",
          sortOrder: 25,
        },
        {
          createdAt: "2022-06-22T11:10:37.095Z",
          updatedAt: "2022-06-23T16:56:20.770Z",
          publishedAt: "2022-06-22T11:10:38.225Z",
          locale: "en",
          content:
            '"I love this App...It is super practical to speed up sales and super organized, it deserves those 5 stars"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 26",
          sortOrder: 26,
        },
        {
          createdAt: "2022-06-22T11:11:01.694Z",
          updatedAt: "2022-06-23T16:56:30.902Z",
          publishedAt: "2022-06-22T11:11:02.985Z",
          locale: "en",
          content:
            '"Amazing user friendly experience, now I can book more orders in less time A complete hassle free experience."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 27",
          sortOrder: 27,
        },
        {
          createdAt: "2022-06-22T11:11:31.672Z",
          updatedAt: "2022-06-23T16:56:55.503Z",
          publishedAt: "2022-06-22T11:11:33.928Z",
          locale: "en",
          content:
            '"Simply the best Catalog.Downloaded a couple of other catalogs but none is as good as the quicksell app"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Zambia",
          flagIconUrl: "/assets/flags/zambia.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 28",
          sortOrder: 28,
        },
        {
          createdAt: "2022-06-22T11:12:03.032Z",
          updatedAt: "2022-06-23T16:57:04.525Z",
          publishedAt: "2022-06-22T11:12:04.433Z",
          locale: "en",
          content:
            '"This application is very good, loads photos quickly and is very practical, I highly recommend it ✳️✳️✳️✳️✳️"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 29",
          sortOrder: 29,
        },
        {
          createdAt: "2022-06-22T11:12:28.839Z",
          updatedAt: "2022-06-23T16:57:12.895Z",
          publishedAt: "2022-06-22T11:12:30.178Z",
          locale: "en",
          content:
            '"Quick sell team provides value for money services Easy to use and user friendly Really appreciated"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 30",
          sortOrder: 30,
        },
        {
          createdAt: "2022-06-22T11:13:46.592Z",
          updatedAt: "2022-06-23T16:57:20.149Z",
          publishedAt: "2022-06-22T11:13:48.488Z",
          locale: "en",
          content:
            '"Owesome ....Easy to use Easy to upload Easy to manage Easy to share Everything is very nice..."',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 31",
          sortOrder: 31,
        },
        {
          createdAt: "2022-06-22T11:13:11.898Z",
          updatedAt: "2022-06-23T16:57:27.521Z",
          publishedAt: "2022-06-22T11:13:13.115Z",
          locale: "en",
          content:
            '"Very good hai ji, use karne main bahut easy hai and looking very helpful to grow business.Thanks"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 32",
          sortOrder: 32,
        },
        {
          createdAt: "2022-06-22T11:14:22.061Z",
          updatedAt: "2022-06-23T16:57:35.801Z",
          publishedAt: "2022-06-22T11:14:23.403Z",
          locale: "en",
          content:
            '"Excellent app I liked it because of all the ones I have tried it is the only one that connects with WhatsApp"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 33",
          sortOrder: 33,
        },
        {
          createdAt: "2022-06-22T11:14:49.940Z",
          updatedAt: "2022-06-23T16:57:43.714Z",
          publishedAt: "2022-06-22T11:14:51.316Z",
          locale: "en",
          content:
            '"I think it is a simple application to use and it provides us with a lot of information as sellers."',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 34",
          sortOrder: 34,
        },
        {
          createdAt: "2022-06-22T11:15:23.038Z",
          updatedAt: "2022-06-23T16:57:51.943Z",
          publishedAt: "2022-06-22T11:15:24.826Z",
          locale: "en",
          content:
            '"Since I have QuickSell, sales have improved a lot, resellers need to improve, I like it"',
          reviewContentMaxLineCount: 6,
          flagCountry: "Spain",
          flagIconUrl: "/assets/flags/spain.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 35",
          sortOrder: 35,
        },
        {
          createdAt: "2022-06-22T11:16:05.887Z",
          updatedAt: "2022-06-23T16:58:01.425Z",
          publishedAt: "2022-06-22T11:16:07.263Z",
          locale: "en",
          content:
            '"It is excellent, i am still exploring different features but so far it is found simply great 👍"',
          reviewContentMaxLineCount: 6,
          flagCountry: "India",
          flagIconUrl: "/assets/flags/india.svg",
          flagIconHeight: "17",
          flagIconWidth: "25",
          starCount: 5,
          identifier_internal: "review 36",
          sortOrder: 36,
        },
      ],
    },
    videoPlayListSection: {
      id: 43,
      cardTitle: "Customer Reviews",
      cardSubTitle: null,
      cardDescription: "From&nbsp;&nbsp;Jaipur&nbsp;to&nbsp;Johannesburg",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "videoPlayListSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T03:07:02.634Z",
          updatedAt: "2022-07-06T11:43:10.766Z",
          publishedAt: "2022-06-21T03:07:04.019Z",
          locale: "en",
          internal_identifier: "home page - section 9 - logo 1",
          cardSubTitle: null,
          cardDescription: "Jewellery wholesaler from Ahmedabad, India",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl:
            "https://youtube.com/embed/pHQibTdgYSw?autoplay=1&muted=0&controls=1",
          cardVideoPosterUrl: "/assets/pd_soni_testimonial_poster.webp",
          cardVideoPosterUrlFallback: "/assets/pd_soni_testimonial_poster.png",
          cardVideoPosterHeight: "200",
          cardVideoPosterWidth: "276",
          cardTitle: "PD Soni Jewels Pvt Ltd",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: "200",
          cardVideoWidth: "276",
        },
        {
          createdAt: "2022-06-21T03:07:51.548Z",
          updatedAt: "2022-07-06T11:43:19.548Z",
          publishedAt: "2022-06-21T03:07:55.671Z",
          locale: "en",
          internal_identifier: "home page - section 9 - logo 2",
          cardSubTitle: null,
          cardDescription: "Retailer from Delhi, India",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl:
            "https://youtube.com/embed/wCYHIr9ZbsU?autoplay=1&muted=0&controls=1",
          cardVideoPosterUrl: "/assets/aggarwal_saree_testimonial_poster.webp",
          cardVideoPosterUrlFallback:
            "/assets/aggarwal_saree_testimonial_poster.png",
          cardVideoPosterHeight: "200",
          cardVideoPosterWidth: "276",
          cardTitle: "Aggarwal Saree Center",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: "200",
          cardVideoWidth: "276",
        },
        {
          createdAt: "2022-06-21T03:09:33.547Z",
          updatedAt: "2022-07-06T11:43:27.282Z",
          publishedAt: "2022-06-21T03:09:36.449Z",
          locale: "en",
          internal_identifier: "home page - section 9 - logo 3",
          cardSubTitle: null,
          cardDescription: "Wholesaler from Jaipur, India",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl:
            "https://youtube.com/embed/SHQthVP6DPY?autoplay=1&muted=0&controls=1",
          cardVideoPosterUrl:
            "/assets/shri_handicrafts_testimonial_poster.webp",
          cardVideoPosterUrlFallback:
            "/assets/shri_handicrafts_testimonial_poster.png",
          cardVideoPosterHeight: "200",
          cardVideoPosterWidth: "276",
          cardTitle: "Shri Handicrafts Shop",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: "200",
          cardVideoWidth: "276",
        },
        {
          createdAt: "2022-06-21T03:10:15.340Z",
          updatedAt: "2022-07-06T11:43:34.118Z",
          publishedAt: "2022-06-21T03:10:18.179Z",
          locale: "en",
          internal_identifier: "home page - section 9 - logo 4",
          cardSubTitle: null,
          cardDescription: "Makeup Store from Bolivia",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl:
            "https://youtube.com/embed/NQVILLf2VAY?autoplay=1&muted=0&controls=1",
          cardVideoPosterUrl: "/assets/pam_makeup_testimonial_poster.webp",
          cardVideoPosterUrlFallback:
            "/assets/pam_makeup_testimonial_poster.png",
          cardVideoPosterHeight: "200",
          cardVideoPosterWidth: "276",
          cardTitle: "PAM Makeup Store",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: "200",
          cardVideoWidth: "276",
        },
        {
          createdAt: "2022-06-21T03:11:12.385Z",
          updatedAt: "2022-07-06T11:43:40.897Z",
          publishedAt: "2022-06-21T03:11:15.328Z",
          locale: "en",
          internal_identifier: "home page - section 9 - logo 5",
          cardSubTitle: null,
          cardDescription: "Manufacturer from Mumbai, India",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "",
          cardImageUrlFallback: null,
          cardImageHeight: "200",
          cardImageWidth: "276",
          cardVideoUrl:
            "https://www.youtube.com/embed/bxPFSX-RQ0U?autoplay=1&muted=0&controls=1",
          cardVideoPosterUrl: "/assets/hd_gold_testimonial_poster.webp",
          cardVideoPosterUrlFallback: "/assets/hd_gold_testimonial_poster.png",
          cardVideoPosterHeight: "200",
          cardVideoPosterWidth: "276",
          cardTitle: "HD Gold Jewellery Store",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: "200",
          cardVideoWidth: "276",
        },
        {
          createdAt: "2022-06-21T03:11:53.583Z",
          updatedAt: "2022-07-06T11:43:52.864Z",
          publishedAt: "2022-06-21T03:11:55.128Z",
          locale: "en",
          internal_identifier: "home page - section 9 - logo 6",
          cardSubTitle: null,
          cardDescription: "Manufacturer from Andhra Pradesh, India",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: "200",
          cardImageWidth: "276",
          cardVideoUrl:
            "https://youtube.com/embed/lZibd53A4R4?autoplay=1&muted=0&controls=1",
          cardVideoPosterUrl: "/assets/khushal_sanghvi_testimonial_poster.webp",
          cardVideoPosterUrlFallback:
            "/assets/khushal_sanghvi_testimonial_poster.png",
          cardVideoPosterHeight: "200",
          cardVideoPosterWidth: "276",
          cardTitle: "Khushal Sanghvi Jewellers",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: "200",
          cardVideoWidth: "276",
        },
      ],
    },
    talkToAnExpertSection: {
      id: 44,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "talkToAnExpertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "Home Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  cataloguingPageData: {
    createdAt: "2022-06-21T12:22:34.113Z",
    updatedAt: "2022-06-24T18:44:56.586Z",
    publishedAt: "2022-06-24T18:44:56.402Z",
    locale: "en",
    flexibleOrderSection: {
      id: 49,
      cardTitle: "KEY FEATURE",
      cardSubTitle:
        "Create beautiful catalogues for whatever use-case you can imagine",
      cardDescription:
        "One catalogue per customer, one catalogue per theme, one catalogue per price range - you choose how you want to setup your showcase",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/cataloguing_main_image.webp",
      cardImageUrlFallback: "/assets/cataloguing_main_image.png",
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "flexibleOrderSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    expertSection: {
      id: 50,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    catalogueCommerceSection: {
      id: 51,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle:
        '<span>Complete flexibility of showcasing with Curated<br /> <span class="text-green">Catalogue Commerce</span></span>',
      sectionSubTitle:
        "With QuickSell, you choose how you want to showcase your products",
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "catalogueCommerceSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T12:47:54.956Z",
          updatedAt: "2022-06-23T17:03:04.372Z",
          publishedAt: "2022-06-21T12:47:56.812Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Create a catalogue for a particular customer with their name",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/personal_trainer.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "46",
          cardImageWidth: "50",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Personal cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:09.721Z",
          updatedAt: "2022-06-23T17:03:12.974Z",
          publishedAt: "2022-06-21T12:49:11.269Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            'Create a catalogue for a particular theme like "Christmas", "Diwali", etc',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/christmas_tree.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "46",
          cardImageWidth: "38",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Theme based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:58.483Z",
          updatedAt: "2022-06-23T17:03:21.202Z",
          publishedAt: "2022-06-21T12:49:59.941Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            'Create a catalogue for a particular time duration like - "March 2022", etc',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/time_based_cataloguing.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "36",
          cardImageWidth: "36",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Time based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:50:57.775Z",
          updatedAt: "2022-06-23T17:03:31.216Z",
          publishedAt: "2022-06-21T12:50:59.229Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            'Create a catalogue for a particular price range like - "Dresses less than $25"',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/price_based_cataloguing.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "50",
          cardImageWidth: "50",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Price based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:51:52.930Z",
          updatedAt: "2022-06-23T17:03:40.521Z",
          publishedAt: "2022-06-21T12:51:54.343Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "Create a catalogue separately for B2B customers only or for any other similar use-case",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2b_b2c_based_cataloguing.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "46",
          cardImageWidth: "46",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "B2B/B2C based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:52:43.686Z",
          updatedAt: "2022-06-23T17:03:48.894Z",
          publishedAt: "2022-06-21T12:52:45.511Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 6",
          cardSubTitle: null,
          cardDescription: "Launch a catalogue with items on offer only",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/coupon_code.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "22",
          cardImageWidth: "35",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Offer based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:53:26.456Z",
          updatedAt: "2022-06-23T17:03:58.086Z",
          publishedAt: "2022-06-21T12:53:27.860Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 7",
          cardSubTitle: null,
          cardDescription:
            'Create a catalogue for a particular location - "Mumbai collection", etc',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/location.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "50",
          cardImageWidth: "34",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Location based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:54:04.255Z",
          updatedAt: "2022-06-23T17:04:08.235Z",
          publishedAt: "2022-06-21T12:54:05.581Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - catalogueCommerceSection - logo 8",
          cardSubTitle: null,
          cardDescription:
            'Create a catalogue for a particular color or size - "Dresses in size XL"',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/variant_based_cataloguing.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "36",
          cardImageWidth: "36",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Variant based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    digitalProductSection: {
      id: 52,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle:
        "Your products deserve a with beautiful mobile-optimized digital product showcase",
      sectionSubTitle: "3 powerful in-built product layouts",
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "digitalProductSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-23T06:03:41.077Z",
          updatedAt: "2022-06-23T17:05:45.383Z",
          publishedAt: "2022-06-23T06:03:43.487Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - digitalProductSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/digital_product_showcase_1.webp",
          cardImageUrlFallback: "/assets/digital_product_showcase_1.png",
          cardImageHeight: "1212",
          cardImageWidth: "822",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Grid layout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          featuresList: [
            {
              createdAt: "2022-06-23T06:07:50.166Z",
              updatedAt: "2022-06-23T17:05:56.837Z",
              publishedAt: "2022-06-23T06:07:51.656Z",
              locale: "en",
              internal_identifier:
                "cataloguingPageData - digitalProductSection - logo 1 - section 1",
              cardSubTitle: null,
              cardDescription: "Good for showcasing larger number of products",
              sectionTitle: null,
              sectionSubTitle: null,
              cardImageUrl: "/assets/green_check.svg",
              cardImageUrlFallback: null,
              cardImageHeight: "25",
              cardImageWidth: "25",
              cardVideoUrl: null,
              cardVideoPosterUrl: null,
              cardVideoPosterUrlFallback: null,
              cardVideoPosterHeight: null,
              cardVideoPosterWidth: null,
              cardTitle: null,
              linkUrl: null,
              openInNewTab: false,
              cardVideoControls: false,
              cardVideoAutoplay: false,
              cardVideoMuted: false,
              cardVideoLoop: false,
              cardVideoDisablePictureInPicture: false,
              cardVideoAllowFullScreen: false,
              sectionDescription: null,
              cardVideoTagName: "iframe",
              internal_column: null,
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              cardVideoHeight: null,
              cardVideoWidth: null,
            },
          ],
        },
        {
          createdAt: "2022-06-23T06:05:17.876Z",
          updatedAt: "2022-06-23T17:06:07.768Z",
          publishedAt: "2022-06-23T06:05:19.603Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - digitalProductSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/digital_product_showcase_2.webp",
          cardImageUrlFallback: "/assets/digital_product_showcase_2.png",
          cardImageHeight: "1212",
          cardImageWidth: "822",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Magazine layout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
          featuresList: [
            {
              createdAt: "2022-06-23T06:08:27.853Z",
              updatedAt: "2022-06-23T17:06:17.806Z",
              publishedAt: "2022-06-23T06:08:29.380Z",
              locale: "en",
              internal_identifier:
                "cataloguingPageData - digitalProductSection - logo 2 - section 1",
              cardSubTitle: null,
              cardDescription: "Showcasing higher value items",
              sectionTitle: null,
              sectionSubTitle: null,
              cardImageUrl: "/assets/green_check.svg",
              cardImageUrlFallback: null,
              cardImageHeight: "25",
              cardImageWidth: "25",
              cardVideoUrl: null,
              cardVideoPosterUrl: null,
              cardVideoPosterUrlFallback: null,
              cardVideoPosterHeight: null,
              cardVideoPosterWidth: null,
              cardTitle: null,
              linkUrl: null,
              openInNewTab: false,
              cardVideoControls: false,
              cardVideoAutoplay: false,
              cardVideoMuted: false,
              cardVideoLoop: false,
              cardVideoDisablePictureInPicture: false,
              cardVideoAllowFullScreen: false,
              sectionDescription: null,
              cardVideoTagName: "iframe",
              internal_column: null,
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              cardVideoHeight: null,
              cardVideoWidth: null,
            },
            {
              createdAt: "2022-06-23T06:09:11.282Z",
              updatedAt: "2022-06-23T17:06:28.639Z",
              publishedAt: "2022-06-23T06:09:14.682Z",
              locale: "en",
              internal_identifier:
                "cataloguingPageData - digitalProductSection - logo 2 - section 2",
              cardSubTitle: null,
              cardDescription: "Quick B2B order booking",
              sectionTitle: null,
              sectionSubTitle: null,
              cardImageUrl: "/assets/green_check.svg",
              cardImageUrlFallback: null,
              cardImageHeight: "25",
              cardImageWidth: "25",
              cardVideoUrl: null,
              cardVideoPosterUrl: null,
              cardVideoPosterUrlFallback: null,
              cardVideoPosterHeight: null,
              cardVideoPosterWidth: null,
              cardTitle: null,
              linkUrl: null,
              openInNewTab: false,
              cardVideoControls: false,
              cardVideoAutoplay: false,
              cardVideoMuted: false,
              cardVideoLoop: false,
              cardVideoDisablePictureInPicture: false,
              cardVideoAllowFullScreen: false,
              sectionDescription: null,
              cardVideoTagName: "iframe",
              internal_column: null,
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              cardVideoHeight: null,
              cardVideoWidth: null,
            },
          ],
        },
        {
          createdAt: "2022-06-23T06:06:01.498Z",
          updatedAt: "2022-06-23T17:06:38.708Z",
          publishedAt: "2022-06-23T06:06:03.661Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - digitalProductSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/digital_product_showcase_3.webp",
          cardImageUrlFallback: "/assets/digital_product_showcase_3.png",
          cardImageHeight: "1212",
          cardImageWidth: "822",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "List layout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
          featuresList: [
            {
              createdAt: "2022-06-23T06:10:06.872Z",
              updatedAt: "2022-06-23T17:06:49.517Z",
              publishedAt: "2022-06-23T06:10:08.267Z",
              locale: "en",
              internal_identifier:
                "cataloguingPageData - digitalProductSection - logo 3 - section 1",
              cardSubTitle: null,
              cardDescription: "Quick B2C order booking",
              sectionTitle: null,
              sectionSubTitle: null,
              cardImageUrl: "/assets/green_check.svg",
              cardImageUrlFallback: null,
              cardImageHeight: "25",
              cardImageWidth: "25",
              cardVideoUrl: null,
              cardVideoPosterUrl: null,
              cardVideoPosterUrlFallback: null,
              cardVideoPosterHeight: null,
              cardVideoPosterWidth: null,
              cardTitle: null,
              linkUrl: null,
              openInNewTab: false,
              cardVideoControls: false,
              cardVideoAutoplay: false,
              cardVideoMuted: false,
              cardVideoLoop: false,
              cardVideoDisablePictureInPicture: false,
              cardVideoAllowFullScreen: false,
              sectionDescription: null,
              cardVideoTagName: "iframe",
              internal_column: null,
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              cardVideoHeight: null,
              cardVideoWidth: null,
            },
            {
              createdAt: "2022-06-23T06:10:35.796Z",
              updatedAt: "2022-06-23T17:06:59.360Z",
              publishedAt: "2022-06-23T06:10:37.498Z",
              locale: "en",
              internal_identifier:
                "cataloguingPageData - digitalProductSection - logo 3 - section 2",
              cardSubTitle: null,
              cardDescription: "Groceries, Restaurants and Food",
              sectionTitle: null,
              sectionSubTitle: null,
              cardImageUrl: "/assets/green_check.svg",
              cardImageUrlFallback: null,
              cardImageHeight: "25",
              cardImageWidth: "25",
              cardVideoUrl: null,
              cardVideoPosterUrl: null,
              cardVideoPosterUrlFallback: null,
              cardVideoPosterHeight: null,
              cardVideoPosterWidth: null,
              cardTitle: null,
              linkUrl: null,
              openInNewTab: false,
              cardVideoControls: false,
              cardVideoAutoplay: false,
              cardVideoMuted: false,
              cardVideoLoop: false,
              cardVideoDisablePictureInPicture: false,
              cardVideoAllowFullScreen: false,
              sectionDescription: null,
              cardVideoTagName: "iframe",
              internal_column: null,
              columnName: null,
              sectionName: null,
              sortOrder: 2,
              cardVideoHeight: null,
              cardVideoWidth: null,
            },
          ],
        },
      ],
    },
    createYourOwnCustomLayoutSection: {
      id: 54,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: "Create your own custom layout",
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "createYourOwnCustomLayoutSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T13:02:41.278Z",
          updatedAt: "2022-06-23T17:04:18.043Z",
          publishedAt: "2022-06-21T13:02:48.657Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - createYourOwnCustomLayoutSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Add one of more banners and link to catalogue, product or any other website",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Banners",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: "",
          columnName: "leftColumn",
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:04:01.154Z",
          updatedAt: "2022-06-23T17:04:28.280Z",
          publishedAt: "2022-06-21T13:04:04.802Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - createYourOwnCustomLayoutSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Define a catalogues section by selecting one or more catalogues from your product library",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Catalogue section",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: "",
          columnName: "leftColumn",
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:05:01.310Z",
          updatedAt: "2022-06-23T17:04:36.576Z",
          publishedAt: "2022-06-21T13:05:04.348Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - createYourOwnCustomLayoutSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/create_your_own_custom_layout.webp",
          cardImageUrlFallback: "/assets/create_your_own_custom_layout.png",
          cardImageHeight: "3128",
          cardImageWidth: "720",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: "",
          columnName: "centerColumn",
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:07:12.429Z",
          updatedAt: "2022-06-23T17:04:45.692Z",
          publishedAt: "2022-06-21T13:07:16.646Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - createYourOwnCustomLayoutSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Select products from any catalogue and define a products section to show off discounted products or limited editions",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Products section",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    customThemesSection: {
      id: 53,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: "Themes",
      sectionSubTitle:
        "Create your own theme or choose one of the <span class='text-bold'>50+</span> in-built themes",
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customThemesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T13:08:59.647Z",
          updatedAt: "2022-06-23T17:04:53.980Z",
          publishedAt: "2022-06-21T13:09:01.725Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - customThemesSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: "",
          sectionSubTitle: "",
          cardImageUrl: "/assets/custom_theme_1.webp",
          cardImageUrlFallback: "/assets/custom_theme_1.png",
          cardImageHeight: "457",
          cardImageWidth: "337",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:09:35.399Z",
          updatedAt: "2022-06-23T17:05:01.408Z",
          publishedAt: "2022-06-21T13:09:37.089Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - customThemesSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_theme_2.webp",
          cardImageUrlFallback: "/assets/custom_theme_2.png",
          cardImageHeight: "457",
          cardImageWidth: "337",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:10:06.649Z",
          updatedAt: "2022-06-23T17:05:11.394Z",
          publishedAt: "2022-06-21T13:10:08.521Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - customThemesSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_theme_3.webp",
          cardImageUrlFallback: "/assets/custom_theme_3.png",
          cardImageHeight: "457",
          cardImageWidth: "337",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:10:39.468Z",
          updatedAt: "2022-06-23T17:05:27.045Z",
          publishedAt: "2022-06-21T13:10:40.876Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - customThemesSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_theme_4.webp",
          cardImageUrlFallback: "/assets/custom_theme_4.png",
          cardImageHeight: "457",
          cardImageWidth: "337",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:11:14.537Z",
          updatedAt: "2022-06-23T17:05:35.942Z",
          publishedAt: "2022-06-21T13:11:16.532Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - customThemesSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/custom_theme_5.webp",
          cardImageUrlFallback: "/assets/custom_theme_5.png",
          cardImageHeight: "457",
          cardImageWidth: "337",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    beautyInSimplicitySection: {
      id: 55,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: "There is beauty in simplicity",
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "beautyInSimplicitySection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T13:12:45.352Z",
          updatedAt: "2022-06-24T13:07:51.021Z",
          publishedAt: "2022-06-21T13:12:47.415Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - beautyInSimplicitySection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Designed for simplicity<br />Create any number of sub-categories",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sub-categories",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "leftColumn",
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:13:30.711Z",
          updatedAt: "2022-06-23T17:02:27.761Z",
          publishedAt: "2022-06-21T13:13:32.215Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - beautyInSimplicitySection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/beauty_in_simplicity.webp",
          cardImageUrlFallback: "/assets/beauty_in_simplicity.png",
          cardImageHeight: "1610",
          cardImageWidth: "736",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "centerColumn",
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:14:23.633Z",
          updatedAt: "2022-06-23T17:02:36.706Z",
          publishedAt: "2022-06-21T13:14:25.776Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - beautyInSimplicitySection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Powerful search",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:15:19.641Z",
          updatedAt: "2022-06-25T12:06:12.633Z",
          publishedAt: "2022-06-21T13:15:21.867Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - beautyInSimplicitySection - logo 4",
          cardSubTitle: null,
          cardDescription:
            '<div style="margin-top: 24px;">ADD TO CART</div><div style="margin-top: 24px;">ADD TO LIST</div><div style="margin-top: 24px;">REQUEST QUOTE</div><div style="margin-top: 24px;">SEND INQUIRY</div>',
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom action button",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T13:16:14.821Z",
          updatedAt: "2022-06-23T17:02:54.855Z",
          publishedAt: "2022-06-21T13:16:16.221Z",
          locale: "en",
          internal_identifier:
            "cataloguingPageData - beautyInSimplicitySection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "Simple 2 page checkout flow without the need of signing up and filling complicated long forms",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/cataloguing_page_arrow.webp",
          cardImageUrlFallback: "/assets/cataloguing_page_arrow.png",
          cardImageHeight: "210",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Simple Checkout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "bottomColumn",
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
  },
  liveAnalyticsPageData: {
    createdAt: "2022-06-21T11:31:16.385Z",
    updatedAt: "2022-07-05T17:18:10.190Z",
    publishedAt: "2022-06-21T11:31:19.358Z",
    locale: "en",
    flexibleOrderSection: {
      id: 45,
      cardTitle: "KEY FEATURE",
      cardSubTitle:
        "Increase your conversion rate with <span class='text-green'>Live Analytics and Real-time Tracking</span>",
      cardDescription:
        "Track customer activity in real-time - reach out to the right buyers at the right time",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/analytics_main_image.webp",
      cardImageUrlFallback: "/assets/analytics_main_image.png",
      cardImageUrlFallback: null,
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "flexibleOrderSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: "",
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    watchCustomersSection: {
      id: 46,
      cardTitle:
        "Watch customers as they buy, the same way it happens in your physical store",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "watchCustomersSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T11:36:04.948Z",
          updatedAt: "2022-06-23T17:21:32.751Z",
          publishedAt: "2022-06-21T11:36:06.743Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - watchCustomersSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Follow up with customers immediately as and when they are viewing your catalogue for a higher conversion rate",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/watch_customers_physical_store_1.webp",
          cardImageUrlFallback: "/assets/watch_customers_physical_store_1.png",
          cardImageHeight: "742",
          cardImageWidth: "940",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Follow up at the right time",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T11:36:56.799Z",
          updatedAt: "2022-06-23T17:21:40.376Z",
          publishedAt: "2022-06-21T11:36:58.347Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - watchCustomersSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Analyze what products customers are spending most time on and which products customers are not even opening - and plan your future catalogues with the right insights",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/watch_customers_physical_store_2.webp",
          cardImageUrlFallback: "/assets/watch_customers_physical_store_2.png",
          cardImageHeight: "742",
          cardImageWidth: "758",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Demand analysis",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T11:37:36.205Z",
          updatedAt: "2022-06-23T17:21:47.109Z",
          publishedAt: "2022-06-21T11:37:38.352Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - watchCustomersSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Ignore the window shoppers and turn serious buyers into customers by following up with people who are most interested",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/watch_customers_physical_store_3.webp",
          cardImageUrlFallback: "/assets/watch_customers_physical_store_3.png",
          cardImageHeight: "742",
          cardImageWidth: "991",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Focus on serious buyers",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    realTimeVisitorAnalyticsSection: {
      id: 47,
      cardTitle: "Real-time visitor analytics",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "realTimeVisitorAnalyticsSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-23T09:26:08.867Z",
          updatedAt: "2022-06-23T17:19:59.856Z",
          publishedAt: "2022-06-23T09:26:10.189Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 1",
          cardSubTitle: "Total view time",
          cardDescription:
            "Total amount of time that the customer has spent viewing a particular catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "14:13",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "leftColumn",
          sectionName: "customInformationSection",
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:26:43.758Z",
          updatedAt: "2022-06-23T17:20:26.617Z",
          publishedAt: "2022-06-23T09:26:45.416Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 2",
          cardSubTitle: "Opens",
          cardDescription:
            "How many times a particular customer has opened a particular catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "15",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "leftColumn",
          sectionName: "customInformationSection",
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:43:23.594Z",
          updatedAt: "2022-06-23T17:20:33.876Z",
          publishedAt: "2022-06-23T09:43:25.962Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/real_time_visitor_analytics_1.webp",
          cardImageUrlFallback: "/assets/real_time_visitor_analytics_1.jpg",
          cardImageHeight: "1324",
          cardImageWidth: "1306",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "centerColumn",
          sectionName: "customInformationSection",
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:29:06.503Z",
          updatedAt: "2022-06-23T17:20:42.751Z",
          publishedAt: "2022-06-23T09:29:13.161Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 4",
          cardSubTitle: "Percentage viewed",
          cardDescription:
            "What % of products have been viewed in your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "83%",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: "customInformationSection",
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:29:50.200Z",
          updatedAt: "2022-06-23T17:20:50.626Z",
          publishedAt: "2022-06-23T09:29:52.977Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 5",
          cardSubTitle: "Inquiries",
          cardDescription:
            "How many products have been added to cart in this catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "10",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: "customInformationSection",
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:30:30.243Z",
          updatedAt: "2022-06-23T17:20:58.587Z",
          publishedAt: "2022-06-23T09:30:32.494Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 6",
          cardSubTitle: "Real-time product interest tracking",
          cardDescription:
            "See in real-time which product the customer has spent the most amount of time viewing",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "leftColumn",
          sectionName: "productInterestSection",
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:31:23.284Z",
          updatedAt: "2022-06-23T17:21:06.777Z",
          publishedAt: "2022-06-23T09:31:25.239Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/real_time_visitor_analytics_2.webp",
          cardImageUrlFallback: "/assets/real_time_visitor_analytics_2.jpg",
          cardImageHeight: "654",
          cardImageWidth: "1304",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "centerColumn",
          sectionName: "productInterestSection",
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:32:00.289Z",
          updatedAt: "2022-06-23T17:21:13.521Z",
          publishedAt: "2022-06-23T09:32:01.604Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/real_time_visitor_analytics_3.webp",
          cardImageUrlFallback: "/assets/real_time_visitor_analytics_3.jpg",
          cardImageHeight: "610",
          cardImageWidth: "1304",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "centerColumn",
          sectionName: "notSeenProductsSection",
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:32:41.632Z",
          updatedAt: "2022-06-23T17:21:22.458Z",
          publishedAt: "2022-06-23T09:32:43.426Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 9",
          cardSubTitle: "Track not seen products",
          cardDescription:
            "Easily see which products have been ignored by customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: "notSeenProductsSection",
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:33:23.828Z",
          updatedAt: "2022-06-23T17:20:08.583Z",
          publishedAt: "2022-06-23T09:33:25.423Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 10",
          cardSubTitle: "Track activity for each Session",
          cardDescription:
            "Track customer activity at a session level so that you can track latest customer interest and demand and pitch accordingly",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "leftColumn",
          sectionName: "viewingActivitySection",
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T09:33:59.786Z",
          updatedAt: "2022-06-23T17:20:18.528Z",
          publishedAt: "2022-06-23T09:34:01.820Z",
          locale: "en",
          internal_identifier:
            "liveAnalyticsPageData - realTimeVisitorAnalyticsSection - logo 11",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/real_time_visitor_analytics_4.webp",
          cardImageUrlFallback: "/assets/real_time_visitor_analytics_4.jpg",
          cardImageHeight: "806",
          cardImageWidth: "1304",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: null,
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "centerColumn",
          sectionName: "viewingActivitySection",
          sortOrder: 11,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    expertSection: {
      id: 48,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  industryWiseJewelleryPageData: {
    createdAt: "2022-06-22T05:59:31.529Z",
    updatedAt: "2022-07-19T17:06:00.797Z",
    publishedAt: "2022-06-22T06:40:55.943Z",
    boostConversionWhatsappSection: {
      id: 87,
      cardTitle:
        'Mobile e-commerce for <span class="text-green">Jewellery Manufacturing and Wholesale</span>',
      cardSubTitle: "E-COMMERCE FOR JEWELLERY",
      cardDescription: "Reduce transit risk by selling online",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/quicksell_for_jewellery_main_image.webp",
      cardImageUrlFallback: "/assets/quicksell_for_jewellery_main_image.png",
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "boostConversionWhatsappSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "Special features",
          url: "#special-features",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T07:04:31.002Z",
          updatedAt: "2022-06-23T11:19:34.068Z",
          publishedAt: "2022-06-22T07:04:33.412Z",
          locale: "en",
          identifier_internal:
            "industryWiseJewelleryPageData boostConversionWhatsappSection Page Link 1",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: null,
        },
        {
          text: "Key use-cases",
          url: "#key-use-cases",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T07:05:11.593Z",
          updatedAt: "2022-06-23T11:19:21.085Z",
          publishedAt: "2022-06-22T07:05:13.283Z",
          locale: "en",
          identifier_internal:
            "industryWiseJewelleryPageData boostConversionWhatsappSection Page Link 2",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: null,
        },
        {
          text: "Sample catalogues",
          url: "#sample-catalogues",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T07:05:37.737Z",
          updatedAt: "2022-06-23T11:19:12.705Z",
          publishedAt: "2022-06-22T07:05:39.795Z",
          locale: "en",
          identifier_internal:
            "industryWiseJewelleryPageData boostConversionWhatsappSection Page Link 3",
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          menuId: null,
          onClick: null,
          bold: null,
        },
      ],
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:46:28.587Z",
          updatedAt: "2022-07-19T16:46:31.097Z",
          publishedAt: "2022-07-19T16:46:31.091Z",
          locale: "en",
          identifier_internal: "Jewellery Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    associationBannerSection: {
      id: 112,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "associationBannerSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      testimonialsList: [
        {
          thumbnailUrl: "/assets/partner_logos/ibja.png",
          thumbnailHeight: "93",
          thumbnailWidth: "295",
          title: "IBJA",
          subTitle: "India Bullion & Jewellers Association",
          description:
            "\"India Bullion and Jewellers Association Ltd. is one of India's Largest associations of gold manufacturers & wholesalers.QuickSell as its commerce partner empowered IBJA's members by adding technical benefits to their jewellery businesses. Here is what the general secretary has to say about QuickSell.\"",
          customerName: '<span class="text-italic">- Mr. Surendra Mehta</span>',
          cardVideoUrl:
            "https://www.youtube.com/embed/Pt7qfcfB7ww?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-24T19:04:04.296Z",
          updatedAt: "2022-07-06T10:10:46.545Z",
          publishedAt: "2022-06-24T19:04:08.974Z",
          identifier_internal:
            "industryWiseJewelleryPageData AssociationBannerSection Testimonial 1",
          locale: "en",
          cardVideoPosterUrl:
            "/assets/ibja_association_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/ibja_association_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 1,
        },
      ],
    },
    partnerLogosSection: {
      id: 88,
      cardTitle: "1400+ jewellery leaders use QuickSell",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "partnerLogosSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T06:06:26.610Z",
          updatedAt: "2022-06-23T17:13:38.118Z",
          publishedAt: "2022-06-22T06:06:28.451Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/gp_jewellers.webp",
          cardImageUrlFallback: "/assets/partner_logos/gp_jewellers.jpg",
          cardImageHeight: "185",
          cardImageWidth: "375",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "GP Jewellers",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:07:02.961Z",
          updatedAt: "2022-06-23T17:14:34.282Z",
          publishedAt: "2022-06-22T06:07:04.921Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/hd_gold.webp",
          cardImageUrlFallback: "/assets/partner_logos/hd_gold.png",
          cardImageHeight: "820",
          cardImageWidth: "878",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "HD Gold",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:07:36.917Z",
          updatedAt: "2022-06-23T17:14:42.825Z",
          publishedAt: "2022-06-22T06:07:38.380Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/heer_gold.webp",
          cardImageUrlFallback: "/assets/partner_logos/heer_gold.jpg",
          cardImageHeight: "185",
          cardImageWidth: "262",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Heer Gold",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:08:09.557Z",
          updatedAt: "2022-06-23T17:14:55.156Z",
          publishedAt: "2022-06-22T06:08:10.938Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/ira_jewels.webp",
          cardImageUrlFallback: "/assets/partner_logos/ira_jewels.jpg",
          cardImageHeight: "185",
          cardImageWidth: "185",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "IRA Jewels",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:09:22.820Z",
          updatedAt: "2022-06-23T17:15:03.022Z",
          publishedAt: "2022-06-22T06:09:24.794Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/kuvera_jewels.webp",
          cardImageUrlFallback: "/assets/partner_logos/kuvera_jewels.jpg",
          cardImageHeight: "185",
          cardImageWidth: "240",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Kuvera Jewels",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:09:58.497Z",
          updatedAt: "2022-06-23T17:15:10.362Z",
          publishedAt: "2022-06-22T06:10:00.370Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/mangalmani_jewels.webp",
          cardImageUrlFallback: "/assets/partner_logos/mangalmani_jewels.png",
          cardImageHeight: "1536",
          cardImageWidth: "1536",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mangalmani",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:10:31.481Z",
          updatedAt: "2022-06-23T17:15:17.918Z",
          publishedAt: "2022-06-22T06:10:34.963Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/milan_jewels.webp",
          cardImageUrlFallback: "/assets/partner_logos/milan_jewels.jpg",
          cardImageHeight: "185",
          cardImageWidth: "328",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Milan Jewels",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:11:09.764Z",
          updatedAt: "2022-06-23T17:15:28.251Z",
          publishedAt: "2022-06-22T06:11:11.282Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/payal_gold.webp",
          cardImageUrlFallback: "/assets/partner_logos/payal_gold.png",
          cardImageHeight: "198",
          cardImageWidth: "830",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Payal Gold",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:11:49.686Z",
          updatedAt: "2022-06-23T17:15:39.032Z",
          publishedAt: "2022-06-22T06:11:51.739Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pd_soni.webp",
          cardImageUrlFallback: "/assets/partner_logos/pd_soni.jpeg",
          cardImageHeight: "185",
          cardImageWidth: "611",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PD Soni",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:12:21.238Z",
          updatedAt: "2022-06-23T17:13:53.879Z",
          publishedAt: "2022-06-22T06:12:22.622Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/shringar.webp",
          cardImageUrlFallback: "/assets/partner_logos/shringar.jpg",
          cardImageHeight: "185",
          cardImageWidth: "308",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Shringar",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:12:53.844Z",
          updatedAt: "2022-06-23T17:14:02.538Z",
          publishedAt: "2022-06-22T06:12:55.158Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 11",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/shubh_chains.webp",
          cardImageUrlFallback: "/assets/partner_logos/shubh_chains.jpg",
          cardImageHeight: "185",
          cardImageWidth: "150",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Shubh Chains",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 11,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:13:30.189Z",
          updatedAt: "2022-06-23T17:14:12.635Z",
          publishedAt: "2022-06-22T06:13:31.775Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 12",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/siroya.webp",
          cardImageUrlFallback: "/assets/partner_logos/siroya.jpeg",
          cardImageHeight: "1326",
          cardImageWidth: "1326",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Siroya",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 12,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:14:05.553Z",
          updatedAt: "2022-06-23T17:14:25.977Z",
          publishedAt: "2022-06-22T06:14:07.720Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - partnerLogosSection - logo 13",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/sundaram_chains.webp",
          cardImageUrlFallback: "/assets/partner_logos/sundaram_chains.png",
          cardImageHeight: "185",
          cardImageWidth: "247",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sundaram Chains",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 13,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    customerReviewSection: {
      id: 89,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      testimonialsList: [
        {
          thumbnailUrl: "/assets/partner_logos/hd_gold.png",
          thumbnailHeight: "820",
          thumbnailWidth: "878",
          title: "HD Gold",
          subTitle: "B2B Jewellery Manufacturer",
          description:
            '"With QuickSell, we easily cater to our outstation customers with cataloguing features like bulk edit product details and privacy control options to protect our designs from being copied."',
          customerName: '<span class="text-italic">- Mr. Animesh Pal</span>',
          cardVideoUrl:
            "https://www.youtube.com/embed/bxPFSX-RQ0U?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-22T10:38:25.403Z",
          updatedAt: "2022-07-06T10:10:55.255Z",
          publishedAt: "2022-06-22T10:38:27.509Z",
          identifier_internal:
            "industryWiseJewelleryPageData CustomerReviewSection Testimonial 1",
          locale: "en",
          cardVideoPosterUrl: "/assets/hd_gold_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/hd_gold_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 1,
        },
        {
          thumbnailUrl: "/assets/partner_logos/pd_soni.jpeg",
          thumbnailHeight: "820",
          thumbnailWidth: "878",
          title: "PD Soni",
          subTitle: "B2B Jewellery Manufacturer",
          description:
            '"Live Analytics feature helped me improve the conversion rate & privacy Control feature maintained the exclusivity of my products."',
          customerName: '<span class="text-italic">- Mr. Krushang Soni</span>',
          cardVideoUrl:
            "https://youtube.com/embed/pHQibTdgYSw?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-27T05:56:42.558Z",
          updatedAt: "2022-07-06T10:11:04.007Z",
          publishedAt: "2022-06-27T05:56:44.014Z",
          identifier_internal:
            "industryWiseJewelleryPageData CustomerReviewSection Testimonial 2",
          locale: "en",
          cardVideoPosterUrl: "/assets/pd_soni_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/pd_soni_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 2,
        },
        {
          thumbnailUrl: "/assets/partner_logos/ks.png",
          thumbnailHeight: "820",
          thumbnailWidth: "878",
          title: "Khushal Sanghvi",
          subTitle: "B2B Jewellery Manufacturer",
          description:
            '"QuickSell made the entire order booking process completely risk-free for us! They also helped in increasing the efficiency of our sales team by many folds."',
          customerName:
            '<span class="text-italic">- Mr. Khushal Sanghvi</span>',
          cardVideoUrl:
            "https://www.youtube.com/embed/lZibd53A4R4?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-27T06:00:42.049Z",
          updatedAt: "2022-07-06T10:11:55.416Z",
          publishedAt: "2022-06-27T06:00:43.969Z",
          identifier_internal:
            "industryWiseJewelleryPageData CustomerReviewSection Testimonial 3",
          locale: "en",
          cardVideoPosterUrl:
            "/assets/khushal_sanghvi_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/khushal_sanghvi_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 3,
        },
      ],
    },
    specialFeaturesSection: {
      id: 90,
      cardTitle: "Special features for B2B Jewellery Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "specialFeaturesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T06:16:39.159Z",
          updatedAt: "2022-06-23T17:15:48.274Z",
          publishedAt: "2022-06-22T06:16:41.943Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - specialFeaturesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Restrict access to one catalogue, multiple catalogues or your entire store",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/jewellery_privacy_module.webp",
          cardImageUrlFallback: "/assets/jewellery_privacy_module.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Privacy module",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:17:13.606Z",
          updatedAt: "2022-06-23T17:16:00.971Z",
          publishedAt: "2022-06-22T06:17:16.232Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - specialFeaturesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Restrict access to one catalogue, multiple catalogues or your entire store",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/jewellery_gram_based_selling.webp",
          cardImageUrlFallback: "/assets/jewellery_gram_based_selling.png",
          cardImageHeight: "711",
          cardImageWidth: "870",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Gram based selling",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:18:00.592Z",
          updatedAt: "2022-06-23T17:16:13.160Z",
          publishedAt: "2022-06-22T06:18:02.147Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - specialFeaturesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Restrict access to one catalogue, multiple catalogues or your entire store",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/jewellery_screenshot_protection.webp",
          cardImageUrlFallback: "/assets/jewellery_screenshot_protection.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Screenshot protection",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    keyUseCasesSection: {
      id: 91,
      cardTitle: "Key use-cases for B2B Jewellery Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyUseCasesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T06:18:54.844Z",
          updatedAt: "2022-06-23T17:59:46.922Z",
          publishedAt: "2022-06-22T06:18:56.757Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - keyUseCasesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "2-click easy checkout via WhatsApp. Let your customer book in bulk or in set.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/jewellery_outstation_customers.webp",
          cardImageUrlFallback: "/assets/jewellery_outstation_customers.png",
          cardImageHeight: "1422",
          cardImageWidth: "1728",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Easy Outstation booking for clients",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:19:38.578Z",
          updatedAt: "2022-06-23T17:59:54.792Z",
          publishedAt: "2022-06-22T06:19:40.179Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - keyUseCasesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Avoid jewellery theft while commuting for outstation jewellery showcase.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/reduce_transit_risk.webp",
          cardImageUrlFallback: "/assets/reduce_transit_risk.png",
          cardImageHeight: "1422",
          cardImageWidth: "1728",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Reduce transit risk",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:20:17.298Z",
          updatedAt: "2022-06-23T18:00:05.164Z",
          publishedAt: "2022-06-22T06:20:19.023Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - keyUseCasesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Share your catalogue to limited users & stop your competitors from copying your jewellery designs/pricing.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/jewellery_protect_design.webp",
          cardImageUrlFallback: "/assets/jewellery_protect_design.png",
          cardImageHeight: "1422",
          cardImageWidth: "1728",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Protect your jewellery designs",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:20:59.735Z",
          updatedAt: "2022-06-23T18:00:14.283Z",
          publishedAt: "2022-06-22T06:21:01.248Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - keyUseCasesSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Live analytics allows to get the right leads for quick follow-up and closure. Provide accurate products details to customers & reduce error rate.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/sales_person_enablement.webp",
          cardImageUrlFallback: "/assets/sales_person_enablement.png",
          cardImageHeight: "1422",
          cardImageWidth: "1728",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sales person enablement",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    otherFeaturesForB2Section: {
      id: 92,
      cardTitle: "Other features",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/other_features.webp",
      cardImageUrlFallback: "/assets/other_features.png",
      cardImageHeight: "1203",
      cardImageWidth: "1839",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "otherFeaturesForB2Section",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T06:21:50.659Z",
          updatedAt: "2022-06-23T18:00:22.636Z",
          publishedAt: "2022-06-22T06:21:52.155Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: "",
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get your domain",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:22:23.925Z",
          updatedAt: "2022-06-23T18:00:42.142Z",
          publishedAt: "2022-06-22T06:22:25.153Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Coupons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:22:58.684Z",
          updatedAt: "2022-06-23T18:00:52.141Z",
          publishedAt: "2022-06-22T06:22:59.921Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "QuickSell Web",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:23:27.783Z",
          updatedAt: "2022-06-23T18:01:00.628Z",
          publishedAt: "2022-06-22T06:23:33.223Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Inventory Management",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:26:58.435Z",
          updatedAt: "2022-06-23T18:01:09.493Z",
          publishedAt: "2022-06-22T06:27:05.165Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Payments",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:27:41.545Z",
          updatedAt: "2022-06-23T18:01:18.810Z",
          publishedAt: "2022-06-22T06:27:43.018Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mobile App",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:28:05.909Z",
          updatedAt: "2022-06-23T18:01:27.746Z",
          publishedAt: "2022-06-22T06:28:11.224Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Logistics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:28:36.159Z",
          updatedAt: "2022-06-23T18:01:38.529Z",
          publishedAt: "2022-06-22T06:28:43.484Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Visitor Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:29:15.310Z",
          updatedAt: "2022-06-23T17:13:30.542Z",
          publishedAt: "2022-06-22T06:29:20.272Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PDF Cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:29:47.207Z",
          updatedAt: "2022-06-23T18:00:33.344Z",
          publishedAt: "2022-06-22T06:29:49.587Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - otherFeaturesForB2Section - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get QR Code",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    builtWithQuickSellSection: {
      id: 93,
      cardTitle:
        '<span class="text-libre-bodoni text-italic">Built with </span><span class="text-avenir-next text-bold text-italic">QuickSell</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "builtWithQuickSellSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T06:33:05.743Z",
          updatedAt: "2022-06-23T17:58:47.323Z",
          publishedAt: "2022-06-22T06:33:08.000Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - builtWithQuickSellSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/jewellery/sky_gold.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/jewellery/sky_gold.png",
          cardImageHeight: "2278",
          cardImageWidth: "1073",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sky Gold",
          linkUrl: "https://skygoldllppmna.catalog.to/s/sky-gold/bracelet/jpr",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:34:19.661Z",
          updatedAt: "2022-06-23T17:58:58.544Z",
          publishedAt: "2022-06-22T06:34:21.134Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - builtWithQuickSellSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/jewellery/payal_gold.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/jewellery/payal_gold.png",
          cardImageHeight: "2278",
          cardImageWidth: "1067",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Payal Gold",
          linkUrl: "https://payalgold.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:35:03.437Z",
          updatedAt: "2022-06-23T17:59:08.346Z",
          publishedAt: "2022-06-22T06:35:05.617Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - builtWithQuickSellSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/jewellery/heer_gold.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/jewellery/heer_gold.png",
          cardImageHeight: "2278",
          cardImageWidth: "1077",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Heer Gold",
          linkUrl: "https://heergold.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:36:02.134Z",
          updatedAt: "2022-06-23T17:59:17.095Z",
          publishedAt: "2022-06-22T06:36:03.722Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - builtWithQuickSellSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/jewellery/sundaram_chains.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/jewellery/sundaram_chains.png",
          cardImageHeight: "2278",
          cardImageWidth: "1078",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sundaram Chains",
          linkUrl: "https://sundaramchains.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:36:39.511Z",
          updatedAt: "2022-06-23T17:59:26.339Z",
          publishedAt: "2022-06-22T06:36:41.531Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - builtWithQuickSellSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/jewellery/jewel_partner.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/jewellery/jewel_partner.png",
          cardImageHeight: "2278",
          cardImageWidth: "1077",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Jewel Partner",
          linkUrl: "https://jewelpartner.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T06:37:17.641Z",
          updatedAt: "2022-06-23T17:59:36.066Z",
          publishedAt: "2022-06-22T06:37:22.719Z",
          locale: "en",
          internal_identifier:
            "industryWiseJewelleryPageData - builtWithQuickSellSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/jewellery/siroya.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/jewellery/siroya.png",
          cardImageHeight: "2278",
          cardImageWidth: "1087",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Siroya",
          linkUrl: "https://siroyajewellers.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    talkToAnExpertSection: {
      id: 94,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "talkToAnExpertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:46:28.587Z",
          updatedAt: "2022-07-19T16:46:31.097Z",
          publishedAt: "2022-07-19T16:46:31.091Z",
          locale: "en",
          identifier_internal: "Jewellery Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  industryWiseGiftingPageData: {
    createdAt: "2022-06-22T05:14:08.455Z",
    updatedAt: "2022-07-19T17:00:31.272Z",
    publishedAt: "2022-06-22T05:17:16.286Z",
    boostConversionWhatsappSection: {
      id: 79,
      cardTitle:
        'Mobile e-commerce for <span class="text-green">Gifting Business</span>',
      cardSubTitle: "E-COMMERCE FOR GIFTING",
      cardDescription:
        "Create a personalized order booking process for your B2B customers",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: "https://www.youtube.com/embed/6VVFqeh4lCs",
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "boostConversionWhatsappSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "Special features",
          url: "#special-features",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T05:51:11.446Z",
          updatedAt: "2022-06-23T11:18:41.045Z",
          publishedAt: "2022-06-22T05:51:13.133Z",
          locale: "en",
          identifier_internal:
            "industryWiseGiftingPageData boostConversionWhatsappSection Page Link 1",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: null,
        },
        {
          text: "Key use-cases",
          url: "#key-use-cases",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T05:51:41.670Z",
          updatedAt: "2022-06-23T11:18:49.327Z",
          publishedAt: "2022-06-22T05:51:44.025Z",
          locale: "en",
          identifier_internal:
            "industryWiseGiftingPageData boostConversionWhatsappSection Page Link 2",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: null,
        },
        {
          text: "Sample catalogues",
          url: "#sample-catalogues",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T05:52:31.158Z",
          updatedAt: "2022-06-24T06:42:12.608Z",
          publishedAt: "2022-06-22T05:52:32.622Z",
          locale: "en",
          identifier_internal:
            "industryWiseGiftingPageData boostConversionWhatsappSection Page Link 3",
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:51:50.454Z",
          updatedAt: "2022-07-19T16:51:51.418Z",
          publishedAt: "2022-07-19T16:51:51.413Z",
          locale: "en",
          identifier_internal: "Gifting Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    associationBannerSection: {
      id: 110,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "associationBannerSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    partnerLogosSection: {
      id: 80,
      cardTitle: "800+ gifting leaders use QuickSell",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "partnerLogosSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T05:18:33.522Z",
          updatedAt: "2022-06-23T17:56:47.411Z",
          publishedAt: "2022-06-22T05:18:34.896Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/art_deco_1.webp",
          cardImageUrlFallback: "/assets/partner_logos/art_deco_1.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Art Deco 1",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:19:07.507Z",
          updatedAt: "2022-06-23T17:56:57.153Z",
          publishedAt: "2022-06-22T05:19:09.280Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/art_deco.webp",
          cardImageUrlFallback: "/assets/partner_logos/art_deco.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Art Deco",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:19:38.103Z",
          updatedAt: "2022-06-23T17:57:07.316Z",
          publishedAt: "2022-06-22T05:19:40.195Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/bharat_sublimation.webp",
          cardImageUrlFallback: "/assets/partner_logos/bharat_sublimation.jpg",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Bharat Sublimation",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:21:00.681Z",
          updatedAt: "2022-06-23T17:57:18.804Z",
          publishedAt: "2022-06-22T05:21:03.422Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/freelance.webp",
          cardImageUrlFallback: "/assets/partner_logos/freelance.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Freelance",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:21:39.663Z",
          updatedAt: "2022-06-23T17:57:28.338Z",
          publishedAt: "2022-06-22T05:21:41.276Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/parshwa_padmavati.webp",
          cardImageUrlFallback: "/assets/partner_logos/parshwa_padmavati.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Parshwa Padmavati",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:22:16.470Z",
          updatedAt: "2022-06-23T17:57:38.793Z",
          publishedAt: "2022-06-22T05:22:17.856Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/shri_sidhi_vinayak.webp",
          cardImageUrlFallback: "/assets/partner_logos/shri_sidhi_vinayak.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Shri Sidhi Vinayak",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:22:53.908Z",
          updatedAt: "2022-06-23T17:57:49.751Z",
          publishedAt: "2022-06-22T05:22:55.587Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/smile_gift_shop.webp",
          cardImageUrlFallback: "/assets/partner_logos/smile_gift_shop.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Smile Gift Shop",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:23:35.054Z",
          updatedAt: "2022-06-23T17:57:59.633Z",
          publishedAt: "2022-06-22T05:23:36.568Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - partnerLogosSection - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/vintage_crafts.webp",
          cardImageUrlFallback: "/assets/partner_logos/vintage_crafts.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Vintage Crafts",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    customerReviewSection: {
      id: 81,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    specialFeaturesSection: {
      id: 82,
      cardTitle: "Special features for B2B Gifting Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "specialFeaturesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T05:25:21.310Z",
          updatedAt: "2022-06-23T17:58:18.267Z",
          publishedAt: "2022-06-22T05:25:26.389Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - specialFeaturesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Curate & share a product catalog to meet your customer's specific requirements.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/gifting_personalized_cataloguing.webp",
          cardImageUrlFallback: "/assets/gifting_personalized_cataloguing.png",
          cardImageHeight: "1728",
          cardImageWidth: "1422",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Personalized Cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:26:05.443Z",
          updatedAt: "2022-06-23T17:58:27.452Z",
          publishedAt: "2022-06-22T05:26:07.170Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - specialFeaturesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Observe your customer's action on the shared catalogue & know their interests right away.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/gifting_live_analytics.webp",
          cardImageUrlFallback: "/assets/gifting_live_analytics.png",
          cardImageHeight: "1728",
          cardImageWidth: "1422",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Live Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:26:45.886Z",
          updatedAt: "2022-06-23T17:58:37.628Z",
          publishedAt: "2022-06-22T05:26:47.153Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - specialFeaturesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Avoid price theft from your competitors by sharing the catalog to trusted customers.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/gifting_privacy_control_option.webp",
          cardImageUrlFallback: "/assets/gifting_privacy_control_option.png",
          cardImageHeight: "1728",
          cardImageWidth: "1422",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Privacy Control Option",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    keyUseCasesSection: {
      id: 83,
      cardTitle: "Key use-cases for B2B Gifting Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "identifier_internal",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T05:27:32.583Z",
          updatedAt: "2022-06-23T17:54:46.184Z",
          publishedAt: "2022-06-22T05:27:37.999Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - keyUseCasesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Enable your sales team to connect with the customers who showed interest in your certain products.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/gifting_know_customers_interest.webp",
          cardImageUrlFallback: "/assets/gifting_know_customers_interest.png",
          cardImageHeight: "1728",
          cardImageWidth: "1422",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Know Customer's Interest",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:28:16.145Z",
          updatedAt: "2022-06-24T13:29:21.361Z",
          publishedAt: "2022-06-22T05:28:18.066Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - keyUseCasesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Share the catalog with the employees to self select the gifts. Employees at different level can have different catalogs with access control enabled.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/gifting_self_selection_by_clients_mployees.webp",
          cardImageUrlFallback:
            "/assets/gifting_self_selection_by_clients_mployees.png",
          cardImageHeight: "1728",
          cardImageWidth: "1422",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Self selection by client's employees",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:29:25.052Z",
          updatedAt: "2022-06-23T17:55:04.956Z",
          publishedAt: "2022-06-22T05:29:26.679Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - keyUseCasesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Help your confused customers by sharing a personalized catalogue as per their requirements.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/gifting_assisted_buying_experience.webp",
          cardImageUrlFallback:
            "/assets/gifting_assisted_buying_experience.png",
          cardImageHeight: "1728",
          cardImageWidth: "1422",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Provide Assisted Buying Experience",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    otherFeaturesForB2Section: {
      id: 84,
      cardTitle: "Other features",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/other_features.webp",
      cardImageUrlFallback: "/assets/other_features.png",
      cardImageHeight: "1203",
      cardImageWidth: "1839",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "otherFeaturesForB2Section",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T05:30:11.411Z",
          updatedAt: "2022-06-23T17:55:15.202Z",
          publishedAt: "2022-06-22T05:30:13.807Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get your domain",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:30:39.464Z",
          updatedAt: "2022-06-23T17:55:31.001Z",
          publishedAt: "2022-06-22T05:30:41.298Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Coupons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:31:13.049Z",
          updatedAt: "2022-06-23T17:55:39.296Z",
          publishedAt: "2022-06-22T05:31:14.750Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "QuickSell Web",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:31:44.538Z",
          updatedAt: "2022-06-23T17:55:47.675Z",
          publishedAt: "2022-06-22T05:31:46.242Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Inventory Management",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:32:16.472Z",
          updatedAt: "2022-06-23T17:55:55.875Z",
          publishedAt: "2022-06-22T05:32:19.048Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Payments",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:32:50.827Z",
          updatedAt: "2022-06-23T17:56:04.744Z",
          publishedAt: "2022-06-22T05:32:52.745Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mobile App",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:33:18.353Z",
          updatedAt: "2022-06-23T17:56:14.248Z",
          publishedAt: "2022-06-22T05:33:20.519Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Logistics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:33:47.397Z",
          updatedAt: "2022-06-23T17:56:25.040Z",
          publishedAt: "2022-06-22T05:33:49.560Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Visitor Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:34:26.823Z",
          updatedAt: "2022-06-23T17:56:33.826Z",
          publishedAt: "2022-06-22T05:34:30.470Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PDF Cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:35:03.599Z",
          updatedAt: "2022-06-23T17:55:23.305Z",
          publishedAt: "2022-06-22T05:35:08.776Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - otherFeaturesForB2Section - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get QR Code",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    builtWithQuickSellSection: {
      id: 85,
      cardTitle:
        '<span class="text-libre-bodoni text-italic">Built with </span><span class="text-avenir-next text-bold text-italic">QuickSell</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "builtWithQuickSellSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T05:35:59.348Z",
          updatedAt: "2022-06-23T17:53:47.891Z",
          publishedAt: "2022-06-22T05:36:01.230Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - builtWithQuickSellSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/gifting/bharat_sublimation.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/gifting/bharat_sublimation.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Bharat Sublimation",
          linkUrl: "https://bharatsublimation.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:36:39.157Z",
          updatedAt: "2022-06-23T17:53:58.844Z",
          publishedAt: "2022-06-22T05:36:40.789Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - builtWithQuickSellSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/gifting/rsgift4u.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/gifting/rsgift4u.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "rsgift4u",
          linkUrl: "https://rsgifts4u.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:37:35.147Z",
          updatedAt: "2022-06-23T17:54:09.667Z",
          publishedAt: "2022-06-22T05:37:37.106Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - builtWithQuickSellSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/gifting/sidhhivinayak_gifts.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/gifting/sidhhivinayak_gifts.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "SiddhiVinayak Gifts",
          linkUrl: "https://siddhivinayakgift.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:38:20.802Z",
          updatedAt: "2022-06-23T17:54:17.957Z",
          publishedAt: "2022-06-22T05:38:22.347Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - builtWithQuickSellSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/gifting/smile_gift.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/gifting/smile_gift.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Smile Gift",
          linkUrl: "https://smilegift8585.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:39:01.323Z",
          updatedAt: "2022-06-23T17:54:27.075Z",
          publishedAt: "2022-06-22T05:39:03.128Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - builtWithQuickSellSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/gifting/vintage_crafts.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/gifting/vintage_crafts.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Vintage Crafts",
          linkUrl:
            "https://vintagecrafts.catalog.to/s/vintage-crafts/assorted-products/t47",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T05:39:34.709Z",
          updatedAt: "2022-06-23T17:54:37.409Z",
          publishedAt: "2022-06-22T05:39:36.883Z",
          locale: "en",
          internal_identifier:
            "industryWiseGiftingPageData - builtWithQuickSellSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/gifting/yash_gifts.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/gifting/yash_gifts.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Yash Gifts",
          linkUrl: "https://yashgifts.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    talkToAnExpertSection: {
      id: 86,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "talkToAnExpertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:51:50.454Z",
          updatedAt: "2022-07-19T16:51:51.418Z",
          publishedAt: "2022-07-19T16:51:51.413Z",
          locale: "en",
          identifier_internal: "Gifting Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  industryWiseClothingPageData: {
    createdAt: "2022-06-22T02:45:58.610Z",
    updatedAt: "2022-07-19T17:00:03.292Z",
    publishedAt: "2022-06-22T02:50:24.136Z",
    boostConversionWhatsappSection: {
      id: 65,
      cardTitle:
        'Mobile e-commerce for <span class="text-green">Clothing Manufacturing and Wholesaler businesses</span>',
      cardSubTitle: "E-COMMERCE FOR CLOTHING",
      cardDescription:
        "Provide seamless order booking & personal assisting experience to your customers",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/quicksell_for_clothing_main_image.webp",
      cardImageUrlFallback: "/assets/quicksell_for_clothing_main_image.png",
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "boostConversionWhatsappSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "Special features",
          url: "#special-features",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T02:52:23.569Z",
          updatedAt: "2022-06-23T11:18:09.353Z",
          publishedAt: "2022-06-22T02:53:47.215Z",
          locale: "en",
          identifier_internal:
            "industryWiseClothingPageData boostConversionWhatsappSection Page Link 1",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: null,
        },
        {
          text: "Key use-cases",
          url: "#key-use-cases",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T02:54:36.783Z",
          updatedAt: "2022-06-23T11:18:21.838Z",
          publishedAt: "2022-06-22T02:54:39.005Z",
          locale: "en",
          identifier_internal:
            "industryWiseClothingPageData boostConversionWhatsappSection Page Link 2",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          menuId: null,
          onClick: null,
          bold: null,
        },
        {
          text: "Sample catalogues",
          url: "#sample-catalogues",
          openInNewTab: false,
          color: "var(--blue)",
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-22T02:55:13.357Z",
          updatedAt: "2022-06-23T11:18:29.997Z",
          publishedAt: "2022-06-22T02:55:17.365Z",
          locale: "en",
          identifier_internal:
            "industryWiseClothingPageData boostConversionWhatsappSection Page Link 3",
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          menuId: null,
          onClick: null,
          bold: null,
        },
      ],
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:50:58.442Z",
          updatedAt: "2022-07-19T16:53:48.606Z",
          publishedAt: "2022-07-19T16:53:48.600Z",
          locale: "en",
          identifier_internal: "Clothing Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    associationBannerSection: {
      id: 111,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "associationBannerSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    partnerLogosSection: {
      id: 66,
      cardTitle: "800+ clothing leaders use QuickSell",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "partnerLogosSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T02:56:25.051Z",
          updatedAt: "2022-06-23T17:51:59.718Z",
          publishedAt: "2022-06-22T02:56:27.079Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/banjaran.webp",
          cardImageUrlFallback: "/assets/partner_logos/banjaran.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Banjaran",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T02:57:15.892Z",
          updatedAt: "2022-06-23T17:52:18.153Z",
          publishedAt: "2022-06-22T02:57:17.550Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/boo_boo_club.webp",
          cardImageUrlFallback: "/assets/partner_logos/boo_boo_club.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Boo Boo Club",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T02:58:02.310Z",
          updatedAt: "2022-06-23T17:52:26.210Z",
          publishedAt: "2022-06-22T02:58:04.438Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/denmonk.webp",
          cardImageUrlFallback: "/assets/partner_logos/denmonk.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Denmonk (B2B Zone)",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T02:58:37.438Z",
          updatedAt: "2022-06-23T17:52:32.819Z",
          publishedAt: "2022-06-22T02:58:44.271Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/gini_and_jony.webp",
          cardImageUrlFallback: "/assets/partner_logos/gini_and_jony.png",
          cardImageHeight: "144",
          cardImageWidth: "296",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Gini and Jony",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T02:59:21.574Z",
          updatedAt: "2022-06-23T17:52:40.374Z",
          publishedAt: "2022-06-22T02:59:23.284Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/mens_club.webp",
          cardImageUrlFallback: "/assets/partner_logos/mens_club.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mens Club",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T02:59:54.105Z",
          updatedAt: "2022-06-23T17:52:49.876Z",
          publishedAt: "2022-06-22T02:59:56.396Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pantaloons.webp",
          cardImageUrlFallback: "/assets/partner_logos/pantaloons.png",
          cardImageHeight: "300",
          cardImageWidth: "600",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Pantaloons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:00:28.323Z",
          updatedAt: "2022-06-23T17:52:58.475Z",
          publishedAt: "2022-06-22T03:00:30.359Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/peter_england.webp",
          cardImageUrlFallback: "/assets/partner_logos/peter_england.png",
          cardImageHeight: "512",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Peter England",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:01:01.558Z",
          updatedAt: "2022-06-23T17:53:06.019Z",
          publishedAt: "2022-06-22T03:01:03.724Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pyjama_party.webp",
          cardImageUrlFallback: "/assets/partner_logos/pyjama_party.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Pyjama Party",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:01:34.664Z",
          updatedAt: "2022-06-23T17:53:13.669Z",
          publishedAt: "2022-06-22T03:01:37.854Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/sabyasachi.webp",
          cardImageUrlFallback: "/assets/partner_logos/sabyasachi.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sabyasachi",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:02:13.449Z",
          updatedAt: "2022-06-23T17:52:09.872Z",
          publishedAt: "2022-06-22T03:02:14.832Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - partnerLogosSection - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/siyarams.webp",
          cardImageUrlFallback: "/assets/partner_logos/siyarams.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Siyarams",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    customerReviewSection: {
      id: 67,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      testimonialsList: [
        {
          thumbnailUrl: "/assets/partner_logos/aggarwal_saree_centre.jpeg",
          thumbnailHeight: "723",
          thumbnailWidth: "723",
          title: "Aggarwal Saree Center",
          subTitle: "Clothing Retailer",
          description:
            '"QuickSell increased my overall sales by 3x! I was able to create personalised catalogue as per customer\'s requirements and cater to wholesalers and retail customers by sharing different catalogues with different pricing."',
          customerName: '<span class="text-italic">- Mr. Ajay Aggarwal</span>',
          cardVideoUrl:
            "https://www.youtube.com/embed/wCYHIr9ZbsU?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-22T10:30:41.806Z",
          updatedAt: "2022-07-06T10:10:32.641Z",
          publishedAt: "2022-06-22T10:31:48.314Z",
          identifier_internal:
            "industryWiseClothingPageData CustomerReviewSection Testimonial 1",
          locale: "en",
          cardVideoPosterUrl:
            "/assets/aggarwal_saree_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/aggarwal_saree_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 1,
        },
      ],
    },
    specialFeaturesSection: {
      id: 68,
      cardTitle: "Special features for B2B Clothing Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "specialFeaturesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T03:03:24.985Z",
          updatedAt: "2022-06-23T17:53:22.709Z",
          publishedAt: "2022-06-22T03:03:26.261Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Prevent your clothing designs and prices from getting copied with privacy control settings",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_private_whatsapp_commerce.webp",
          cardImageUrlFallback:
            "/assets/clothing_private_whatsapp_commerce.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private WhatsApp Commerce",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:04:09.422Z",
          updatedAt: "2022-06-23T17:53:30.558Z",
          publishedAt: "2022-06-22T03:04:11.276Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Allow your retail customers to place orders in bulk and sets of colors/prices",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_bulk_andset_wise_ordering.webp",
          cardImageUrlFallback:
            "/assets/clothing_bulk_andset_wise_ordering.png",
          cardImageHeight: "711",
          cardImageWidth: "870",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Bulk and set-wise ordering",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:04:49.543Z",
          updatedAt: "2022-06-23T17:53:39.253Z",
          publishedAt: "2022-06-22T03:04:54.816Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Show products as per the design and price range specified by the customer",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_personalized_cataloguing.webp",
          cardImageUrlFallback: "/assets/clothing_personalized_cataloguing.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Personalized Cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:14:36.840Z",
          updatedAt: "2022-06-30T20:30:29.484Z",
          publishedAt: "2022-06-30T19:16:46.747Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Show your customer different colour & size options of the selected product",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_size_and_color_variants.webp",
          cardImageUrlFallback: "/assets/clothing_size_and_color_variants.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Size and color based variants",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:14:38.142Z",
          updatedAt: "2022-06-30T20:30:42.190Z",
          publishedAt: "2022-06-30T19:16:40.005Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "QuickSell let's your end customer filter clothes on available size & color",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_filtering_options_variants.png",
          cardImageUrlFallback: null,
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Filtering options based on variants like size and color",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:21:33.053Z",
          updatedAt: "2022-06-30T20:30:47.739Z",
          publishedAt: "2022-06-30T19:21:34.551Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo 6",
          cardSubTitle: null,
          cardDescription:
            "Set your customer's minimum order quantity & minimum order amount",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_moq_moa.webp",
          cardImageUrlFallback: "/assets/clothing_moq_moa.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "MOQ and MOA",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:28:42.496Z",
          updatedAt: "2022-06-30T20:30:59.160Z",
          publishedAt: "2022-06-30T19:28:43.969Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 7",
          cardSubTitle: null,
          cardDescription: "Showcase your product via engaging videos",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_product_videos.webp",
          cardImageUrlFallback: "/assets/clothing_product_videos.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: "",
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Product videos",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:31:53.208Z",
          updatedAt: "2022-06-30T20:31:05.555Z",
          publishedAt: "2022-06-30T19:32:57.637Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 8",
          cardSubTitle: null,
          cardDescription:
            "Within 2 minutes curate separate catalogues for your customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_b2b_b2c_store.webp",
          cardImageUrlFallback: "/assets/clothing_b2b_b2c_store.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "B2B and B2C store",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:34:57.545Z",
          updatedAt: "2022-06-30T20:31:11.654Z",
          publishedAt: "2022-06-30T19:34:59.894Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 9",
          cardSubTitle: null,
          cardDescription:
            "An alternative method for COD for B2B & B2C customers. Verify your orders by taking a small amount as an advance",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_partial_cod.webp",
          cardImageUrlFallback: "/assets/clothing_partial_cod.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Partial COD",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:38:03.194Z",
          updatedAt: "2022-06-30T20:31:18.189Z",
          publishedAt: "2022-06-30T19:38:06.391Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 10",
          cardSubTitle: null,
          cardDescription:
            "Not just links or single images, you can now also share PDFs with your customers for offline access",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/clothing_automatic_pdf_catalogue_creation.webp",
          cardImageUrlFallback:
            "/assets/clothing_automatic_pdf_catalogue_creation.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Automatic PDF catalogue creation",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "",
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:40:16.527Z",
          updatedAt: "2022-06-30T20:31:46.118Z",
          publishedAt: "2022-06-30T19:40:19.986Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 11",
          cardSubTitle: null,
          cardDescription:
            "With Sort option you can make sure that your customers view your latest products all the time or different products each time they open the catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_control_product_visiblity.webp",
          cardImageUrlFallback:
            "/assets/clothing_control_product_visiblity.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Control Product Visiblity",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 11,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:42:14.795Z",
          updatedAt: "2022-06-30T20:31:51.124Z",
          publishedAt: "2022-06-30T19:42:16.114Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 12",
          cardSubTitle: null,
          cardDescription: "Allow customers to order out-of-stock products",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_advance_ordering_system.webp",
          cardImageUrlFallback: "/assets/clothing_advance_ordering_system.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Advance ordering system",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 12,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:44:56.577Z",
          updatedAt: "2022-06-30T20:31:57.020Z",
          publishedAt: "2022-06-30T19:45:17.080Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 13",
          cardSubTitle: null,
          cardDescription:
            "Create filters in the product library for quick TAT",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_custom_fields.webp",
          cardImageUrlFallback: "/assets/clothing_custom_fields.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom fields",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 13,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:47:29.843Z",
          updatedAt: "2022-06-30T20:32:01.915Z",
          publishedAt: "2022-06-30T19:47:31.661Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - specialFeaturesSection - logo on 14",
          cardSubTitle: null,
          cardDescription:
            "Create Subcategories to further break down your product range",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_subcategories.webp",
          cardImageUrlFallback: "/assets/clothing_subcategories.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Subcategories",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 14,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    keyUseCasesSection: {
      id: 69,
      cardTitle: "Key use-cases for B2B Clothing Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyUseCasesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T03:06:03.327Z",
          updatedAt: "2022-06-30T20:34:15.667Z",
          publishedAt: "2022-06-22T03:06:05.281Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - keyUseCasesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Curate two different catalogues of the same products, but with different pricing for your B2B and B2C customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_assisted_buying_experience.webp",
          cardImageUrlFallback:
            "/assets/clothing_assisted_buying_experience.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Provide assisted buying experience",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:06:46.373Z",
          updatedAt: "2022-06-30T20:34:21.941Z",
          publishedAt: "2022-06-22T03:06:47.625Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - keyUseCasesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Track live inventory and prices for online and offline order booking. An additional feature to track the accountability and incentivise sales representatives as per their performance",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_sales_team_enablement.webp",
          cardImageUrlFallback: "/assets/clothing_sales_team_enablement.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sales team enablement",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:07:22.962Z",
          updatedAt: "2022-06-30T20:34:26.959Z",
          publishedAt: "2022-06-22T03:07:28.015Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - keyUseCasesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Slow release or launch a new catalogue daily to create an illusion of newness of your products",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_maintain_freshness_of_products.webp",
          cardImageUrlFallback:
            "/assets/clothing_maintain_freshness_of_products.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Maintain freshness of your product",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T18:52:18.633Z",
          updatedAt: "2022-06-30T20:34:32.918Z",
          publishedAt: "2022-06-30T18:52:20.465Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - keyUseCasesSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Send resellers your latest products on WhatsApp with a single click",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_reseller_channel.webp",
          cardImageUrlFallback: "/assets/clothing_reseller_channel.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Reseller channel",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T18:52:22.620Z",
          updatedAt: "2022-06-30T20:34:38.035Z",
          publishedAt: "2022-06-30T18:52:23.768Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - keyUseCasesSection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "Catering to leads coming from IndiaMart, JustDial, Facebook, etc",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/clothing_cater_to_leads.webp",
          cardImageUrlFallback: "/assets/clothing_cater_to_leads.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Convert your leads",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    otherFeaturesForB2Section: {
      id: 70,
      cardTitle: "Other features",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/other_features.webp",
      cardImageUrlFallback: "/assets/other_features.png",
      cardImageHeight: "1203",
      cardImageWidth: "1839",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "otherFeaturesForB2Section",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T03:08:13.957Z",
          updatedAt: "2022-06-23T17:36:35.593Z",
          publishedAt: "2022-06-22T03:08:20.169Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get your domain",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:08:51.395Z",
          updatedAt: "2022-06-30T18:59:00.797Z",
          publishedAt: "2022-06-22T03:08:52.786Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Coupons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:09:24.958Z",
          updatedAt: "2022-06-30T19:03:18.099Z",
          publishedAt: "2022-06-22T03:09:26.371Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Magazine View",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:09:54.710Z",
          updatedAt: "2022-06-30T19:03:30.683Z",
          publishedAt: "2022-06-22T03:09:56.603Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Inventory Managament",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:10:24.883Z",
          updatedAt: "2022-06-30T19:03:42.397Z",
          publishedAt: "2022-06-22T03:10:26.084Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Multi-payment option",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:11:00.935Z",
          updatedAt: "2022-06-23T17:37:31.372Z",
          publishedAt: "2022-06-22T03:11:02.554Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mobile App",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:11:28.442Z",
          updatedAt: "2022-06-23T17:38:03.461Z",
          publishedAt: "2022-06-22T03:11:29.911Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Logistics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:12:04.652Z",
          updatedAt: "2022-06-23T17:38:10.959Z",
          publishedAt: "2022-06-22T03:12:05.864Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Visitor Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:12:41.639Z",
          updatedAt: "2022-06-30T19:04:22.414Z",
          publishedAt: "2022-06-22T03:12:43.818Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Auto-PDF Generation",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:13:27.178Z",
          updatedAt: "2022-06-23T17:36:47.680Z",
          publishedAt: "2022-06-22T03:13:29.150Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - otherFeaturesForB2Section - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get QR Code",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    builtWithQuickSellSection: {
      id: 71,
      cardTitle:
        '<span class="text-libre-bodoni text-italic">Built with </span><span class="text-avenir-next text-bold text-italic">QuickSell</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "builtWithQuickSellSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T03:14:34.440Z",
          updatedAt: "2022-06-23T17:35:13.029Z",
          publishedAt: "2022-06-22T03:14:35.963Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/clothing/vishal_kapur_design.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/clothing/vishal_kapur_design.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Vishal Kapur",
          linkUrl: "https://vishalkapurdesign.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:15:16.483Z",
          updatedAt: "2022-06-23T17:35:20.175Z",
          publishedAt: "2022-06-22T03:15:18.848Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/clothing/pooja_creations.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/clothing/pooja_creations.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Pooja Creations",
          linkUrl: "https://poojacreations.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:15:58.862Z",
          updatedAt: "2022-06-23T17:35:28.415Z",
          publishedAt: "2022-06-22T03:16:04.624Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/clothing/pantaloons.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/clothing/pantaloons.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Pantaloons",
          linkUrl: "https://pantaloonslakecitymall.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:16:56.734Z",
          updatedAt: "2022-06-23T17:35:36.295Z",
          publishedAt: "2022-06-22T03:22:58.229Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/clothing/fabtex_india.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/clothing/fabtex_india.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Fabtex India",
          linkUrl: "https://fabtexindia.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:23:33.869Z",
          updatedAt: "2022-06-23T17:35:44.068Z",
          publishedAt: "2022-06-22T03:23:35.658Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/clothing/denmonk.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/clothing/denmonk.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Denmonk",
          linkUrl: "https://www.b2bzone.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:24:13.527Z",
          updatedAt: "2022-06-23T17:35:52.948Z",
          publishedAt: "2022-06-22T03:24:14.938Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "assets/built_with_quicksell/clothing/chirala_weavers.png",
          cardImageUrlFallback: null,
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Chirala Weavers",
          linkUrl: "https://chiralaweavers.com",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T03:24:57.051Z",
          updatedAt: "2022-06-23T17:36:01.521Z",
          publishedAt: "2022-06-22T03:24:58.369Z",
          locale: "en",
          internal_identifier:
            "industryWiseClothingPageData - builtWithQuickSellSection - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/clothing/aggarwal_saree_centre.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/clothing/aggarwal_saree_centre.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Aggarwal Saree",
          linkUrl: "https://wonderbazar.com",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    talkToAnExpertSection: {
      id: 72,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "talkToAnExpertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:50:58.442Z",
          updatedAt: "2022-07-19T16:53:48.606Z",
          publishedAt: "2022-07-19T16:53:48.600Z",
          locale: "en",
          identifier_internal: "Clothing Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  industryWiseToysPageData: {
    createdAt: "2022-06-22T07:31:58.845Z",
    updatedAt: "2022-07-19T17:06:27.843Z",
    publishedAt: "2022-06-22T07:38:28.952Z",
    boostConversionWhatsappSection: {
      id: 95,
      cardTitle:
        'Mobile e-commerce for <span class="text-green">Toys Business</span>',
      cardSubTitle: "E-COMMERCE FOR TOYS",
      cardDescription: "Showcase unlimited toys with video based cataloguing",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/quicksell_for_toys_main_image.webp",
      cardImageUrlFallback: "/assets/quicksell_for_toys_main_image.png",
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "boostConversionWhatsappSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:50:08.422Z",
          updatedAt: "2022-07-19T16:50:09.742Z",
          publishedAt: "2022-07-19T16:50:09.736Z",
          locale: "en",
          identifier_internal: "Toys Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    associationBannerSection: {
      id: 113,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "associationBannerSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    partnerLogosSection: {
      id: 96,
      cardTitle: "800+ toys leaders use QuickSell",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "partnerLogosSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T07:39:34.985Z",
          updatedAt: "2022-06-23T17:18:58.188Z",
          publishedAt: "2022-06-22T07:39:40.345Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - partnerLogosSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/bps.webp",
          cardImageUrlFallback: "/assets/partner_logos/bps.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "BPS",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:40:13.826Z",
          updatedAt: "2022-06-23T17:19:05.288Z",
          publishedAt: "2022-06-22T07:40:16.555Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - partnerLogosSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/hamleys.webp",
          cardImageUrlFallback: "/assets/partner_logos/hamleys.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Hamleys",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:40:49.569Z",
          updatedAt: "2022-06-23T17:19:13.418Z",
          publishedAt: "2022-06-22T07:40:50.882Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - partnerLogosSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/kangaroos.webp",
          cardImageUrlFallback: "/assets/partner_logos/kangaroos.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Kangaroos",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:41:33.574Z",
          updatedAt: "2022-06-23T17:19:20.318Z",
          publishedAt: "2022-06-22T07:41:36.227Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - partnerLogosSection - logo 4",
          cardSubTitle: null,
          cardDescription: "",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/puva.webp",
          cardImageUrlFallback: "/assets/partner_logos/puva.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Puva",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:42:13.902Z",
          updatedAt: "2022-06-23T17:19:28.805Z",
          publishedAt: "2022-06-22T07:42:15.499Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - partnerLogosSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/toyport.webp",
          cardImageUrlFallback: "/assets/partner_logos/toyport.png",
          cardImageHeight: "250",
          cardImageWidth: "500",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "ToyPort",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    customerReviewSection: {
      id: 97,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    specialFeaturesSection: {
      id: 98,
      cardTitle: "Special features for B2B Toys Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "specialFeaturesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T07:43:11.148Z",
          updatedAt: "2022-06-30T20:10:54.497Z",
          publishedAt: "2022-06-22T07:43:12.953Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Prevent your new toy collection and prices from competitors with privacy control settings",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_private_whatsapp_commerce.webp",
          cardImageUrlFallback: "/assets/toys_private_whatsapp_commerce.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private WhatsApp Commerce",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:46:19.746Z",
          updatedAt: "2022-06-30T20:13:26.813Z",
          publishedAt: "2022-06-22T07:46:21.295Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Allow your retail customers to place orders in bulk and sets",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_bulk_set_wise_ordering.webp",
          cardImageUrlFallback: "/assets/toys_bulk_set_wise_ordering.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Bulk and set-wise ordering",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:47:11.630Z",
          updatedAt: "2022-06-30T20:12:06.096Z",
          publishedAt: "2022-06-22T07:47:13.677Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Set your customer's minimum order quality & minimum order amount",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_moq_moa.webp",
          cardImageUrlFallback: "/assets/toys_moq_moa.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "MOQ and MOA",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T20:14:30.401Z",
          updatedAt: "2022-06-30T20:17:33.285Z",
          publishedAt: "2022-06-30T20:17:33.271Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Share filtered catalogs based on customer requirement",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_personalized_cataloguing.webp",
          cardImageUrlFallback: "/assets/toys_personalized_cataloguing.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Personal Commerce",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T20:14:55.678Z",
          updatedAt: "2022-06-30T20:18:02.485Z",
          publishedAt: "2022-06-30T20:18:02.474Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "Know which customer is eyeing your product for how long",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_live_analytics.webp",
          cardImageUrlFallback: "/assets/toys_live_analytics.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Live Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T20:15:01.339Z",
          updatedAt: "2022-06-30T20:18:33.247Z",
          publishedAt: "2022-06-30T20:18:33.238Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 6",
          cardSubTitle: null,
          cardDescription: "Your go to source to track offline & online sales",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_inventory_management.webp",
          cardImageUrlFallback: "/assets/toys_inventory_management.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Inventory Managemnt",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T20:21:01.941Z",
          updatedAt: "2022-06-30T20:22:00.744Z",
          publishedAt: "2022-06-30T20:22:00.736Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 7",
          cardSubTitle: null,
          cardDescription: "Showcase your product via engaging videos",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_product_videos.webp",
          cardImageUrlFallback: "/assets/toys_product_duplication.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Product videos",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T20:23:21.446Z",
          updatedAt: "2022-06-30T20:25:40.900Z",
          publishedAt: "2022-06-30T20:23:23.026Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 8",
          cardSubTitle: null,
          cardDescription:
            "An alternative method for COD for B2B & B2C customers.Verify your orders by taking a small amount as an advance",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_partial_cod.webp",
          cardImageUrlFallback: "/assets/toys_partial_cod.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Partial COD",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T20:23:24.141Z",
          updatedAt: "2022-06-30T20:26:02.642Z",
          publishedAt: "2022-06-30T20:23:25.203Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - specialFeaturesSection - logo 9",
          cardSubTitle: null,
          cardDescription:
            "Create filters in the product library for quick TAT",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_custom_fields.webp",
          cardImageUrlFallback: "/assets/toys_custom_fields.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom fields",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    keyUseCasesSection: {
      id: 99,
      cardTitle: "Key use-cases for B2B Toys Businesses",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyUseCasesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T07:49:30.407Z",
          updatedAt: "2022-06-30T20:03:22.848Z",
          publishedAt: "2022-06-22T07:49:31.800Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - keyUseCasesSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Avoid carrying of physical samples and limited samples and showcase unlimited items",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_product_showcasing.webp",
          cardImageUrlFallback: "/assets/toys_product_showcasing.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Product showcasing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:50:15.637Z",
          updatedAt: "2022-06-30T20:03:28.566Z",
          publishedAt: "2022-06-22T07:50:16.872Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - keyUseCasesSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Easily replicate product information and run your B2B and B2C together seamlessly along with different pricing",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_product_duplication.webp",
          cardImageUrlFallback: "/assets/toys_product_duplication.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Catalogue / Product duplication",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:52:00.249Z",
          updatedAt: "2022-07-01T07:33:01.252Z",
          publishedAt: "2022-06-22T07:52:01.680Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - keyUseCasesSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Help sales team to identify the hot leads & provides a single source to track live product inventory",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_sales_team_enablement.webp",
          cardImageUrlFallback: "/assets/toys_sales_team_enablement.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sales team enablement",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:52:41.927Z",
          updatedAt: "2022-07-01T07:34:51.295Z",
          publishedAt: "2022-06-22T07:52:47.414Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - keyUseCasesSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Simplify taking orders by automating the order management process",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_order_management.webp",
          cardImageUrlFallback: "/assets/toys_order_management.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "2 Click Order Checkout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-30T19:57:40.281Z",
          updatedAt: "2022-06-30T20:03:08.983Z",
          publishedAt: "2022-06-30T19:57:49.660Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - keyUseCasesSection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "Catering to leads coming from IndiaMart, JustDial, Facebook, etc",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/toys_cater_to_leads.webp",
          cardImageUrlFallback: "/assets/toys_cater_to_leads.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Convert your leads",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    otherFeaturesForB2Section: {
      id: 100,
      cardTitle: "Other features",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/other_features.webp",
      cardImageUrlFallback: "/assets/other_features.png",
      cardImageHeight: "1203",
      cardImageWidth: "1203",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "otherFeaturesForB2Section",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T07:53:41.184Z",
          updatedAt: "2022-06-23T17:17:42.509Z",
          publishedAt: "2022-06-22T07:53:43.043Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get your domain",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:54:10.417Z",
          updatedAt: "2022-06-23T17:18:00.905Z",
          publishedAt: "2022-06-22T07:54:12.618Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Coupons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:54:41.924Z",
          updatedAt: "2022-06-23T17:18:09.204Z",
          publishedAt: "2022-06-22T07:54:43.319Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "QuickSell Web",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:55:08.464Z",
          updatedAt: "2022-06-23T17:18:17.078Z",
          publishedAt: "2022-06-22T07:55:09.884Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Inventory Management",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:55:39.507Z",
          updatedAt: "2022-06-23T17:18:23.195Z",
          publishedAt: "2022-06-22T07:55:41.908Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Payments",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:56:13.945Z",
          updatedAt: "2022-06-23T17:18:30.934Z",
          publishedAt: "2022-06-22T07:56:16.030Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mobile App",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:57:03.763Z",
          updatedAt: "2022-06-23T17:18:38.124Z",
          publishedAt: "2022-06-22T07:57:05.975Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 7",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Logistics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:57:34.916Z",
          updatedAt: "2022-06-23T17:18:45.355Z",
          publishedAt: "2022-06-22T07:57:36.579Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Visitor Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:58:35.770Z",
          updatedAt: "2022-06-23T17:18:51.762Z",
          publishedAt: "2022-06-22T07:58:37.270Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PDF Cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T07:59:12.064Z",
          updatedAt: "2022-06-23T17:17:52.196Z",
          publishedAt: "2022-06-22T07:59:13.647Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - otherFeaturesForB2Section - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Get QR Code",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    builtWithQuickSellSection: {
      id: 101,
      cardTitle:
        '<span class="text-libre-bodoni text-italic">Built with </span><span class="text-avenir-next text-bold text-italic">QuickSell</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "builtWithQuickSellSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-22T08:01:34.428Z",
          updatedAt: "2022-06-23T17:16:22.822Z",
          publishedAt: "2022-06-22T08:08:04.804Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - builtWithQuickSellSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/toys/hamleys.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/toys/hamleys.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Hamleys",
          linkUrl: "https://hamleysvasantkunj.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T08:08:45.320Z",
          updatedAt: "2022-06-23T17:16:30.540Z",
          publishedAt: "2022-06-22T08:09:09.090Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - builtWithQuickSellSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/toys/kangaroo_club.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/toys/kangaroo_club.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Kangaroo Club",
          linkUrl: "https://kangarooindia.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T08:10:09.999Z",
          updatedAt: "2022-06-23T17:16:38.286Z",
          publishedAt: "2022-06-22T08:10:11.242Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - builtWithQuickSellSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/toys/puva.webp",
          cardImageUrlFallback: "/assets/built_with_quicksell/toys/puva.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Puva",
          linkUrl: "https://puva.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T08:10:50.006Z",
          updatedAt: "2022-06-23T17:16:45.924Z",
          publishedAt: "2022-06-22T08:10:51.247Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - builtWithQuickSellSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/toys/toy_port.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/toys/toy_port.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Toy Port",
          linkUrl: "https://toyport.in",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-22T08:11:28.477Z",
          updatedAt: "2022-06-23T17:16:54.857Z",
          publishedAt: "2022-06-22T08:11:29.588Z",
          locale: "en",
          internal_identifier:
            "industryWiseToysPageData - builtWithQuickSellSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/toys/zee_toys.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/toys/zee_toys.png",
          cardImageHeight: "2284",
          cardImageWidth: "1080",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Zee Toys",
          linkUrl: "https://zeetoys.catalog.to",
          openInNewTab: true,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    talkToAnExpertSection: {
      id: 102,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "talkToAnExpertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:50:08.422Z",
          updatedAt: "2022-07-19T16:50:09.742Z",
          publishedAt: "2022-07-19T16:50:09.736Z",
          locale: "en",
          identifier_internal: "Toys Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  b2cPageData: {
    createdAt: "2022-06-21T07:48:49.872Z",
    updatedAt: "2022-07-19T16:59:11.370Z",
    publishedAt: "2022-06-21T07:53:33.646Z",
    locale: "en",
    bookOrderSection: {
      id: 22,
      cardTitle: "QUICKSELL FOR B2C",
      cardSubTitle:
        "Take your virtual store live in minutes all from your mobile",
      cardDescription:
        "Provide a simplified and personal shopping experience for your customers",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/b2c_main_image.webp",
      cardImageUrlFallback: "/assets/b2c_main_image.png",
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "bookOrderSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:53:24.352Z",
          updatedAt: "2022-07-19T16:53:25.348Z",
          publishedAt: "2022-07-19T16:53:25.343Z",
          locale: "en",
          identifier_internal: "B2c Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    customerReviewSection: {
      id: 23,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      testimonialsList: [
        {
          thumbnailUrl: "/assets/partner_logos/aggarwal_saree_centre.jpeg",
          thumbnailHeight: "723",
          thumbnailWidth: "723",
          title: "Aggarwal Saree Centre",
          subTitle: "Clothing Retailer",
          description:
            '"I have increased my sales by 3x & catered B2B/B2C users with QuickSell\'s unique features like live analytics, personalised catalogues & more."',
          customerName: '<span class="text-italic">- Mr. Ajay Aggarwal</span>',
          cardVideoUrl:
            "https://youtube.com/embed/wCYHIr9ZbsU?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-21T07:55:56.118Z",
          updatedAt: "2022-07-06T10:09:55.342Z",
          publishedAt: "2022-06-21T07:56:01.302Z",
          identifier_internal: "B2C Page CustomerReviewSection Testimonial 1",
          locale: "en",
          cardVideoPosterUrl:
            "/assets/aggarwal_saree_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/aggarwal_saree_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 1,
        },
      ],
    },
    expertSection: {
      id: 24,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:53:24.352Z",
          updatedAt: "2022-07-19T16:53:25.348Z",
          publishedAt: "2022-07-19T16:53:25.343Z",
          locale: "en",
          identifier_internal: "B2c Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    personalShoppingSection: {
      id: 25,
      cardTitle: "Personal shopping experience",
      cardSubTitle: "",
      cardDescription: "Give a personal shopping experience to customers",
      sectionTitle: "Commerce made personal again",
      sectionSubTitle:
        "Allow customers to experience an in-store shopping experience in the online world",
      cardImageUrl: "/assets/personal_shopping_experience.webp",
      cardImageUrlFallback: "/assets/personal_shopping_experience.png",
      cardImageHeight: "1298",
      cardImageWidth: "1338",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "personalShoppingSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T07:58:00.815Z",
          updatedAt: "2022-06-23T17:00:54.185Z",
          publishedAt: "2022-06-21T07:58:02.385Z",
          locale: "en",
          internal_identifier: "b2cPage - personalShoppingSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Share relevant product information with customers. No more wasting hours trying to find the right products to share",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T07:58:35.764Z",
          updatedAt: "2022-06-23T17:01:03.689Z",
          publishedAt: "2022-06-22T17:05:33.626Z",
          locale: "en",
          internal_identifier: "b2cPage - personalShoppingSection - logo 2",
          cardSubTitle: "",
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Focussed buying experience rather than a diluted buying experience",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    whatsAppSection: {
      id: 26,
      cardTitle: "WhatsApp Commerce",
      cardSubTitle: null,
      cardDescription:
        "Boost your conversion rate by providing customers on WhatsApp with a personal commerce experience",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/whatsapp_commerce.webp",
      cardImageUrlFallback: "/assets/whatsapp_commerce.png",
      cardImageHeight: "1428",
      cardImageWidth: "1650",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "whatsAppSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T08:00:09.698Z",
          updatedAt: "2022-06-23T17:01:48.345Z",
          publishedAt: "2022-06-21T08:00:14.286Z",
          locale: "en",
          internal_identifier: "b2cPage - whatsAppSection - logo 1",
          cardSubTitle: null,
          cardDescription: "",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Deep Integration with Whatsapp- Allowing your customers to directly connect with you over whatsapp and be more accessible to them",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T08:00:45.894Z",
          updatedAt: "2022-06-23T17:01:55.905Z",
          publishedAt: "2022-06-21T08:00:49.224Z",
          locale: "en",
          internal_identifier: "b2cPage - whatsAppSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Boost your conversion rate with our unique WhatsApp Brochure feature",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T08:01:14.351Z",
          updatedAt: "2022-06-23T17:02:03.403Z",
          publishedAt: "2022-06-21T08:01:17.753Z",
          locale: "en",
          internal_identifier: "b2cPage - whatsAppSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Allow customers to receive order receipts via WhatsApp",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T08:01:46.227Z",
          updatedAt: "2022-06-23T17:02:11.762Z",
          publishedAt: "2022-06-21T08:01:47.569Z",
          locale: "en",
          internal_identifier: "b2cPage - whatsAppSection - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Create catalogue from your suppliers WhatsApp chat for quick selling",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    storeSetupSection: {
      id: 27,
      cardTitle: "Blazing fast store setup",
      cardSubTitle: null,
      cardDescription:
        "QuickSell is designed to enable you to setup your store and go live in a few minutes as opposted to spending months in trying to find the right developers and designers to setup your store",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/blazing_fast_store_setup.webp",
      cardImageUrlFallback: "/assets/blazing_fast_store_setup.png",
      cardImageHeight: "1509",
      cardImageWidth: "452",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "storeSetupSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T08:06:29.103Z",
          updatedAt: "2022-06-23T17:01:11.960Z",
          publishedAt: "2022-06-21T08:06:31.309Z",
          locale: "en",
          internal_identifier: "b2cPage - storeSetupSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Bulk upload using your phone, Excel sheet or from your computer and setup your catalogue in seconds",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/blazing_fast_store_setup_feature_1.webp",
          cardImageUrlFallback:
            "/assets/blazing_fast_store_setup_feature_1.png",
          cardImageHeight: "2133",
          cardImageWidth: "2592",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Easy bulk cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T08:07:03.227Z",
          updatedAt: "2022-06-23T17:01:20.607Z",
          publishedAt: "2022-06-21T08:07:06.515Z",
          locale: "en",
          internal_identifier: "b2cPage - storeSetupSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Using our in-built theme and layout builder, you can create your own theme to provide an experience that reflects your brand",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/blazing_fast_store_setup_feature_2.webp",
          cardImageUrlFallback:
            "/assets/blazing_fast_store_setup_feature_2.png",
          cardImageHeight: "2133",
          cardImageWidth: "2592",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Layouts and Themes",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T08:07:41.242Z",
          updatedAt: "2022-06-23T17:01:27.832Z",
          publishedAt: "2022-06-21T08:07:42.896Z",
          locale: "en",
          internal_identifier: "b2cPage - storeSetupSection - logo 3",
          cardSubTitle: null,
          cardDescription: "Accept payments in multiple currencies",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/blazing_fast_store_setup_feature_3.webp",
          cardImageUrlFallback:
            "/assets/blazing_fast_store_setup_feature_3.png",
          cardImageHeight: "2133",
          cardImageWidth: "2592",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Payments",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T08:08:20.203Z",
          updatedAt: "2022-06-23T17:01:36.656Z",
          publishedAt: "2022-06-21T08:08:21.570Z",
          locale: "en",
          internal_identifier: "b2cPage - storeSetupSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Collect the correct shipping cost amount during checkout with our robust in-built shipping cost calculator",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/blazing_fast_store_setup_feature_4.webp",
          cardImageUrlFallback:
            "/assets/blazing_fast_store_setup_feature_4.png",
          cardImageHeight: "2133",
          cardImageWidth: "2592",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Shipping",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
  },
  b2bPageData: {
    createdAt: "2022-06-21T06:25:21.696Z",
    updatedAt: "2022-07-19T16:59:20.432Z",
    publishedAt: "2022-06-23T10:34:30.809Z",
    locale: "en",
    flexibleOrderSection: {
      id: 12,
      cardTitle:
        '<span>Flexible order booking process for your <span class="text-green">B2B Business</span></span>',
      cardSubTitle: "QUICKSELL FOR B2B",
      cardDescription:
        "Multiply your sales team's efficiency and protect your designs & prices from getting copied by the competitors.",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/b2b_main_image.webp",
      cardImageUrlFallback: "/assets/b2b_main_image.png",
      cardImageUrlFallback: null,
      cardImageHeight: "1651",
      cardImageWidth: "2700",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "flexibleOrderSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:52:59.464Z",
          updatedAt: "2022-07-19T16:53:00.452Z",
          publishedAt: "2022-07-19T16:53:00.437Z",
          locale: "en",
          identifier_internal: "B2b Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    customerReviewSection: {
      id: 13,
      cardTitle: "Designed from the ground up keeping B2B businesses in mind",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      testimonialsList: [
        {
          thumbnailUrl: "/assets/partner_logos/shri_handicrafts.jpeg",
          thumbnailHeight: "185",
          thumbnailWidth: "419",
          title: "Shri Handicrafts",
          subTitle: "B2B Handicrafts Manufacturer",
          description:
            '"QuickSell is the perfect cataloguing solution for handicraft businesses. It has multiplied my business effeciency by 100x! Live Analytics feature helped me improve the conversion rate & privacy Control feature maintained the exclusivity of my products."',
          customerName: '<span class="text-italic">- Mr. Hemant Agarwal</span>',
          cardVideoUrl:
            "https://youtube.com/embed/SHQthVP6DPY?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-21T07:30:41.108Z",
          updatedAt: "2022-07-06T10:09:19.250Z",
          publishedAt: "2022-06-21T07:30:48.905Z",
          identifier_internal: "B2B Page CustomerReviewSection Testimonial 1",
          locale: "en",
          cardVideoPosterUrl:
            "/assets/shri_handicrafts_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/shri_handicrafts_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 1,
        },
        {
          thumbnailUrl: "/assets/partner_logos/pd_soni.jpeg",
          thumbnailHeight: "185",
          thumbnailWidth: "611",
          title: "PD Soni",
          subTitle: "B2B Jewellery Manufacturer",
          description:
            '"Live Analytics feature helped me improve the conversion rate & privacy Control feature maintained the exclusivity of my products."',
          customerName: '<span class="text-italic">- Mr. Krushang Soni</span>',
          cardVideoUrl:
            "https://youtube.com/embed/pHQibTdgYSw?autoplay=1&muted=0&controls=1",
          cardVideoHeight: "600",
          cardVideoWidth: "828",
          cardVideoTagName: "iframe",
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          createdAt: "2022-06-21T07:32:16.284Z",
          updatedAt: "2022-07-06T10:09:32.352Z",
          publishedAt: "2022-06-21T07:37:40.336Z",
          identifier_internal: "B2B Page CustomerReviewSection Testimonial 2",
          locale: "en",
          cardVideoPosterUrl: "/assets/pd_soni_testimonial_poster_large.webp",
          cardVideoPosterUrlFallback:
            "/assets/pd_soni_testimonial_poster_large.png",
          cardVideoPosterHeight: "600",
          cardVideoPosterWidth: "828",
          sortOrder: 2,
        },
      ],
    },
    cloudStructureSection: {
      id: 14,
      cardTitle:
        "Cloud based centralized product library for all your sales reps",
      cardSubTitle: null,
      cardDescription:
        "Manage and organise accurate product information and enable salespeople to access a centralized, updated and dynamic product catalogue that they can share easily anytime from their phone.",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "cloudStructureSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    specificFeatures: {
      id: 15,
      cardTitle: "B2B Specific features",
      cardSubTitle: "Designed specially for B2B Businesses",
      cardDescription: "Fast order booking for large orders",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "specificFeatures",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T06:37:54.977Z",
          updatedAt: "2022-07-06T11:23:25.398Z",
          publishedAt: "2022-06-21T06:37:56.580Z",
          locale: "en",
          internal_identifier: "b2bPage - specificFeatures - logo 1",
          cardSubTitle: null,
          cardDescription:
            "QuickSell Catalogues are designed for superfast loading and super fast checkout so that your customers don't waste time placing large orders",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: "/assets/two_page_checkout.mp4",
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: "736",
          cardVideoPosterWidth: "1162",
          cardTitle: "Simple 2 page checkout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: true,
          cardVideoMuted: true,
          cardVideoLoop: true,
          cardVideoDisablePictureInPicture: true,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "video",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: "736",
          cardVideoWidth: "1162",
        },
        {
          createdAt: "2022-06-21T06:43:51.965Z",
          updatedAt: "2022-07-06T11:23:35.191Z",
          publishedAt: "2022-06-21T06:43:54.177Z",
          locale: "en",
          internal_identifier: "b2bPage - specificFeatures - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Allow customers to easily order multiple sizes and colors in just a few clicks",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: "/assets/bulk_ordering_sizes_and_colors.mp4",
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: "736",
          cardVideoPosterWidth: "1162",
          cardTitle: "Bulk ordering for sizes and colors",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: true,
          cardVideoMuted: true,
          cardVideoLoop: true,
          cardVideoDisablePictureInPicture: true,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "video",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: "736",
          cardVideoWidth: "1162",
        },
      ],
    },
    b2bFeaturesGridSection: {
      id: 16,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "b2bFeaturesGridSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T06:48:08.231Z",
          updatedAt: "2022-06-23T16:58:36.418Z",
          publishedAt: "2022-06-21T06:53:14.600Z",
          locale: "en",
          internal_identifier: "b2bPage - b2bFeaturesGridSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Avoid wasting time on small orders by setting a minimum order quantity for your products",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/min_order_quantity.webp",
          cardImageUrlFallback: "/assets/min_order_quantity.png",
          cardImageHeight: "652",
          cardImageWidth: "772",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Minimum order quantity",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:54:20.148Z",
          updatedAt: "2022-06-23T16:58:44.032Z",
          publishedAt: "2022-06-21T06:54:21.888Z",
          locale: "en",
          internal_identifier: "b2bPage - b2bFeaturesGridSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Avoid wasting time by setting a minimum order amount below which customers can't order",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/min_order_amount.webp",
          cardImageUrlFallback: "/assets/min_order_amount.png",
          cardImageHeight: "748",
          cardImageWidth: "792",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Minimum order amount",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:55:03.684Z",
          updatedAt: "2022-06-23T16:58:51.460Z",
          publishedAt: "2022-06-21T06:55:07.819Z",
          locale: "en",
          internal_identifier: "b2bPage - b2bFeaturesGridSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Set a different price for your B2B customers. Share only your B2B catalogues with B2B buyers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "assets/b2b_pricing.webp",
          cardImageUrlFallback: "assets/b2b_pricing.png",
          cardImageHeight: "302",
          cardImageWidth: "551",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "B2B Pricing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:55:44.741Z",
          updatedAt: "2022-06-23T16:59:00.602Z",
          publishedAt: "2022-06-21T06:55:47.201Z",
          locale: "en",
          internal_identifier: "b2bPage - b2bFeaturesGridSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Track buyer interest as they view your catalogue, and then focus on converting serious buyers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/live_visitor_analytics.webp",
          cardImageUrlFallback: "/assets/live_visitor_analytics.png",
          cardImageHeight: "620",
          cardImageWidth: "952",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Live Visitor Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    keyUseCaseSection: {
      id: 17,
      cardTitle: "Key use-cases",
      cardSubTitle: null,
      cardDescription: "Designed specially for B2B Businesses",
      sectionTitle:
        "Increase conversion rate from Indiamart, Alibaba and other B2B portals",
      sectionSubTitle: null,
      cardImageUrl: "/assets/increase_conversion_rate.webp",
      cardImageUrlFallback: "/assests/increase_conversion_rate.png",
      cardImageHeight: "1014",
      cardImageWidth: "1612",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyUseCaseSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        "<span>Talk to <span class='text-bold'>serious buyers only</span> by tracking their behaviour on your catalogue and calling them with the right information at the right time</span>",
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    whatsappOrderBookingSection: {
      id: 18,
      cardTitle: "B2B Whatsapp Order Booking",
      cardSubTitle: null,
      cardDescription:
        "Scale WhatsApp Sales by engaging with more customers in lesser time on WhatsApp with QuickSell",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/whatsapp_ordering.webp",
      cardImageUrlFallback: "/assets/whatsapp_ordering.png",
      cardImageHeight: "1558",
      cardImageWidth: "1144",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "whatsappOrderBookingSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T06:57:57.807Z",
          updatedAt: "2022-06-23T16:59:48.071Z",
          publishedAt: "2022-06-21T06:57:59.556Z",
          locale: "en",
          internal_identifier: "b2bPage - whatsappOrderBookingSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Share product images with customers with or without prices/ branding",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:58:44.738Z",
          updatedAt: "2022-06-23T17:00:35.560Z",
          publishedAt: "2022-06-21T06:58:48.180Z",
          locale: "en",
          internal_identifier: "b2bPage - whatsappOrderBookingSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Update your catalogue on WhatsApp Stories",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:59:14.751Z",
          updatedAt: "2022-06-23T17:00:43.266Z",
          publishedAt: "2022-06-21T06:59:16.402Z",
          locale: "en",
          internal_identifier: "b2bPage - whatsappOrderBookingSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/green_check.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "25",
          cardImageWidth: "25",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            "Integrate QuickSell catalogue link with WhatsApp auto-reply and let customers have a preview/ trailer of your products showcase the moment they message you 24x7",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    privateCatalogueSection: {
      id: 19,
      cardTitle: "Private cataloguing",
      cardSubTitle:
        "The only e-commerce platform in the market to offer robust privacy protection for your catalogues so that you can maintain your competitive edge by avoiding leakage of designs and prices to competitors",
      cardDescription: null,
      sectionTitle: "2 privacy modes",
      sectionSubTitle: null,
      cardImageUrl: "/assets/private_cataloguing.webp",
      cardImageUrlFallback: "/assets/private_cataloguing.png",
      cardImageHeight: "1234",
      cardImageWidth: "1216",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "privateCatalogueSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "Learn More",
          url: "/private-showcasing",
          openInNewTab: false,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: true,
          createdAt: "2022-06-21T07:05:54.736Z",
          updatedAt: "2022-06-24T06:57:01.063Z",
          publishedAt: "2022-06-21T07:07:36.664Z",
          locale: "en",
          identifier_internal: "Private Showcasing Page Link",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
      featuresList: [
        {
          createdAt: "2022-06-21T07:02:38.521Z",
          updatedAt: "2022-06-23T16:59:13.833Z",
          publishedAt: "2022-06-21T07:02:39.846Z",
          locale: "en",
          internal_identifier: "b2bPage - privateCatalogueSection - logo 1",
          cardSubTitle: "Trusted buyers only",
          cardDescription:
            "Set your catalogue to private mode to only allow certain customers or customer groups to view your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private mode",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T07:04:32.758Z",
          updatedAt: "2022-06-23T16:59:23.281Z",
          publishedAt: "2022-06-21T07:04:34.262Z",
          locale: "en",
          internal_identifier: "b2bPage - privateCatalogueSection - logo 2",
          cardSubTitle: "Block competitors",
          cardDescription:
            "Set your catalogue in semi-private mode to allow every, but block certain phone numbers from viewing your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Semi-private mode",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    otpBasedVerificationSection: {
      id: 20,
      cardTitle: "OTP based verification",
      cardSubTitle: null,
      cardDescription:
        "In built OTP based phone number verificaiton means that only verified phone numbers can access your catalogue",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/otp_based_verification.webp",
      cardImageUrlFallback: "/assets/otp_based_verification.png",
      cardImageHeight: "1204",
      cardImageWidth: "904",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "otpBasedVerificationSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    expertSection: {
      id: 21,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:52:59.464Z",
          updatedAt: "2022-07-19T16:53:00.452Z",
          publishedAt: "2022-07-19T16:53:00.437Z",
          locale: "en",
          identifier_internal: "B2b Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  quicksellVsPDFData: {
    createdAt: "2022-06-22T03:32:36.474Z",
    updatedAt: "2022-06-24T12:55:06.027Z",
    publishedAt: "2022-06-22T03:34:40.783Z",
    locale: "en",
    competitorSection: {
      id: 73,
      cardTitle: "QUICKSELL VS PDF",
      cardSubTitle:
        "If a tree fell in the forest, but no one heard it fall - did it really fall?",
      cardDescription:
        "If a customer opened your catalogue, but your sales rep doesn't know about it - did it really get opened?",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "competitorSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        "Stop sending long and heavy PDF catalogues to your customers.<br /><br />Adopt a modern buying process with an easy to use, lightweight, dynamic and trackable digital catalogue",
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    comparisonSection: {
      id: 74,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "comparisonSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      tableBody: [
        {
          internal_identifier: "quicksellVsPDFData - tableBody - logo 1",
          competitor:
            "Too much time to create. Need to hire an employee to create PDFs",
          quicksell:
            "Completely do-it-yourself. You can create it yourself and share your digital catalogue instantly with customers",
          createdAt: "2022-06-22T03:35:49.120Z",
          updatedAt: "2022-06-23T18:10:56.720Z",
          publishedAt: "2022-06-22T03:35:50.499Z",
          locale: "en",
          sortOrder: 1,
        },
        {
          internal_identifier: "quicksellVsPDFData - tableBody - logo 2",
          competitor:
            "Need to re-create and re-share PDF everytime product information changes",
          quicksell: "Always up-to-date automatically. Saves hours of time",
          createdAt: "2022-06-22T03:36:14.277Z",
          updatedAt: "2022-06-23T18:10:50.360Z",
          publishedAt: "2022-06-22T03:36:15.577Z",
          locale: "en",
          sortOrder: 2,
        },
        {
          internal_identifier: "quicksellVsPDFData - tableBody - logo 3",
          competitor: "PDFs become heavy with many images in the catalogue",
          quicksell:
            "QuickSell Digital Catalogue loads in the browser almost instantly no matter how many images are there in your catalogue",
          createdAt: "2022-06-22T03:36:31.242Z",
          updatedAt: "2022-06-23T18:11:03.476Z",
          publishedAt: "2022-06-22T03:36:32.624Z",
          locale: "en",
          sortOrder: 3,
        },
        {
          internal_identifier: "quicksellVsPDFData - tableBody - logo 4",
          competitor:
            "Hard to track customer visit and sales follow up (No sales team visibility)",
          quicksell:
            "Real-time followup by sales people for superior customer experience",
          createdAt: "2022-06-22T03:36:58.409Z",
          updatedAt: "2022-06-23T18:11:16.208Z",
          publishedAt: "2022-06-22T03:37:00.250Z",
          locale: "en",
          sortOrder: 4,
        },
        {
          internal_identifier: "quicksellVsPDFData - tableBody - logo 5",
          competitor: "No sales visiblity and tracking",
          quicksell:
            "With QuickSell Digital Catalogue you can track whenever your catalogue gets opened as well as see which customer is looking at which product and for how long!",
          createdAt: "2022-06-22T03:37:20.815Z",
          updatedAt: "2022-06-24T06:03:51.991Z",
          publishedAt: "2022-06-22T03:37:22.936Z",
          locale: "en",
          sortOrder: 5,
        },
        {
          internal_identifier: "quicksellVsPDFData - tableBody - logo 6",
          competitor:
            "No order booking ability. Customer needs to resort to offline order booking",
          quicksell:
            "Quick, digital and efficient order booking. Save hours of yours and your customers time",
          createdAt: "2022-06-22T03:37:38.715Z",
          updatedAt: "2022-06-24T06:04:54.129Z",
          publishedAt: "2022-06-22T03:37:40.474Z",
          locale: "en",
          sortOrder: 6,
        },
      ],
      tableHead: {
        internal_identifier: "quicksellVsPDFData - tableHead",
        competitor: "PDF",
        quicksell: "QuickSell",
        createdAt: "2022-06-22T03:38:08.515Z",
        updatedAt: "2022-06-23T18:13:14.381Z",
        publishedAt: "2022-06-22T03:38:09.887Z",
        locale: "en",
        sortOrder: 1,
      },
    },
    expertSection: {
      id: 75,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  quicksellVsWebsiteData: {
    createdAt: "2022-06-22T03:43:31.787Z",
    updatedAt: "2022-06-24T12:30:42.137Z",
    publishedAt: "2022-06-22T09:50:08.345Z",
    locale: "en",
    competitorSection: {
      id: 76,
      cardTitle: "PERSONAL COMMERCE",
      cardSubTitle:
        "Goodbye long and boring websites,<br /><br />Hello Personal Commerce",
      cardDescription: "Start selling online the way you sell offline",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "competitorSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        "When a customer walks into your shop, you don't ignore them. You welcome them, try to understand their interest and prepare a personalized pitch for them.<br /><br />However, when a customer opens your website, they are faced with an impersonal and boring experience that is common for everyone<br /><br />QuickSell enables your to deal with your customers personally, yet at scale.",
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    comparisonSection: {
      id: 77,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "comparisonSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      tableBody: [
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 1",
          competitor: "Too much time to make changes",
          quicksell: "Instant changes",
          createdAt: "2022-06-22T10:16:01.010Z",
          updatedAt: "2022-06-23T18:12:02.649Z",
          publishedAt: "2022-06-22T10:16:02.709Z",
          locale: "en",
          sortOrder: 1,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 2",
          competitor: "Generic website for everyone",
          quicksell: "Personalized and curated collection",
          createdAt: "2022-06-22T10:16:24.792Z",
          updatedAt: "2022-06-23T18:12:08.913Z",
          publishedAt: "2022-06-22T10:16:26.098Z",
          locale: "en",
          sortOrder: 2,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 3",
          competitor: "Confusing user experience with too many hoops",
          quicksell: "Simple 3 click checkout",
          createdAt: "2022-06-22T10:16:43.669Z",
          updatedAt: "2022-06-23T18:12:16.349Z",
          publishedAt: "2022-06-22T10:16:45.404Z",
          locale: "en",
          sortOrder: 3,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 4",
          competitor:
            "Hard to track customer visit and sales follow up (No sales team visibility)",
          quicksell:
            "Real-time followup by sales people for superior customer experience",
          createdAt: "2022-06-22T10:17:17.426Z",
          updatedAt: "2022-06-23T18:12:22.166Z",
          publishedAt: "2022-06-22T10:17:19.089Z",
          locale: "en",
          sortOrder: 4,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 5",
          competitor: "Distracted browsing experience",
          quicksell: "Focused browsing experience",
          createdAt: "2022-06-22T10:17:39.959Z",
          updatedAt: "2022-06-23T18:12:27.871Z",
          publishedAt: "2022-06-22T10:17:41.759Z",
          locale: "en",
          sortOrder: 5,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 6",
          competitor: "Out of your control and high dependency on others.",
          quicksell: "Complete control of your catalogue in your hands",
          createdAt: "2022-06-22T10:17:59.602Z",
          updatedAt: "2022-06-23T18:12:36.774Z",
          publishedAt: "2022-06-22T10:18:03.645Z",
          locale: "en",
          sortOrder: 6,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 7",
          competitor: "Can't protect your designs and prices",
          quicksell:
            "Complete privacy control to protect your design and prices from competitors",
          createdAt: "2022-06-22T10:18:23.711Z",
          updatedAt: "2022-06-23T18:12:43.549Z",
          publishedAt: "2022-06-22T10:18:24.960Z",
          locale: "en",
          sortOrder: 7,
        },
        {
          internal_identifier: "quicksellVsWebsiteData - tableBody - logo 8",
          competitor: "Hard to differentiate B2B and B2C",
          quicksell: "Customized catalogs for B2B and B2C",
          createdAt: "2022-06-22T10:18:41.093Z",
          updatedAt: "2022-06-23T18:12:50.110Z",
          publishedAt: "2022-06-22T10:18:44.196Z",
          locale: "en",
          sortOrder: 8,
        },
      ],
      tableHead: {
        internal_identifier: "quicksellVsWebsiteData - tableHead",
        competitor: "Traditional Websites",
        quicksell: "Personal Commerce",
        createdAt: "2022-06-22T10:19:11.285Z",
        updatedAt: "2022-06-23T18:12:55.234Z",
        publishedAt: "2022-06-22T10:19:12.670Z",
        locale: "en",
        sortOrder: 1,
      },
    },
    expertSection: {
      id: 78,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  quicksellVsExcelData: {
    createdAt: "2022-06-21T14:12:58.591Z",
    updatedAt: "2022-06-24T12:31:56.388Z",
    publishedAt: "2022-06-21T14:13:01.669Z",
    locale: "en",
    competitorSection: {
      id: 62,
      cardTitle: "QUICKSELL VS EXCEL",
      cardSubTitle:
        "Your products deserve a better display than an Excel sheet",
      cardDescription: "Give your B2B customers a better way to order",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "competitorSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        "Stop sending ugly excel catalogues to your customers.<br /><br />Adopt a modern buying process with a beautiful, easy to use, lightweight, dynamic and trackable digital catalogue",
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    comparisonSection: {
      id: 63,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "comparisonSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      tableBody: [
        {
          internal_identifier: "quicksellVsExcelData - tableBody - logo 1",
          competitor:
            "Looks super ugly. Your products deserve a better display",
          quicksell: "Design your own customer viewing experience",
          createdAt: "2022-06-21T14:13:59.280Z",
          updatedAt: "2022-06-24T12:54:28.832Z",
          publishedAt: "2022-06-21T14:14:01.387Z",
          locale: "en",
          sortOrder: 1,
        },
        {
          internal_identifier: "quicksellVsExcelData - tableBody - logo 2",
          competitor:
            "Need to re-create and re-share Excel everytime product information changes",
          quicksell: "Always up-to-date automatically. Saves hours of time",
          createdAt: "2022-06-21T14:14:18.829Z",
          updatedAt: "2022-06-23T18:09:45.873Z",
          publishedAt: "2022-06-21T14:14:20.200Z",
          locale: "en",
          sortOrder: 2,
        },
        {
          internal_identifier: "quicksellVsExcelData - tableBody - logo 3",
          competitor: "Excel become heavy with many images in the catalogue",
          quicksell:
            "QuickSell Digital Catalogue loads in the browser almost instantly no matter how many images are there in your catalogue",
          createdAt: "2022-06-21T14:14:41.895Z",
          updatedAt: "2022-06-23T18:10:07.260Z",
          publishedAt: "2022-06-21T14:14:43.119Z",
          locale: "en",
          sortOrder: 3,
        },
        {
          internal_identifier: "quicksellVsExcelData - tableBody - logo 4",
          competitor:
            "Hard to track customer visit and sales follow up (No sales team visibility)",
          quicksell:
            "Real-time followup by sales people for superior customer experience",
          createdAt: "2022-06-21T14:15:09.509Z",
          updatedAt: "2022-06-23T18:10:13.663Z",
          publishedAt: "2022-06-21T14:15:10.616Z",
          locale: "en",
          sortOrder: 4,
        },
        {
          internal_identifier: "quicksellVsExcelData - tableBody - logo 5",
          competitor: "No sales visiblity and tracking",
          quicksell:
            "With QuickSell Digital Catalogue you can track whenever your catalogue gets opened as well as see which customer is looking at which product and for how long!",
          createdAt: "2022-06-21T14:15:42.231Z",
          updatedAt: "2022-06-23T18:10:19.105Z",
          publishedAt: "2022-06-21T14:15:43.782Z",
          locale: "en",
          sortOrder: 5,
        },
        {
          internal_identifier: "quicksellVsExcelData - tableBody - logo 6",
          competitor:
            "Extremely hard and slow to order. Customer needs to resort to offline order booking.",
          quicksell:
            "Quick, digital and efficient order booking. Save hours of yours and your customers time",
          createdAt: "2022-06-21T14:16:04.816Z",
          updatedAt: "2022-06-23T18:10:23.832Z",
          publishedAt: "2022-06-21T14:16:13.849Z",
          locale: "en",
          sortOrder: 6,
        },
      ],
      tableHead: {
        internal_identifier: "quicksellVsExcelData - tableHead",
        competitor: "Excel Sheet",
        quicksell: "QuickSell Digital Catalogue",
        createdAt: "2022-06-21T14:16:37.463Z",
        updatedAt: "2022-06-23T18:10:34.864Z",
        publishedAt: "2022-06-21T14:16:39.169Z",
        locale: "en",
        sortOrder: 1,
      },
    },
    expertSection: {
      id: 64,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  quicksellVsDropboxData: {
    createdAt: "2022-06-21T13:35:53.566Z",
    updatedAt: "2022-06-24T12:28:29.355Z",
    publishedAt: "2022-06-21T14:01:24.705Z",
    locale: "en",
    competitorSection: {
      id: 56,
      cardTitle: "QUICKSELL VS CLOUD STORAGE",
      cardSubTitle:
        "Take your products out of digital storage containers and showcase them on a digital shelf, where they deserve to be",
      cardDescription:
        "Your products are not files. Don't showcase them as such",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "competitorSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        "Stop sending ugly excel catalogues to your customers.<br /><br />Adopt a modern buying process with a beautiful, easy to use, lightweight, dynamic and trackable digital catalogue",
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    comparisonSection: {
      id: 57,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "comparisonSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      tableBody: [
        {
          internal_identifier: "quicksellVsDropboxData - tableBody - logo 1",
          competitor: "Products are shown as files instead of products",
          quicksell:
            "Showcase your products with title, price, description and variant information so that it's easy for customers to browse",
          createdAt: "2022-06-21T13:59:07.024Z",
          updatedAt: "2022-06-23T18:09:19.054Z",
          publishedAt: "2022-06-21T13:59:08.749Z",
          locale: "en",
          sortOrder: 1,
        },
        {
          internal_identifier: "quicksellVsDropboxData - tableBody - logo 2",
          competitor:
            "Hard to track customer visit and sales follow up (No sales team visibility)",
          quicksell:
            "Real-time followup by sales people for superior customer experience",
          createdAt: "2022-06-21T13:59:26.141Z",
          updatedAt: "2022-06-23T18:09:23.810Z",
          publishedAt: "2022-06-21T13:59:29.075Z",
          locale: "en",
          sortOrder: 2,
        },
        {
          internal_identifier: "quicksellVsDropboxData - tableBody - logo 3",
          competitor: "No sales visiblity and tracking",
          quicksell:
            "With QuickSell Digital Catalogue you can track whenever your catalogue gets opened as well as see which customer is looking at which product and for how long!",
          createdAt: "2022-06-21T13:59:50.936Z",
          updatedAt: "2022-06-23T18:09:29.291Z",
          publishedAt: "2022-06-21T13:59:52.142Z",
          locale: "en",
          sortOrder: 3,
        },
        {
          internal_identifier: "quicksellVsDropboxData - tableBody - logo 4",
          competitor:
            "Extremely hard and slow to order. Customer needs to resort to offline order booking.",
          quicksell:
            "Quick, digital and efficient order booking. Save hours of yours and your customers time",
          createdAt: "2022-06-21T14:00:35.954Z",
          updatedAt: "2022-06-23T18:09:34.448Z",
          publishedAt: "2022-06-21T14:00:37.222Z",
          locale: "en",
          sortOrder: 4,
        },
      ],
      tableHead: {
        internal_identifier: "quicksellVsDropboxData - tableHead",
        competitor: "Cloud Storage (Dropbox, Google Drive, etc)",
        quicksell: "QuickSell Digital Catalogue",
        createdAt: "2022-06-21T14:02:18.343Z",
        updatedAt: "2022-06-23T18:09:51.332Z",
        publishedAt: "2022-06-21T14:02:20.419Z",
        locale: "en",
        sortOrder: 1,
      },
    },
    expertSection: {
      id: 58,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  quicksellVsShopifyData: {
    createdAt: "2022-06-21T14:03:57.375Z",
    updatedAt: "2022-06-24T13:00:09.366Z",
    publishedAt: "2022-06-21T14:05:16.014Z",
    locale: "en",
    competitorSection: {
      id: 59,
      cardTitle: "QUICKSELL VS SHOPIFY",
      cardSubTitle:
        "Launch your e-commerce website in less than 5 minutes via your smartphone",
      cardDescription:
        "Improve your product visibilty & sales by proving an assisted-buying shopping experience to your customers",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "competitorSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        'Customers are people and they like to be dealt with personally.<br /><br />Shopify is not built for a <span class="text-green">Personal Commerce</span> interaction',
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    comparisonSection: {
      id: 60,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "comparisonSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      tableBody: [
        {
          internal_identifier: "quicksellVsShopifyData - tableBody - logo 1",
          competitor:
            "Go live in 1 week. Complicated and time consuming to setup",
          quicksell: "Go live in 1 day. Simple and easy to setup.",
          createdAt: "2022-06-21T14:07:05.227Z",
          updatedAt: "2022-06-23T18:11:29.343Z",
          publishedAt: "2022-06-21T14:07:06.431Z",
          locale: "en",
          sortOrder: 1,
        },
        {
          internal_identifier: "quicksellVsShopifyData- tableBody - logo 2",
          competitor: "No catalogue curation",
          quicksell:
            "Curate and personalize your catalogues and give an in-store like experience to your customers",
          createdAt: "2022-06-21T14:07:27.795Z",
          updatedAt: "2022-06-23T18:11:38.267Z",
          publishedAt: "2022-06-21T14:07:29.107Z",
          locale: "en",
          sortOrder: 2,
        },
        {
          internal_identifier: "quicksellVsShopifyData - tableBody - logo 3",
          competitor: "No sales visiblity and tracking",
          quicksell:
            "With QuickSell Digital Catalogue you can track whenever your catalogue gets opened as well as see which customer is looking at which product and for how long!",
          createdAt: "2022-06-21T14:08:10.703Z",
          updatedAt: "2022-06-23T18:11:44.584Z",
          publishedAt: "2022-06-21T14:08:12.355Z",
          locale: "en",
          sortOrder: 3,
        },
        {
          internal_identifier: "quicksellVsShopifyData - tableBody - logo 4",
          competitor: "Extremely expensive for B2B businesses",
          quicksell:
            "QuickSell is built to make B2B commerce affordable and easy to use",
          createdAt: "2022-06-21T14:08:30.903Z",
          updatedAt: "2022-06-23T18:11:51.119Z",
          publishedAt: "2022-06-21T14:08:36.751Z",
          locale: "en",
          sortOrder: 4,
        },
      ],
      tableHead: {
        internal_identifier: "quicksellVsShopifyData - tableHead",
        competitor: "Shopify",
        quicksell: "QuickSell Digital Catalogue",
        createdAt: "2022-06-21T14:09:02.431Z",
        updatedAt: "2022-06-23T18:13:02.947Z",
        publishedAt: "2022-06-21T14:09:03.780Z",
        locale: "en",
        sortOrder: 1,
      },
    },
    expertSection: {
      id: 61,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  privateShowcasingPageData: {
    createdAt: "2022-06-21T09:49:54.775Z",
    updatedAt: "2022-07-05T17:18:28.825Z",
    publishedAt: "2022-06-22T17:07:27.836Z",
    locale: "en",
    quicksellPrivacyModuleSection: {
      id: 28,
      cardTitle:
        '<span>Protect your designs and price with <span class="text-green">QuickSell\'s Privacy Module</span></span>',
      cardSubTitle: "KEY FEATURE",
      cardDescription:
        "Win over competitors by preventing them from copying your product secrets",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/private_showcasing_main_image.webp",
      cardImageUrlFallback: "/assets/private_showcasing_main_image.png",
      cardImageHeight: "960",
      cardImageWidth: "1490",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "quicksellPrivacyModuleSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
        {
          type: "default",
          linkUrl:
            "/download?utm_source=web&utm_medium=quicksell.co&utm_campaign=catalogue_ad_campaign",
          openInNewTab: false,
          text: "DOWNLOAD",
          onClick: "captureDownloadAndroidClick",
          createdAt: "2022-06-20T16:08:26.814Z",
          updatedAt: "2022-07-23T06:14:10.173Z",
          publishedAt: "2022-06-20T17:58:55.017Z",
          locale: "en",
          identifier_internal: "Download App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 2,
        },
      ],
    },
    controlWhoSeesYourDesignSection: {
      id: 29,
      cardTitle: "Control who sees your designs and prices",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/custom_access_control.webp",
      cardImageUrlFallback: "/assets/custom_access_control.png",
      cardImageHeight: "1800",
      cardImageWidth: "3018",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "controlWhoSeesYourDesignSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T09:55:52.399Z",
          updatedAt: "2022-06-23T17:21:54.774Z",
          publishedAt: "2022-06-21T09:55:54.510Z",
          locale: "en",
          internal_identifier:
            "privateShowcasingPageData - controlWhoSeesYourDesignSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Restrict access to one catalogue, multiple catalogues or your entire store",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom access control",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T09:56:22.924Z",
          updatedAt: "2022-06-23T17:22:03.622Z",
          publishedAt: "2022-06-21T09:56:25.312Z",
          locale: "en",
          internal_identifier:
            "privateShowcasingPageData - controlWhoSeesYourDesignSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Easily allow only <span class='text-bold'>trusted buyers</span> to see a catalogue and restrict anyone else. Create as many customer groups as you like",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Customer group based access",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    privateCatalogueSection: {
      id: 30,
      cardTitle: "Private cataloguing",
      cardSubTitle: null,
      cardDescription:
        "The only e-commerce platform in the market to offer robust privacy protection for your catalogues so that you can maintain your competitive edge by avoiding leakage of designs and prices to competitors",
      sectionTitle: "2 privacy modes",
      sectionSubTitle: null,
      cardImageUrl: "/assets/private_cataloguing.webp",
      cardImageUrlFallback: "/assets/private_cataloguing.png",
      cardImageHeight: "1234",
      cardImageWidth: "1216",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "privateCatalogueSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T10:00:54.468Z",
          updatedAt: "2022-06-23T17:22:22.629Z",
          publishedAt: "2022-06-21T10:00:56.431Z",
          locale: "en",
          internal_identifier:
            "privateShowcasingPageData - privateCatalogueSection - logo 1",
          cardSubTitle: "Trusted buyers only",
          cardDescription:
            "Set your catalogue to private mode to only allow certain customers or customer groups to view your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private mode",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T10:01:28.034Z",
          updatedAt: "2022-06-23T17:22:30.404Z",
          publishedAt: "2022-06-21T10:01:29.801Z",
          locale: "en",
          internal_identifier:
            "privateShowcasingPageData - privateCatalogueSection - logo 2",
          cardSubTitle: "Block competitors",
          cardDescription:
            "Set your catalogue in semi-private mode to allow every, but block certain phone numbers from viewing your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Semi-private mode",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    otpBasedVerificationSection: {
      id: 31,
      cardTitle: "OTP based verification",
      cardSubTitle: null,
      cardDescription:
        "In built OTP based phone number verificaiton means that only verified phone numbers can access your catalogue",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/otp_based_verification.webp",
      cardImageUrlFallback: "/assets/otp_based_verification.png",
      cardImageHeight: "1204",
      cardImageWidth: "904",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "otpBasedVerificationSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    featureGridSection: {
      id: 32,
      cardTitle: "",
      cardSubTitle: null,
      cardDescription: "",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "",
      cardImageUrlFallback: null,
      cardImageHeight: "",
      cardImageWidth: "",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "featureGridSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T10:03:32.056Z",
          updatedAt: "2022-07-04T11:23:46.726Z",
          publishedAt: "2022-06-21T10:03:34.737Z",
          locale: "en",
          internal_identifier:
            "privateShowcasingPageData - featureGridSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Using QuickSell Screenshot Protection with E-Commerce App prevent your product designs from being copied by competitors",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/prevent_screenshot.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "53",
          cardImageWidth: "53",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Screenshot protection",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "leftColumn",
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-24T13:08:24.345Z",
          updatedAt: "2022-07-04T11:25:30.842Z",
          publishedAt: "2022-07-04T11:11:09.275Z",
          locale: "en",
          internal_identifier:
            "privateShowcasingPageData - featureGridSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Limit the amount of time that some customers have access to any catalogue from your product collection",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/hourglass_timer.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "53",
          cardImageWidth: "53",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: " Timer based cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: "rightColumn",
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    expertSection: {
      id: 33,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-06-21T03:16:43.720Z",
          updatedAt: "2022-07-19T12:57:20.874Z",
          publishedAt: "2022-06-21T03:16:45.328Z",
          locale: "en",
          identifier_internal: "Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  nativeAppPageData: {
    createdAt: "2022-07-05T19:43:55.763Z",
    updatedAt: "2022-07-19T16:58:49.070Z",
    publishedAt: "2022-07-05T16:14:43.655Z",
    locale: "en",
    getYourOwnMobileAppSection: {
      id: 114,
      cardTitle:
        '<span>Get your own <span class="text-green">E-Commerce Mobile App</span> in just 2 Days</span>',
      cardSubTitle: "AVAILABLE FOR APPLE IOS AND ANDROID",
      cardDescription:
        "Build your brand, increase your repeated buyers by 10x & more. Unlock the power of e-commerce app now",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "",
      cardImageUrlFallback: null,
      cardImageHeight: "",
      cardImageWidth: "",
      cardVideoUrl: "/assets/app_with_push_notification.mp4",
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "getYourOwnMobileAppSection",
      cardVideoControls: false,
      cardVideoAutoplay: true,
      cardVideoMuted: true,
      cardVideoLoop: true,
      cardVideoDisablePictureInPicture: true,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "video",
      cardVideoHeight: "1000",
      cardVideoWidth: "1000",
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: "/app-demo?page=get-native-app",
          openInNewTab: false,
          text: "GET YOUR APP NOW",
          onClick: null,
          createdAt: "2022-07-05T19:47:14.032Z",
          updatedAt: "2022-07-20T06:31:31.306Z",
          publishedAt: "2022-07-05T19:48:27.215Z",
          locale: "en",
          identifier_internal: "Native App Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    whyToGetYourOwnAppSection: {
      id: 115,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: "Why to get your own App?",
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "whyToGetYourOwnAppSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription:
        "Stats recommend that shopping mobile apps are growing at a rate of 54% year-over-year. That's the highest rate compared to any other app category & 78% of consumers would rather use an app to buy from an eCommerce shop than a mobile website. Following are some more benefits of having your own app:",
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-07-05T19:50:53.476Z",
          updatedAt: "2022-07-06T09:10:26.049Z",
          publishedAt: "2022-07-05T19:50:57.685Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - whyToGetYourOwnAppSection feature 1",
          cardSubTitle: null,
          cardDescription:
            "Do not rely on Amazon, Flipkart or any other app to showcase your product when you can re-engage with your users on your own app without any competition",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/make_your_brand_standout.webp",
          cardImageUrlFallback: "/assets/make_your_brand_standout.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Make your Brand Standout",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T19:52:09.037Z",
          updatedAt: "2022-07-06T09:10:59.949Z",
          publishedAt: "2022-07-05T14:37:36.965Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - whyToGetYourOwnAppSection feature 2",
          cardSubTitle: null,
          cardDescription:
            "Having your own app builds trust & confidence among the buyers, which helps you get new orders from customers quickly",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/gain_your_customers_trust.webp",
          cardImageUrlFallback: "/assets/gain_your_customers_trust.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Gain your Customer's trust",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T19:52:26.770Z",
          updatedAt: "2022-07-06T09:11:32.658Z",
          publishedAt: "2022-07-05T14:37:42.156Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - whyToGetYourOwnAppSection feature 3",
          cardSubTitle: null,
          cardDescription:
            "Reach out to your customers with interactive push notifications & increase your sales by 3x. With the simple UI, user can place the order in 2 simple steps",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/increase_repeated_buyers.webp",
          cardImageUrlFallback: "/assets/increase_repeated_buyers.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Increase Repeated Buyers",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T19:54:09.502Z",
          updatedAt: "2022-07-06T09:11:59.407Z",
          publishedAt: "2022-07-05T19:54:49.792Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - whyToGetYourOwnAppSection feature 4",
          cardSubTitle: null,
          cardDescription:
            "Showcase your latest offer or collection with single push notification & in-app banners",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/rollout_new_offers_collection.webp",
          cardImageUrlFallback: "/assets/rollout_new_offers_collection.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Rollout New offers & collection easily",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T19:54:36.264Z",
          updatedAt: "2022-07-06T09:12:24.772Z",
          publishedAt: "2022-07-05T19:54:51.032Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - whyToGetYourOwnAppSection feature 5",
          cardSubTitle: null,
          cardDescription:
            "With our Screenshot protection feature, to can prevent your latest designs & prices from getting copied",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/avoid_design_price_theft.webp",
          cardImageUrlFallback: "/assets/avoid_design_price_theft.png",
          cardImageHeight: "711",
          cardImageWidth: "864",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Avoid design & price theft",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    uniqueFeaturesOfNativeAppSection: {
      id: 117,
      cardTitle: "Unique Features of QuickSell Apps",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/unique_features_of_native_app.webp",
      cardImageUrlFallback: "/assets/unique_features_of_native_app.png",
      cardImageHeight: "640",
      cardImageWidth: "483",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "uniqueFeaturesOfNativeAppSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-07-05T15:28:00.663Z",
          updatedAt: "2022-07-05T15:30:00.585Z",
          publishedAt: "2022-07-05T15:28:26.292Z",
          locale: "en",
          internal_identifier:
            "nativeAppAPage - uniqueFeaturesOfNativeAppSection - feature 1",
          cardSubTitle: null,
          cardDescription:
            "Engage your customers with alerts on offers, coupons and new releases. Drive-up sales by marketing products directly to customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Push Notifications",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T21:11:46.871Z",
          updatedAt: "2022-07-05T21:15:04.287Z",
          publishedAt: "2022-07-05T21:12:06.421Z",
          locale: "en",
          internal_identifier:
            "nativeAppAPage - uniqueFeaturesOfNativeAppSection - feature 2",
          cardSubTitle: null,
          cardDescription:
            "This feature helps you maintain privacy by avoiding your designs & prices from getting copied via screenshots",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Screenshot Protection",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T21:12:00.439Z",
          updatedAt: "2022-07-05T21:15:15.527Z",
          publishedAt: "2022-07-05T21:12:08.138Z",
          locale: "en",
          internal_identifier:
            "nativeAppAPage - uniqueFeaturesOfNativeAppSection - feature 3",
          cardSubTitle: null,
          cardDescription:
            "Allows customers to add filters to their search. With Shopping cart customers can add product to their cart & go checkout in 2 simple steps",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Filter & Shopping Cart",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-07-05T21:12:21.210Z",
          updatedAt: "2022-07-05T21:15:26.273Z",
          publishedAt: "2022-07-05T21:12:24.810Z",
          locale: "en",
          internal_identifier:
            "nativeAppAPage - uniqueFeaturesOfNativeAppSection - feature 4",
          cardSubTitle: null,
          cardDescription:
            "Add interactive banner of your latest offers & collection on the top of your app's main screen",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: null,
          cardImageUrlFallback: null,
          cardImageHeight: null,
          cardImageWidth: null,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Banners",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    builtWithQuickSellSection: {
      id: 120,
      cardTitle:
        '<span class="text-libre-bodoni text-italic">Experience the </span><span class="text-avenir-next text-bold text-italic">e-commerce App</span>',
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "builtWithQuickSellSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-07-05T21:34:11.201Z",
          updatedAt: "2022-07-19T10:51:03.882Z",
          publishedAt: "2022-07-05T21:34:22.897Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - builtWithQuickSellSection - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/native_app/jake_clothing.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/native_app/jake_clothing.png",
          cardImageHeight: "512",
          cardImageWidth: "512",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Jake Clothing",
          linkUrl:
            "https://play.google.com/store/apps/details?id=jakeclothing.catalog.to",
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Jake Clothing",
              url: "https://play.google.com/store/apps/details?id=jakeclothing.catalog.to",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-07-19T10:38:02.855Z",
              updatedAt: "2022-07-19T10:38:05.848Z",
              publishedAt: "2022-07-19T10:38:05.841Z",
              locale: "en",
              identifier_internal:
                "NativeAppPage - BuiltWithQuicksellSection - Jake Clothing",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-07-05T21:34:20.697Z",
          updatedAt: "2022-07-19T10:51:42.972Z",
          publishedAt: "2022-07-05T21:34:26.242Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - builtWithQuickSellSection - logo 2",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/native_app/aliva_jewellers.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/native_app/aliva_jewellers.png",
          cardImageHeight: "512",
          cardImageWidth: "512",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Aliva Jewellers",
          linkUrl:
            "https://play.google.com/store/apps/details?id=alivajewellers.catalog.to",
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Aliva Jewellers",
              url: "https://play.google.com/store/apps/details?id=alivajewellers.catalog.to",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-07-19T10:39:05.249Z",
              updatedAt: "2022-07-19T10:39:10.420Z",
              publishedAt: "2022-07-19T10:39:10.413Z",
              locale: "en",
              identifier_internal:
                "NativeAppPage - BuiltWithQuicksellSection - Aliva Jewellers",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-07-05T21:34:35.628Z",
          updatedAt: "2022-07-19T10:52:04.147Z",
          publishedAt: "2022-07-05T21:38:06.006Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - builtWithQuickSellSection - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/native_app/om_arham.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/native_app/om_arham.png",
          cardImageHeight: "480",
          cardImageWidth: "480",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Om Arham",
          linkUrl:
            "https://play.google.com/store/apps/details?id=omarham.catalog.to",
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Om Arham",
              url: "https://play.google.com/store/apps/details?id=omarham.catalog.to",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-07-19T10:42:09.124Z",
              updatedAt: "2022-07-19T10:42:10.452Z",
              publishedAt: "2022-07-19T10:42:10.425Z",
              locale: "en",
              identifier_internal:
                "NativeAppPage - BuiltWithQuicksellSection - Om Arham",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-07-05T16:22:30.242Z",
          updatedAt: "2022-07-19T10:52:18.703Z",
          publishedAt: "2022-07-05T16:22:31.761Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - builtWithQuickSellSection - logo 4",
          cardSubTitle: null,
          cardDescription: "",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/native_app/om_handicrafts.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/native_app/om_handicrafts.png",
          cardImageHeight: "512",
          cardImageWidth: "512",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Om Handicrafts",
          linkUrl:
            "https://play.google.com/store/apps/details?id=omhandicrafts.catalog.to",
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "Om Handicrafts",
              url: "https://play.google.com/store/apps/details?id=omhandicrafts.catalog.to",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-07-19T10:43:06.774Z",
              updatedAt: "2022-07-19T10:45:30.244Z",
              publishedAt: "2022-07-19T10:43:07.906Z",
              locale: "en",
              identifier_internal:
                "NativeAppPage - BuiltWithQuicksellSection - Om Handicrafts",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-07-05T16:22:28.907Z",
          updatedAt: "2022-07-19T10:52:28.628Z",
          publishedAt: "2022-07-05T16:22:32.787Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - builtWithQuickSellSection - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/built_with_quicksell/native_app/pd_soni.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/native_app/pd_soni.jpeg",
          cardImageHeight: "185",
          cardImageWidth: "611",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PD Soni Jewels",
          linkUrl:
            "https://play.google.com/store/apps/details?id=pdahmedabad.catalog.to",
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "PD Soni Jewels",
              url: "https://play.google.com/store/apps/details?id=pdahmedabad.catalog.to",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-07-19T10:44:23.546Z",
              updatedAt: "2022-07-19T10:45:41.998Z",
              publishedAt: "2022-07-19T10:44:26.094Z",
              locale: "en",
              identifier_internal:
                "NativeAppPage - BuiltWithQuicksellSection - PD Soni Jewels",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
        {
          createdAt: "2022-07-05T16:25:37.423Z",
          updatedAt: "2022-07-19T10:52:48.334Z",
          publishedAt: "2022-07-05T16:25:38.439Z",
          locale: "en",
          internal_identifier:
            "nativeAppPageData - builtWithQuickSellSection - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl:
            "/assets/built_with_quicksell/native_app/supermart.webp",
          cardImageUrlFallback:
            "/assets/built_with_quicksell/native_app/supermart.png",
          cardImageHeight: "512",
          cardImageWidth: "512",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "SuperMart",
          linkUrl:
            "https://play.google.com/store/apps/details?id=supermart.catalog.to",
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
          linksList: [
            {
              text: "SuperMart",
              url: "https://play.google.com/store/apps/details?id=supermart.catalog.to",
              openInNewTab: false,
              color: null,
              iconPosition: "right",
              showExtraIcon: false,
              extraIconUrl: null,
              extraIconPosition: null,
              showLinkIcon: true,
              createdAt: "2022-07-19T10:45:12.599Z",
              updatedAt: "2022-07-19T10:57:15.824Z",
              publishedAt: "2022-07-19T10:57:15.817Z",
              locale: "en",
              identifier_internal:
                "NativeAppPage - BuiltWithQuicksellSection - SuperMart",
              columnName: null,
              sectionName: null,
              sortOrder: 1,
              menuId: null,
              onClick: null,
              bold: false,
            },
          ],
        },
      ],
    },
    expertSection: {
      id: 116,
      cardTitle: 'Talk to an <span class="text-slant-underline">expert</span>',
      cardSubTitle: null,
      cardDescription:
        "Let our experts guide you with using QuickSell for your specific business case",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/talk_to_an_expert.webp",
      cardImageUrlFallback: "/assets/talk_to_an_expert.png",
      cardImageHeight: "740",
      cardImageWidth: "1120",
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "expertSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: "openBookDemo",
          createdAt: "2022-07-19T16:56:10.411Z",
          updatedAt: "2022-07-19T16:56:11.221Z",
          publishedAt: "2022-07-19T16:56:11.193Z",
          locale: "en",
          identifier_internal: "Native App Page - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
  b2bLandingData: {
    createdAt: "2022-06-21T06:25:21.696Z",
    updatedAt: "2022-07-19T16:59:20.432Z",
    publishedAt: "2022-06-23T10:34:30.809Z",
    locale: "en",
    bookDemoFormSection: {
      id: 12,
      cardTitle:
        '<span class="text-green">Un-complicate</span> B2B online ordering process for your retailers',
      cardSubTitle: null,
      cardDescription: "",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/b2blanding_mr_david_catalogue.webp",
      cardImageUrlFallback: "/assets/b2blanding_mr_david_catalogue.png",
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "bookDemoFormSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      linksList: [
        {
          text: "Contact us",
          url: "https://quicksell.notion.site/Contact-Us-1640e1cfdde445d8871e246dffc0b425",
          openInNewTab: true,
          color: null,
          iconPosition: "right",
          showExtraIcon: false,
          extraIconUrl: null,
          extraIconPosition: null,
          showLinkIcon: false,
          createdAt: "2022-06-23T19:39:33.476Z",
          updatedAt: "2022-07-06T10:21:49.038Z",
          publishedAt: "2022-06-23T19:39:36.095Z",
          locale: "en",
          identifier_internal: "Contact us Link",
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          menuId: null,
          onClick: null,
          bold: false,
        },
      ],
    },
    brandsContainerSection: {
      id: 13,
      cardTitle:
        'Trusted by over <span class="text-green">5 million users</span> across <span class="text-green">120 countries</span>',
      cardSubTitle: null,
      cardDescription: "",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "brandsContainerSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-20T17:26:56.199Z",
          updatedAt: "2022-06-23T17:07:12.018Z",
          publishedAt: "2022-06-20T17:58:46.763Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 1",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pantaloons.webp",
          cardImageUrlFallback: "/assets/partner_logos/pantaloons.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Pantaloons",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-20T17:27:22.706Z",
          updatedAt: "2022-06-23T18:02:16.855Z",
          publishedAt: "2022-06-20T17:58:34.032Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 2",
          cardSubTitle: null,
          cardDescription: "",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/peter_england.webp",
          cardImageUrlFallback: "/assets/partner_logos/peter_england.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Peter England",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:18:23.996Z",
          updatedAt: "2022-06-23T18:02:26.904Z",
          publishedAt: "2022-06-21T03:18:24.910Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 3",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/sabyasachi.webp",
          cardImageUrlFallback: "/assets/partner_logos/sabyasachi.png",
          cardImageHeight: "500",
          cardImageWidth: "1000",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Sabyasachi",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:19:09.401Z",
          updatedAt: "2022-06-23T18:02:34.789Z",
          publishedAt: "2022-06-21T03:19:11.976Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 4",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/gini_and_jony.webp",
          cardImageUrlFallback: "/assets/partner_logos/gini_and_jony.png",
          cardImageHeight: "94",
          cardImageWidth: "199",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Gini & Jony",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:19:36.599Z",
          updatedAt: "2022-06-23T18:02:42.315Z",
          publishedAt: "2022-06-21T03:19:39.846Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 5",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/grt.webp",
          cardImageUrlFallback: "/assets/partner_logos/grt.png",
          cardImageHeight: "194",
          cardImageWidth: "160",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "GRT",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:20:10.134Z",
          updatedAt: "2022-06-23T18:02:49.227Z",
          publishedAt: "2022-06-21T03:20:17.934Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 6",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/tupperware.webp",
          cardImageUrlFallback: "/assets/partner_logos/tupperware.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Tupperware",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:20:46.201Z",
          updatedAt: "2022-06-23T18:02:56.650Z",
          publishedAt: "2022-06-21T03:20:48.441Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 7",
          cardSubTitle: "",
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/hamleys.webp",
          cardImageUrlFallback: "/assets/partner_logos/hamleys.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Hamleys",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 7,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:21:10.867Z",
          updatedAt: "2022-06-23T18:03:04.247Z",
          publishedAt: "2022-06-21T03:21:17.458Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 8",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/neelkanth.webp",
          cardImageUrlFallback: "/assets/partner_logos/neelkanth.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Neelkanth",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 8,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:21:36.348Z",
          updatedAt: "2022-06-23T18:03:13.549Z",
          publishedAt: "2022-06-21T03:21:38.874Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 9",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/miniso.webp",
          cardImageUrlFallback: "/assets/partner_logos/miniso.png",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Miniso",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 9,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:22:03.060Z",
          updatedAt: "2022-06-23T17:07:34.290Z",
          publishedAt: "2022-06-21T03:22:05.776Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 10",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/siroya.webp",
          cardImageUrlFallback: "/assets/partner_logos/siroya.jpeg",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Siroya",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 10,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:22:34.969Z",
          updatedAt: "2022-06-23T17:07:46.511Z",
          publishedAt: "2022-06-21T03:22:39.730Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 11",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/toy_port.webp",
          cardImageUrlFallback: "/assets/partner_logos/toy_port.png",
          cardImageHeight: "185",
          cardImageWidth: "606",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Toy Port",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 11,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:23:38.975Z",
          updatedAt: "2022-06-23T17:07:58.936Z",
          publishedAt: "2022-06-21T03:23:43.172Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 12",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/pd_soni.webp",
          cardImageUrlFallback: "/assets/partner_logos/pd_soni.jpeg",
          cardImageHeight: "100",
          cardImageWidth: "200",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "PD Soni",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 12,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:24:08.724Z",
          updatedAt: "2022-06-23T17:08:09.632Z",
          publishedAt: "2022-06-21T03:24:11.811Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 13",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/bakery.webp",
          cardImageUrlFallback: "/assets/partner_logos/bakery.png",
          cardImageHeight: "1172",
          cardImageWidth: "2132",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Bakery",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 13,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:24:38.459Z",
          updatedAt: "2022-06-23T17:08:19.262Z",
          publishedAt: "2022-06-21T03:24:40.858Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 14",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/kokoh.webp",
          cardImageUrlFallback: "/assets/partner_logos/kokoh.jpeg",
          cardImageHeight: "185",
          cardImageWidth: "297",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Kokoh",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 14,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:25:10.140Z",
          updatedAt: "2022-06-23T18:01:57.757Z",
          publishedAt: "2022-06-21T03:25:12.864Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 15",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/skynn.webp",
          cardImageUrlFallback: "/assets/partner_logos/skynn.jpeg",
          cardImageHeight: "185",
          cardImageWidth: "185",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Skynn",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 15,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T03:25:33.685Z",
          updatedAt: "2022-06-23T18:02:07.250Z",
          publishedAt: "2022-06-21T03:25:34.626Z",
          locale: "en",
          internal_identifier: "b2blanding - section 2 - logo 16",
          cardSubTitle: null,
          cardDescription: null,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/partner_logos/mangalmani_jewels.webp",
          cardImageUrlFallback: "/assets/partner_logos/mangalmani_jewels.png",
          cardImageHeight: "1536",
          cardImageWidth: "1536",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Mangalmani",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: null,
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 16,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    customerReviewSection: {
      id: 12,
      cardTitle:
        'I was spending <span class="text-green">$2k per month on Shopify</span> earlier but now I’m getting more features at less than <span class="text-green">1/10th the cost on QuickSell!</span>',
      cardSubTitle: "Martin Das",
      cardDescription: "Founder, Threadworks",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: "/assets/b2b_landing_customer_review_face.png.webp",
      cardImageUrlFallback: "/assets/b2b_landing_customer_review_face.png",
      cardImageUrlFallback: null,
      cardImageHeight: 94,
      cardImageWidth: 94,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "customerReviewSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
    },
    makeOrdersBookingSection: {
      id: 44,
      cardTitle:
        'Catalogue to make orders booking <span class="text-slant-underline text-green">3x simpler & faster</span>',
      cardSubTitle: null,
      cardDescription:
        "In 5 Minutes, get personalized, privacy-protected catalogues for your products",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "makeOrdersBookingSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: () => {
            const bookDemoForm = document.querySelector('#b2b-landing-page-book-demo-form');
            if (bookDemoForm) {
              bookDemoForm.scrollIntoView({ behavior: 'smooth' });
            }
          },
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "B2blanding - section 3 - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    keyFeaturesSection: {
      id: 51,
      cardTitle: "Try Quicksell now!",
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: `KEY FEATURES`,
      sectionSubTitle:
        'Here’s why B2B businesses <span class="text-green">love</span> QuickSell',
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyFeaturesSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T12:47:54.956Z",
          updatedAt: "2022-06-23T17:03:04.372Z",
          publishedAt: "2022-06-21T12:47:56.812Z",
          locale: "en",
          internal_identifier: "b2blanding - keyFeaturesSection - image 1",
          cardSubTitle: null,
          cardDescription:
            "Complete control over who sees what catalogues and products",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_private_catalogues.webp",
          cardImageUrlFallback: "/assests/b2blanding_private_catalogues.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private Catalogues and Prices",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:09.721Z",
          updatedAt: "2022-06-23T17:03:12.974Z",
          publishedAt: "2022-06-21T12:49:11.269Z",
          locale: "en",
          internal_identifier: "b2blanding - keyFeaturesSection - image 2",
          cardSubTitle: null,
          cardDescription:
            "Create curated buying experience for your customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_personalised_catalogues.webp",
          cardImageUrlFallback:
            "/assets/b2blanding_personalised_catalogues.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Personalized catalogues",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:58.483Z",
          updatedAt: "2022-06-23T17:03:21.202Z",
          publishedAt: "2022-06-21T12:49:59.941Z",
          locale: "en",
          internal_identifier: "b2blanding - keyFeaturesSection - image 3",
          cardSubTitle: null,
          cardDescription: `Super fast 2-page checkout so that your customers don't waste time placing large orders`,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_easy_mobile_ordering.webp",
          cardImageUrlFallback: "/assets/b2blanding_easy_mobile_ordering.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Easy mobile ordering",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:50:57.775Z",
          updatedAt: "2022-06-23T17:03:31.216Z",
          publishedAt: "2022-06-21T12:50:59.229Z",
          locale: "en",
          internal_identifier: "b2blanding - keyFeaturesSection - image 4",
          cardSubTitle: null,
          cardDescription:
            "Set a minimum order quantity and amount for your products to only accept bulk buyers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_b2b_ordering_rules.webp",
          cardImageUrlFallback: "/assets/b2blanding_b2b_ordering_rules.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "B2B ordering rules",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:51:52.930Z",
          updatedAt: "2022-06-23T17:03:40.521Z",
          publishedAt: "2022-06-21T12:51:54.343Z",
          locale: "en",
          internal_identifier: "b2blanding - keyFeaturesSection - image 5",
          cardSubTitle: null,
          cardDescription:
            "Live track when someone opens your catalogue and see how much time is spent on each product",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_real_time_analytics.webp",
          cardImageUrlFallback: "/assets/b2blanding_real_time_analytics.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Real-Time Analytics",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:52:43.686Z",
          updatedAt: "2022-06-23T17:03:48.894Z",
          publishedAt: "2022-06-21T12:52:45.511Z",
          locale: "en",
          internal_identifier: "b2blanding - keyFeaturesSection - image 6",
          cardSubTitle: null,
          cardDescription:
            "Allow your retail customers to place orders in bulk and sets of colours or prices",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_easy_bulk_ordering.webp",
          cardImageUrlFallback: "/assets/b2blanding_easy_bulk_ordering.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Easy Bulk ordering",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: () => {
            const bookDemoForm = document.querySelector('#b2b-landing-page-book-demo-form');
            if (bookDemoForm) {
              bookDemoForm.scrollIntoView({ behavior: 'smooth' });
            }
          },
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "B2blanding - section 3 - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    privateShowcasingSection: {
      id: 51,
      cardTitle:
        'Learn more on online order booking, while <span class="text-slant-underline text-green">maintaining privacy</span>',
      cardSubTitle: null,
      cardDescription:
        "Win over competitors by preventing them from copying your product secrets",
      sectionTitle: `PRIVATE SHOWCASING`,
      sectionSubTitle: `Protect your designs and price with QuickSell's <span class="text-green">Privacy Module</span>`,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "privateShowcasingSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T12:47:54.956Z",
          updatedAt: "2022-06-23T17:03:04.372Z",
          publishedAt: "2022-06-21T12:47:56.812Z",
          locale: "en",
          internal_identifier:
            "b2blanding - privateShowcasingSection - image 1",
          cardSubTitle: null,
          cardDescription:
            "Control access to specific catalogs or your entire store, enabling trusted buyers while restricting others.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_catalogue_specific_access.webp",
          cardImageUrlFallback:
            "/assests/b2blanding_catalogue_specific_access.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Catalogue specific access control",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:09.721Z",
          updatedAt: "2022-06-23T17:03:12.974Z",
          publishedAt: "2022-06-21T12:49:11.269Z",
          locale: "en",
          internal_identifier:
            "b2blanding - privateShowcasingSection - image 2",
          cardSubTitle: null,
          cardDescription:
            "With private and semi-private mode, you may make your catalogue available only to qualified buyers or restrict specific phone numbers from viewing catalogues",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_catalogue_access_control.webp",
          cardImageUrlFallback:
            "/assets/b2blanding_catalogue_access_control.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Private cataloguing",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:58.483Z",
          updatedAt: "2022-06-23T17:03:21.202Z",
          publishedAt: "2022-06-21T12:49:59.941Z",
          locale: "en",
          internal_identifier:
            "b2blanding - privateShowcasingSection - image 3",
          cardSubTitle: null,
          cardDescription: `In built OTP based phone number verificaiton means that only verified phone numbers can access your catalogue`,
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_group_access_control.webp",
          cardImageUrlFallback: "/assets/b2blanding_group_access_control.png",
          cardImageHeight: 400,
          cardImageWidth: 350,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Group based access control",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: () => {
            const bookDemoForm = document.querySelector('#b2b-landing-page-book-demo-form');
            if (bookDemoForm) {
              bookDemoForm.scrollIntoView({ behavior: 'smooth' });
            }
          },
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "B2blanding - section 3 - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    liveAnalyticsSection: {
      id: 52,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription:
        "Track customer activity in real-time - reach out to the right buyers at the right time",
      sectionTitle: "LIVE ANALYTICS",
      sectionSubTitle:
        'Increase conversion rate with <span class="text-green">B2B Live Analytics</span> and <span class="text-green">Real-time Tracking</span>',
      cardImageUrl: "assets/b2blanding_live_analytics.webp",
      cardImageUrlFallback: "assets/b2blanding_live_analytics.png",
      cardImageHeight: 1160,
      cardImageWidth: 800,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "liveAnalyticsSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-23T06:03:41.077Z",
          updatedAt: "2022-06-23T17:05:45.383Z",
          publishedAt: "2022-06-23T06:03:43.487Z",
          locale: "en",
          internal_identifier: "b2blanding - liveAnalyticsSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Leverage analytics data to identify and reach the perfect customer with the ideal product at the optimal time.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/check_circle_dark_green.svg",
          cardImageUrlFallback: null,
          cardImageHeight: 32,
          cardImageWidth: 32,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Precision Targeting",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T06:05:17.876Z",
          updatedAt: "2022-06-23T17:06:07.768Z",
          publishedAt: "2022-06-23T06:05:19.603Z",
          locale: "en",
          internal_identifier: "b2blanding - liveAnalyticsSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Save 78% of your sales team’s time by distinguishing between unproductive and promising leads without making unnecessary calls.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/check_circle_dark_green.svg",
          cardImageUrlFallback: null,
          cardImageHeight: 32,
          cardImageWidth: 32,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Efficient Lead Qualification",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T06:06:01.498Z",
          updatedAt: "2022-06-23T17:06:38.708Z",
          publishedAt: "2022-06-23T06:06:03.661Z",
          locale: "en",
          internal_identifier: "b2blanding - liveAnalyticsSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Re-engage the right customers strategically, ensuring timely follow-ups for increased conversion rates.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/check_circle_dark_green.svg",
          cardImageUrlFallback: null,
          cardImageHeight: 32,
          cardImageWidth: 32,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Strategic Retargeting",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    liveAnalyticsFeaturesGridSection: {
      id: 16,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: null,
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "liveAnalyticsFeaturesGridSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: null,
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T06:48:08.231Z",
          updatedAt: "2022-06-23T16:58:36.418Z",
          publishedAt: "2022-06-21T06:53:14.600Z",
          locale: "en",
          internal_identifier:
            "b2blanding - liveAnalyticsFeaturesGridSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Total amount of time that the customer has spent viewing a particular catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_total_view_time.webp",
          cardImageUrlFallback: "/assets/b2blanding_total_view_time.png",
          cardImageHeight: "600",
          cardImageWidth: "230",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Total view time",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:54:20.148Z",
          updatedAt: "2022-06-23T16:58:44.032Z",
          publishedAt: "2022-06-21T06:54:21.888Z",
          locale: "en",
          internal_identifier:
            "b2blanding - liveAnalyticsFeaturesGridSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "What % of products have been viewed or opened in your catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_percentage_view.webp",
          cardImageUrlFallback: "/assets/b2blanding_percentage_view.png",
          cardImageHeight: "600",
          cardImageWidth: "230",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Percentage viewed & Opened",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:55:03.684Z",
          updatedAt: "2022-06-23T16:58:51.460Z",
          publishedAt: "2022-06-21T06:55:07.819Z",
          locale: "en",
          internal_identifier:
            "b2blanding - liveAnalyticsFeaturesGridSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "How many products have been added to cart in this catalogue",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "assets/b2blanding_percentage_view.webp",
          cardImageUrlFallback: "assets/b2blanding_percentage_view.png",
          cardImageHeight: "600",
          cardImageWidth: "230",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Inquiries",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:55:44.741Z",
          updatedAt: "2022-06-23T16:59:00.602Z",
          publishedAt: "2022-06-21T06:55:47.201Z",
          locale: "en",
          internal_identifier:
            "b2blanding - liveAnalyticsFeaturesGridSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Track customer activity at a session level so that you can track latest customer interest and demand and pitch accordingly",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_track_activity.webp",
          cardImageUrlFallback: "/assets/b2blanding_track_activity.png",
          cardImageHeight: "600",
          cardImageWidth: "230",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Track activity for each Session",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:55:44.741Z",
          updatedAt: "2022-06-23T16:59:00.602Z",
          publishedAt: "2022-06-21T06:55:47.201Z",
          locale: "en",
          internal_identifier:
            "b2blanding - liveAnalyticsFeaturesGridSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "See in real-time which product the customer has spent the most amount of time viewing",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_real_time_product.webp",
          cardImageUrlFallback: "/assets/b2blanding_real_time_product.png",
          cardImageHeight: "600",
          cardImageWidth: "230",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Real-time product interest tracking",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T06:55:44.741Z",
          updatedAt: "2022-06-23T16:59:00.602Z",
          publishedAt: "2022-06-21T06:55:47.201Z",
          locale: "en",
          internal_identifier:
            "b2blanding - liveAnalyticsFeaturesGridSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Easily see which products have been ignored by customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_track_not_seen_products.webp",
          cardImageUrlFallback:
            "/assets/b2blanding_track_not_seen_products.png",
          cardImageHeight: "600",
          cardImageWidth: "230",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Track not seen products",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    sellRightProductsSection: {
      id: 44,
      cardTitle:
        'With QuickSell Catalogue sell the <span class="text-slant-underline text-green">right product at the right time</span>',
      cardSubTitle: null,
      cardDescription: "Get your live analytics enabled Catalogue today!",
      sectionTitle: null,
      sectionSubTitle: null,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "sellRightProductsSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: () => {
            const bookDemoForm = document.querySelector('#b2b-landing-page-book-demo-form');
            if (bookDemoForm) {
              bookDemoForm.scrollIntoView({ behavior: 'smooth' });
            }
          },
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "B2blanding - section 3 - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
    resellerChannelSection: {
      id: 52,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription:
        "Track customer activity in real-time - reach out to the right buyers at the right time",
      sectionTitle: "RESELLER CHANNEL",
      sectionSubTitle:
        'Send resellers your <span class="text-green">latest products on WhatsApp</span> with a single click',
      cardImageUrl: "assets/b2blanding_reseller_image.webp",
      cardImageUrlFallback: "assets/b2blanding_reseller_image.png",
      cardImageHeight: 2160,
      cardImageWidth: 800,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "resellerChannelSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-23T06:03:41.077Z",
          updatedAt: "2022-06-23T17:05:45.383Z",
          publishedAt: "2022-06-23T06:03:43.487Z",
          locale: "en",
          internal_identifier: "b2blanding - resellerChannelSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Streamline the management of your resellers by utilising a centralised platform for administration.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/check_circle_dark_green.svg",
          cardImageUrlFallback: null,
          cardImageHeight: 32,
          cardImageWidth: 32,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Centralised administration",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T06:05:17.876Z",
          updatedAt: "2022-06-23T17:06:07.768Z",
          publishedAt: "2022-06-23T06:05:19.603Z",
          locale: "en",
          internal_identifier: "b2blanding - resellerChannelSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Enable resellers to set margins and share catalogs with their customers to enhance their sales effectiveness.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/check_circle_dark_green.svg",
          cardImageUrlFallback: null,
          cardImageHeight: 32,
          cardImageWidth: 32,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Custom catalogs",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-23T06:06:01.498Z",
          updatedAt: "2022-06-23T17:06:38.708Z",
          publishedAt: "2022-06-23T06:06:03.661Z",
          locale: "en",
          internal_identifier: "b2blanding - resellerChannelSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Effortlessly ensure that your reseller channels stay current with the latest products and pricing, even while on the go.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/check_circle_dark_green.svg",
          cardImageUrlFallback: null,
          cardImageHeight: 32,
          cardImageWidth: 32,
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: "Keep your resellers updated",
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    keyUseCaseSection: {
      id: 51,
      cardTitle: null,
      cardSubTitle: null,
      cardDescription: `How QuickSell help's in enhancing your business processes.`,
      sectionTitle: "KEY USE CASES",
      sectionSubTitle: `Here's the key use cases that can <span class="text-green">3x your B2B sales efficiency</span>`,
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "keyUseCaseSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      featuresList: [
        {
          createdAt: "2022-06-21T12:47:54.956Z",
          updatedAt: "2022-06-23T17:03:04.372Z",
          publishedAt: "2022-06-21T12:47:56.812Z",
          locale: "en",
          internal_identifier: "b2blanding - keyUseCaseSection - logo 1",
          cardSubTitle: null,
          cardDescription:
            "Talk to serious buyers only by tracking their behaviour on your catalogue and calling them with the right information at the right time",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_circle_one.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "46",
          cardImageWidth: "50",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            '<span class="text-green">Increase conversion</span> rate from AliExpress, Alibaba and other B2B portals',
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 1,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:09.721Z",
          updatedAt: "2022-06-23T17:03:12.974Z",
          publishedAt: "2022-06-21T12:49:11.269Z",
          locale: "en",
          internal_identifier: "b2blanding - keyUseCaseSection - logo 2",
          cardSubTitle: null,
          cardDescription:
            "Scale WhatsApp Sales by engaging with more customers in lesser time on WhatsApp with QuickSell",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_circle_two.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "46",
          cardImageWidth: "38",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            '<span class="text-green">B2B WhatsApp</span> Order Booking',
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 2,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:49:58.483Z",
          updatedAt: "2022-06-23T17:03:21.202Z",
          publishedAt: "2022-06-21T12:49:59.941Z",
          locale: "en",
          internal_identifier: "b2blanding - keyUseCaseSection - logo 3",
          cardSubTitle: null,
          cardDescription:
            "Avoid product theft or damage, while commuting for outstation showcase.",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_circle_three.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "36",
          cardImageWidth: "36",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: '<span class="text-green">Reduce</span> transit risk',
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 3,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:50:57.775Z",
          updatedAt: "2022-06-23T17:03:31.216Z",
          publishedAt: "2022-06-21T12:50:59.229Z",
          locale: "en",
          internal_identifier: "b2blanding - keyUseCaseSection - logo 4",
          cardSubTitle: null,
          cardDescription:
            "Curate two different catalogues of the same products, but with different pricing for your B2B and B2C customers",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_circle_four.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "50",
          cardImageWidth: "50",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle: '<span class="text-green">B2B & B2C </span> Store',
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 4,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:51:52.930Z",
          updatedAt: "2022-06-23T17:03:40.521Z",
          publishedAt: "2022-06-21T12:51:54.343Z",
          locale: "en",
          internal_identifier: "b2blanding - keyUseCaseSection - logo 5",
          cardSubTitle: null,
          cardDescription:
            "Not just links or single images, you can now also share PDFs with your customers for offline access",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_circle_five.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "46",
          cardImageWidth: "46",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            'Automatic PDF <span class="text-green">catalogue creation</span>',
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 5,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
        {
          createdAt: "2022-06-21T12:52:43.686Z",
          updatedAt: "2022-06-23T17:03:48.894Z",
          publishedAt: "2022-06-21T12:52:45.511Z",
          locale: "en",
          internal_identifier: "b2blanding - keyUseCaseSection - logo 6",
          cardSubTitle: null,
          cardDescription:
            "QuickSell let's your end customer filter on available size & color",
          sectionTitle: null,
          sectionSubTitle: null,
          cardImageUrl: "/assets/b2blanding_circle_six.svg",
          cardImageUrlFallback: null,
          cardImageHeight: "22",
          cardImageWidth: "35",
          cardVideoUrl: null,
          cardVideoPosterUrl: null,
          cardVideoPosterUrlFallback: null,
          cardVideoPosterHeight: null,
          cardVideoPosterWidth: null,
          cardTitle:
            '<span class="text-green">Filtering options</span> based on variants like size and color',
          linkUrl: null,
          openInNewTab: false,
          cardVideoControls: false,
          cardVideoAutoplay: false,
          cardVideoMuted: false,
          cardVideoLoop: false,
          cardVideoDisablePictureInPicture: false,
          cardVideoAllowFullScreen: false,
          sectionDescription: null,
          cardVideoTagName: "iframe",
          internal_column: null,
          columnName: null,
          sectionName: null,
          sortOrder: 6,
          cardVideoHeight: null,
          cardVideoWidth: null,
        },
      ],
    },
    faqSection: {
      id: 44,
      cardTitle: "Have more questions? We’d love explain everything",
      cardSubTitle: null,
      cardDescription: "Get your live analytics enabled Catalogue today!",
      sectionTitle: "FAQ",
      sectionSubTitle: "Common questions",
      cardImageUrl: null,
      cardImageUrlFallback: null,
      cardImageHeight: null,
      cardImageWidth: null,
      cardVideoUrl: null,
      cardVideoPosterUrl: null,
      cardVideoPosterUrlFallback: null,
      cardVideoPosterHeight: null,
      cardVideoPosterWidth: null,
      identifier_internal: "faqSection",
      cardVideoControls: false,
      cardVideoAutoplay: false,
      cardVideoMuted: false,
      cardVideoLoop: false,
      cardVideoDisablePictureInPicture: false,
      cardVideoAllowFullScreen: false,
      sectionDescription: null,
      cardVideoTagName: "iframe",
      cardVideoHeight: null,
      cardVideoWidth: null,
      tableTitle: "COMPARISON",
      linkUrl: null,
      openInNewTab: false,
      buttonsList: [
        {
          type: "primary",
          linkUrl: undefined,
          openInNewTab: false,
          text: "BOOK DEMO",
          onClick: () => {
            const bookDemoForm = document.querySelector('#b2b-landing-page-book-demo-form');
            if (bookDemoForm) {
              bookDemoForm.scrollIntoView({ behavior: 'smooth' });
            }
          },
          createdAt: "2022-07-19T16:55:24.262Z",
          updatedAt: "2022-07-19T16:55:25.263Z",
          publishedAt: "2022-07-19T16:55:25.233Z",
          locale: "en",
          identifier_internal: "B2blanding - faqSection - Book Demo Button",
          columnName: null,
          sectionName: null,
          sortOrder: 1,
        },
      ],
    },
  },
};

module.exports = {
  data,
};
